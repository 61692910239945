import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { usePlayer } from '../hooks/usePlayer'
import { analytics } from '../config/firebase'
import { Td, Tr } from '@chakra-ui/table'
import { Button, IconButton } from '@chakra-ui/button'
import { BiCog, BiEdit, BiPlay, BiTrash } from 'react-icons/bi'
import NumberInput from './NumberInput'
import Axios from 'axios'
import { Flex, HStack, Text } from '@chakra-ui/layout'
import { updateVinheta } from '../store/actions/vinhetas'
import VinhetaOptions from './VinhetaOptions'
import { useDisclosure } from '@chakra-ui/hooks'

const VinhetaItem = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { usuarioInfo } = useSelector((state) => state.usuario)

  const dispatch = useDispatch()

  const { updatePlayer, data, refreshPlaylist } = usePlayer()

  const [reproduzindoVinheta, setReproduzindoVinheta] = useState(false)
  const [clicou, setClicou] = useState(false)

  const [intervaloVinheta, setIntervaloVinheta] = useState(props?.frequencia)

  useEffect(() => {
    setReproduzindoVinheta(false)
  }, [data])

  useEffect(() => {
    if (!reproduzindoVinheta && clicou) {
      if (props.onReproduzir) props.onReproduzir()
      setClicou(false)
    }
    //eslint-disable-next-line
  }, [reproduzindoVinheta])

  const changeFrequenciaVinheta = (novoIntervalo) => {
    if (novoIntervalo > 0) {
      Axios.get(
        process.env.REACT_APP_API +
          '/vinheta/change_frequencia.php?id=' +
          props.id +
          '&frequencia=' +
          novoIntervalo +
          '&token=' +
          encodeURIComponent(usuarioInfo.token),
        {
          headers: {
            Authorization: 'bearer ' + usuarioInfo.token,
            withCredentials: true,
          },
        }
      ).then(() => {
        refreshPlaylist()
        dispatch(updateVinheta({ frequencia: novoIntervalo, id: props.id }))
      })
    }
  }

  return (
    <>
      <VinhetaOptions
        onClose={onClose}
        isOpen={isOpen}
        onChangeEstado={props.onChangeEstado}
        estado={props.estado}
        setIntervaloVinheta={setIntervaloVinheta}
        intervaloVinheta={intervaloVinheta}
        changeFrequenciaVinheta={changeFrequenciaVinheta}
        isAdmin={+usuarioInfo?.nivel === 0}
        id={props.id}
        onExcluir={props.onExcluir}
        titulo={props.titulo}
        formatoPopup={props.formatoPopup}
      />
      <Tr>
        <Td>
          <HStack spacing='1' alignItems='stretch'>
            <IconButton
              aria-label='Reproduzir vinheta'
              icon={<BiPlay />}
              _hover={{ bg: 'accent.500', color: 'white' }}
              color='gray.700'
              fontSize='3xl'
              size='lg'
              borderRadius='full'
              isLoading={reproduzindoVinheta}
              marginRight='2'
              onClick={() => {
                setClicou(true)
                updatePlayer({
                  playSpot: props.id,
                }).then(() => {
                  analytics.logEvent('reproduziu_vinheta', {
                    vinhetaId: props.id,
                    titulo: props.titulo,
                    categoriaId: props.idCategoria,
                    categoriaTitulo: props.categoria,
                  })
                })
                setReproduzindoVinheta(true)
              }}
            />
            <Flex
              justifyContent='flex-start'
              alignItems='center'
              w='100%'
              display={['none', null, 'flex']}
            >
              <Text w='100%'>{props.titulo}</Text>
            </Flex>
            <Flex
              justifyContent='flex-start'
              alignItems='center'
              w='100%'
              onClick={onOpen}
              display={['flex', null, 'none']}
              cursor='pointer'
            >
              <Text w='100%'>{props.titulo}</Text>
              {!props.formatoPopup && (
                <IconButton
                  size='sm'
                  aria-label='Configurar vinheta'
                  icon={<BiCog />}
                />
              )}
            </Flex>
          </HStack>
        </Td>
        {!props.formatoPopup && (
          <>
            <Td display={['none', null, null, 'table-cell']}>
              <Link to={`/vinheta/${props.idCategoria}`}>
                {props.categoria}
              </Link>
            </Td>
            <Td display={['none', null, 'table-cell']}>
              <Button
                size='sm'
                onClick={props.onChangeEstado}
                colorScheme={props.estado === '1' ? 'green' : 'red'}
                _hover={
                  props.estado === '1'
                    ? {
                        bg: 'red.100',
                        color: 'red.700',
                      }
                    : {
                        bg: 'green.100',
                        color: 'green.700',
                      }
                }
              >
                {props.estado === '1' ? 'Ativo' : 'Desativ.'}
              </Button>
            </Td>
            <Td display={['none', null, 'table-cell']}>
              <NumberInput
                onChange={(e) => {
                  setIntervaloVinheta(e.target.value)
                  changeFrequenciaVinheta(e.target.value)
                }}
                value={intervaloVinheta}
                readOnly
                width='12'
                size='sm'
                fontSize='md'
              />
            </Td>
            {+usuarioInfo?.nivel === 0 && (
              <Td display={['none', null, 'table-cell']}>
                <HStack>
                  <Link to={`/editar-vinheta/${props.id}`}>
                    <IconButton
                      aria-label='Editar'
                      _hover={{ color: 'white', bg: 'blue.500' }}
                      icon={<BiEdit />}
                    />
                  </Link>
                  <IconButton
                    aria-label='Excluir'
                    _hover={{ color: 'white', bg: 'red.500' }}
                    icon={<BiTrash />}
                    onClick={props.onExcluir}
                  />
                </HStack>
              </Td>
            )}
          </>
        )}
      </Tr>
    </>
  )
}

export default VinhetaItem
