import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  VStack,
  Text,
  ModalFooter,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BiCog } from 'react-icons/bi'
import { usePlayer } from '../../../hooks/usePlayer'
import NumberInput from '../../../components/NumberInput'
import { analytics } from '../../../config/firebase'

const PopupSpotInterval = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [intervaloVinhetas, setIntervaloVinhetas] = useState(1)
  const [trocandoIntervalo, setTrocandoIntervalo] = useState(false)

  const { data, updatePlayer } = usePlayer()

  useEffect(() => {
    if (data.intervaloVinhetas) {
      setIntervaloVinhetas(Number(data.intervaloVinhetas))
    }
  }, [data])

  const changeIntervaloVinhetas = () => {
    setTrocandoIntervalo(true)

    updatePlayer({
      intervaloVinhetas,
    })
      .then(() => {
        onClose()
        setTrocandoIntervalo(false)
      })
      .catch(() => {
        setTrocandoIntervalo(false)
      })
  }

  return (
    <>
      <Button
        leftIcon={<BiCog />}
        onClick={() => {
          analytics.logEvent('abriu_popup_intervalo_vinhetas')
          onOpen()
        }}
        colorScheme='blueGray'
        variant='outline'
      >
        Conf. Intervalo
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configurar Intervalo</ModalHeader>
          <ModalCloseButton />
          <Text textAlign='center' margin='2'>
            A cada quantas músicas as vinhetas devem tocar?
          </Text>
          <ModalBody>
            <VStack>
              <NumberInput
                value={intervaloVinhetas}
                onChange={(e) => setIntervaloVinhetas(e.target.value)}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme='accent'
              isLoading={trocandoIntervalo}
              onClick={changeIntervaloVinhetas}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PopupSpotInterval
