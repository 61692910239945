import Axios from 'axios'

export const USUARIOS_REQUEST = 'USUARIOS_REQUEST'
export const USUARIOS_SUCCESS = 'USUARIOS_SUCCESS'
export const USUARIOS_FAIL = 'USUARIOS_FAIL'
export const ADD_USUARIO_SUCCESS = 'ADD_USUARIO_SUCCESS'
export const UPLOAD_IMAGEM_REQUEST = 'UPLOAD_IMAGEM_REQUEST'
export const UPLOAD_IMAGEM_PROGRESS = 'UPLOAD_IMAGEM_PROGRESS'
export const UPLOAD_IMAGEM_SUCCESS = 'UPLOAD_IMAGEM_SUCCESS'
export const UPLOAD_IMAGEM_FAIL = 'UPLOAD_IMAGEM_FAIL'

export const fetchUsuarios = (token) => async (dispatch) => {
  try {
    dispatch({ type: USUARIOS_REQUEST })
    const { data } = await Axios.get(
      process.env.REACT_APP_API + '/usuario/read.php?token=' + token,
      {
        headers: {
          Authorization: 'bearer ' + token,
          withCredentials: true,
        },
      }
    )

    const usuarios = data.data

    // ordenar por nome
    usuarios?.sort((a, b) => {
      if (a.empresa < b.empresa) {
        return -1
      }
      if (a.empresa > b.empresa) {
        return 1
      }
      return 0
    })

    dispatch({ type: USUARIOS_SUCCESS, payload: usuarios })
  } catch (error) {
    dispatch({ type: USUARIOS_FAIL, payload: error.message })
  }
}

export const addUsuario = (token, dados, imagem) => async (dispatch) => {
  try {
    dispatch({ type: USUARIOS_REQUEST })
    const { data } = await Axios.post(
      process.env.REACT_APP_API + '/usuario/create.php?token=' + token,
      {
        nome: dados.nome,
        usuario: dados.usuario,
        senha: dados.senha,
        email: dados.email,
        contato: dados.contato,
        nivel: dados.nivel,
        situacao: dados.situacao,
        empresa: dados.empresa,
        categoria: dados.categoria,
      }
    )
    if (imagem) {
      dispatch(processImage(token, imagem, data.id)) //, data.id
    } else {
      dispatch({ type: ADD_USUARIO_SUCCESS })
    }
    dispatch(fetchUsuarios(token))
  } catch (error) {
    dispatch({ type: USUARIOS_FAIL, payload: error.message })
  }
}

export const updateUsuario = (token, dados, imagem, id) => async (dispatch) => {
  try {
    dispatch({ type: USUARIOS_REQUEST })
    await Axios.post(
      process.env.REACT_APP_API + '/usuario/update.php?token=' + token,
      {
        id: id,
        nome: dados.nome,
        usuario: dados.usuario,
        senha: dados.senha,
        email: dados.email,
        contato: dados.contato,
        nivel: dados.nivel,
        situacao: dados.situacao,
        empresa: dados.empresa,
        categoria: dados.categoria,
      }
    )
    if (imagem) {
      dispatch(processImage(token, imagem, id)) //, data.id
    } else {
      dispatch({ type: ADD_USUARIO_SUCCESS })
    }
    dispatch(fetchUsuarios(token))
  } catch (error) {
    dispatch({ type: USUARIOS_FAIL, payload: error.message })
  }
}

export const processImage = (token, uploadedFile, id) => (dispatch) => {
  const data = new FormData()

  data.append('imagem', uploadedFile)

  dispatch({
    type: UPLOAD_IMAGEM_REQUEST,
    payload: { uploading: true, error: false, uploaded: false, progress: 0 },
  })

  Axios.post(
    process.env.REACT_APP_API +
      '/usuario/upload_image.php?id=' +
      id +
      '&token=' +
      token,
    data,
    {
      onUploadProgress: (e) => {
        const progresso = parseInt((e.loaded * 100) / e.total)
        console.log('progresso: ' + progresso)

        dispatch({
          type: UPLOAD_IMAGEM_PROGRESS,
          payload: { progress: progresso },
        })
      },
    }
  )
    .then((response) => {
      if (response.data.message === 'Imagem salva com sucesso!') {
        console.log(response.data)
        dispatch({
          type: UPLOAD_IMAGEM_SUCCESS,
          payload: { uploaded: true, uploading: false, progress: 100 },
        })
      } else {
        // alert('Ocorreu um Erro!');
        console.log(response)
        dispatch({
          type: UPLOAD_IMAGEM_FAIL,
          payload: { error: true, uploading: false, errorMessage: response },
        })
      }
    })
    .catch((error) => {
      // alert('Ocorreu um Erro!');
      console.log(error)
      dispatch({
        type: UPLOAD_IMAGEM_FAIL,
        payload: { error: true, uploading: false, errorMessage: error.message },
      })
    })
}
