import { useEffect, useRef, useState } from 'react'
import { FiVolume1, FiVolume2 } from 'react-icons/fi'

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const IconeReproduzindo = (props) => {
  const [icone, setIcone] = useState(1)

  useInterval(() => {
    if (icone === 1) {
      setIcone(2)
    } else {
      setIcone(1)
    }
  }, 1000)

  return <div {...props}>{icone === 1 ? <FiVolume1 /> : <FiVolume2 />}</div>
}

export default IconeReproduzindo
