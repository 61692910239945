import { Box, Text, VStack } from '@chakra-ui/layout'
import { Tag } from '@chakra-ui/tag'

const InfoMusica = (props) => {
  return (
    <VStack
      spacing='0'
      alignItems={['center', null, null, 'flex-start']}
      textAlign={['center', null, null, 'left']}
    >
      <Text display='flex' alignItems='center'>
        {props.titulo?.substr(0, 40)}
        {props.titulo?.length > 40 && '...'}
        {/* {props.isNew && (
            <Badge ml='2' colorScheme='green' variant='solid' fontSize='0.6rem'>
              Nova
            </Badge>
          )} */}
      </Text>
      <Text fontSize='xs'>{props.genero}</Text>
      <Box display={['none', 'initial']}>
        <Tag marginTop='1' size='sm' colorScheme='gray' variant='outline'>
          A seguir: {props.proximaMusica?.substr(0, 35)}
          {props.proximaMusica?.length > 35 && '...'}
        </Tag>
      </Box>
    </VStack>
  )
}

export default InfoMusica
