import { Td, Tr } from '@chakra-ui/table'
import { IconButton } from '@chakra-ui/button'
import { BiCommentDetail } from 'react-icons/bi'
import { HStack, Text } from '@chakra-ui/layout'
import DeleteRequestDialog from './DeleteRequestDialog'
import EditRequestModal from './EditRequestModal'
import ShowRequestModal from './ShowRequestModal'

const RequestItem = ({ data }) => {
  return (
    <>
      <Tr>
        <Td>
          <HStack spacing='1' alignItems='center'>
            <IconButton
              aria-label='Ícone de Solicitação'
              icon={<BiCommentDetail />}
              fontSize='3xl'
              size='lg'
              borderRadius='full'
              marginRight='4'
              disabled
              _hover={{ cursor: 'default' }}
              _disabled={{ color: 'gray.600' }}
            />
            <Text w='100%'>{data.type}</Text>
          </HStack>
        </Td>
        <Td display={['none', null, null, 'table-cell']}>
          <Text>{data.userName}</Text>
        </Td>
        <Td display={['none', null, 'table-cell']}>
          {data.status === 'pending'
            ? 'Pendente'
            : data.status === 'closed'
            ? 'Atendida'
            : 'Pendente'}
        </Td>
        <Td display={['none', null, 'table-cell']} isNumeric>
          <HStack justify='flex-end'>
            <ShowRequestModal data={data} />
            <EditRequestModal id={data.id} editButton />
            <DeleteRequestDialog id={data.id} onSuccess={data.onSuccess} />
          </HStack>
        </Td>
      </Tr>
    </>
  )
}

export default RequestItem
