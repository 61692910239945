import TopoSessao from '../../components/TopoSessao'
import PageWrapperPainel from '../../components/PageWrapperPainel'
import { Box, Center, Text } from '@chakra-ui/layout'
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/table'
import RequestItem from './components/RequestItem'
import { CircularProgress } from '@chakra-ui/progress'
import { useDatabase } from '../../hooks/useDatabase'
import EditRequestModal from './components/EditRequestModal'

const SolicitatcoesScreen = () => {
  const {
    requests: { data, loading, refreshRequests },
  } = useDatabase()

  const requests = data

  return (
    <PageWrapperPainel>
      <TopoSessao titulo='Minhas Solicitações'>
        <EditRequestModal onSave={refreshRequests} />
      </TopoSessao>
      <Box paddingX={['4', null, '6']} paddingTop='2' paddingBottom='48'>
        <Box bg='white'>
          {requests?.length > 0 && !loading ? (
            <Table>
              <Thead>
                <Tr>
                  <Th>Tipo</Th>
                  <Th display={['none', null, null, 'table-cell']}>Empresa</Th>
                  <Th display={['none', null, 'table-cell']}>Status</Th>
                  <Th isNumeric display={['none', null, 'table-cell']}>
                    Ações
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {requests?.map((request, index) => (
                  <RequestItem
                    key={index}
                    data={request}
                    onSuccess={refreshRequests}
                  />
                ))}
              </Tbody>
            </Table>
          ) : loading ? (
            <Center padding='8'>
              <CircularProgress
                isIndeterminate
                color='gray'
                trackColor='transparent'
              />
            </Center>
          ) : (
            <Center padding='8'>
              <Text>Parece que você não tem solicitações pendentes!</Text>
            </Center>
          )}
        </Box>
      </Box>
    </PageWrapperPainel>
  )
}

export default SolicitatcoesScreen
