import { useContext, createContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { db } from '../config/firebase'
import firebase from 'firebase/app'
import generatePlaylist from './usePlayerUtil/generatePlaylist'
import savePlayerChangesToFirebase from './usePlayerUtil/savePlayerChangesToFirebase'
import includeSpotInPlaylist from './usePlayerUtil/includeSpotInPlaylist'
import generateSongsPlaylist from './usePlayerUtil/generateSongsPlaylist'
import generateSpotsPlaylist from './usePlayerUtil/generateSpotsPlaylist'
import includeClockInPlaylist from './usePlayerUtil/includeClockInPlaylist'
import { useDatabase } from './useDatabase'
import generateSongsCustomPlaylist from './usePlayerUtil/generateCustomPlaylist'

const PlayerContext = createContext({ data: {} })

export function usePlayer() {
  return useContext(PlayerContext)
}

export function PlayerProvider({ children }) {
  const [data, setData] = useState({})
  const [clockPaths, setClockPaths] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { usuarioInfo } = useSelector((state) => state.usuario)

  const { genres: allGenresData, customPlaylists } = useDatabase()

  const { vinhetas } = useSelector((state) => state.vinhetas)

  useEffect(() => {
    setLoading(true)
    try {
      var unsubscribe = db
        .collection('players')
        .doc(usuarioInfo.id)
        .onSnapshot((querySnapshot) => {
          setData(querySnapshot.data() || {})
          setLoading(false)
          setError(null)
        })

      db.collection('appResources')
        .doc('clock')
        .get()
        .then((doc) => {
          setClockPaths(doc.data())
        })

      return () => {
        unsubscribe()
      }
    } catch (error) {
      setError(error)
    }
  }, [usuarioInfo])

  const updatePlayer = async (dataToUpdate) => {
    if (!dataToUpdate) return false
    console.log('Atualizando player: ', dataToUpdate)

    let extraData = {}

    if (
      data &&
      (typeof dataToUpdate?.genero !== 'undefined' ||
        (String(data?.genero) === '0' &&
          dataToUpdate?.generosPlaylistAleatoria) ||
        dataToUpdate?.intervaloVinhetas ||
        dataToUpdate?.intervaloRelogio >= 0 ||
        (typeof dataToUpdate?.playlistId !== 'undefined' &&
          dataToUpdate?.isCustomPlaylist))
    ) {
      console.log('Playlist alterada')

      const generosPlaylistAleatoria =
        dataToUpdate.generosPlaylistAleatoria ||
        data.generosPlaylistAleatoria ||
        null

      const genero =
        typeof dataToUpdate.genero !== 'undefined'
          ? String(dataToUpdate.genero)
          : data.genero
          ? String(data.genero)
          : '0'

      const spotsInterval = dataToUpdate.intervaloVinhetas
        ? Number(dataToUpdate.intervaloVinhetas)
        : data.intervaloVinhetas
        ? Number(data.intervaloVinhetas)
        : 1

      const clockInterval = dataToUpdate.intervaloRelogio
        ? Number(dataToUpdate.intervaloRelogio)
        : data.intervaloRelogio
        ? Number(data.intervaloRelogio)
        : 0

      let songsPlaylist = generateSongsPlaylist(
        genero,
        generosPlaylistAleatoria,
        allGenresData
      )

      const customPlaylistId = dataToUpdate.playlistId || data.playlistId

      //TODO a verificação de se é playlist normal ou customizada precisa ser refatorada

      if (
        customPlaylistId &&
        !(
          typeof dataToUpdate.isCustomPlaylist !== 'undefined' &&
          dataToUpdate.isCustomPlaylist === false
        ) &&
        (data.isCustomPlaylist || dataToUpdate.isCustomPlaylist)
      ) {
        songsPlaylist = generateSongsCustomPlaylist(
          customPlaylistId,
          customPlaylists
        )
        console.log(songsPlaylist)
      }

      console.log('passou')

      const spotsPlaylist = generateSpotsPlaylist(vinhetas)

      const newPlaylist = generatePlaylist(
        songsPlaylist,
        spotsPlaylist,
        spotsInterval,
        clockInterval
      )

      extraData = {
        ...extraData,
        playlist: newPlaylist,
        dataPlaylist: firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (data.faixaAtual >= newPlaylist.length - 2) {
        extraData = {
          ...extraData,
          faixaAtual: 1,
        }
      }
    } else if (dataToUpdate.playSpot) {
      const novaPlaylist = includeSpotInPlaylist({
        playlist: data.playlist,
        faixaAtual: data.faixaAtual,
        vinhetas,
        spot: dataToUpdate.playSpot,
      })

      extraData = {
        ...extraData,
        playlist: novaPlaylist,
        dataPlaylist: firebase.firestore.FieldValue.serverTimestamp(),
      }
    } else if (dataToUpdate.playRelogio) {
      console.log('incluindo relogio')
      const novaPlaylist = includeClockInPlaylist({
        playlist: data.playlist,
        faixaAtual: data.faixaAtual,
        when: dataToUpdate.playRelogio,
      })

      extraData = {
        ...extraData,
        playlist: novaPlaylist,
        dataPlaylist: firebase.firestore.FieldValue.serverTimestamp(),
      }
    }

    return await savePlayerChangesToFirebase(
      { ...dataToUpdate, ...extraData },
      usuarioInfo.id
    )
  }

  const refreshPlaylist = () => {
    if (data) {
      updatePlayer({
        genero: String(data.genero),
        isCustomPlaylist: data.isCustomPlaylist,
      })
    }
  }

  const value = {
    data,
    setData,
    clockPaths,
    loading,
    error,
    updatePlayer,
    refreshPlaylist,
  }

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  )
}
