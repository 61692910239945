import { useEffect, useState } from 'react'
import TopoSessao from '../components/TopoSessao'
import MusicaItem from '../components/MusicaItem'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import PageWrapperPainel from '../components/PageWrapperPainel'
import { Box, Center, Text } from '@chakra-ui/layout'
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/table'
import { Input } from '@chakra-ui/input'
import { Button } from '@chakra-ui/button'
import { useDatabase } from '../hooks/useDatabase'

const MusicasScreen = (props) => {
  const {
    genres,
    loading: loadingMusicas,
    error,
    deleteMusic,
    deleteMusicFromCustomPlaylist,
    getCustomPlaylist,
  } = useDatabase()

  const [genre, setGenre] = useState({})
  const [musicas, setMusicas] = useState([])
  const [musicasFiltradas, setMusicasFiltradas] = useState(musicas)
  const [pesquisa, setPesquisa] = useState('')
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (genres && props.match.params.genero && !loadingMusicas && !error) {
      let genreSelected = genres.find(
        (genre) => genre.id === props.match.params.genero
      )
      setMusicas(genreSelected?.songs || [])
      setGenre(genreSelected)
      setLoading(false)
    } else if (props.match.params.customPlaylistId) {
      getCustomPlaylist(props.match.params.customPlaylistId).then((data) => {
        setGenre({ ...data, isCustomPlaylist: true })
        setMusicas(data?.songs || [])
        setLoading(false)
      })
    }
  }, [error, genres, getCustomPlaylist, loadingMusicas, props.match.params])

  useEffect(() => {
    if (pesquisa === '') {
      setMusicasFiltradas(musicas)
    } else {
      setMusicasFiltradas(
        musicas.filter((musica) => musica.title.includes(pesquisa))
      )
    }
    //eslint-disable-next-line
  }, [musicas])

  useEffect(() => {
    setMusicasFiltradas(
      musicas.filter((musica) =>
        musica.title.toUpperCase().includes(pesquisa.toUpperCase())
      )
    )
    //eslint-disable-next-line
  }, [pesquisa])

  const indexOfLastPost = page * 20

  let currentResults = []

  if (!loading && !error) {
    currentResults = musicasFiltradas?.slice(0, indexOfLastPost)
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [musicaExcluir, setMusicaExcluir] = useState({})

  const openModal = (id, title) => {
    setModalOpen(true)
    setMusicaExcluir({ id, title })
  }

  const excluirMusica = (title) => {
    if (genre.isCustomPlaylist) {
      deleteMusicFromCustomPlaylist(title, genre.id)
    } else {
      deleteMusic(title, genre.id)
    }
    setModalOpen(false)
  }

  return (
    <PageWrapperPainel loading={loading}>
      <TopoSessao titulo={`Músicas em "${genre?.title}"`}>
        <Input
          bg='white'
          placeholder='Digite para pesquisar...'
          onChange={(e) => {
            setPesquisa(e.target.value)
          }}
        />
      </TopoSessao>
      <Box padding='6'>
        <Box bg='white'>
          {!loading ? (
            <Table>
              <Thead>
                <Tr>
                  <Th>Título</Th>
                  <Th>Cadastrada em</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentResults?.map((musica) => (
                  <MusicaItem
                    onExcluir={() => {
                      openModal(musica.id, musica.title)
                    }}
                    key={musica.id}
                    id={musica.id}
                    titulo={musica.title}
                    cadastro={musica.timestamp?.toLocaleDateString('pt-BR')}
                  />
                ))}
              </Tbody>
            </Table>
          ) : error ? (
            <Text>{error}</Text>
          ) : (
            <Text>Carregando...</Text>
          )}
        </Box>
        {!loading && musicasFiltradas && (
          <Center paddingTop='6' paddingBottom='36'>
            <Button
              size='lg'
              colorScheme='blueGray'
              variant='outline'
              onClick={() => {
                setPage((prev) => prev + 1)
              }}
            >
              Carregar mais
            </Button>
          </Center>
        )}
      </Box>
      <ModalConfirmacaoForm
        open={modalOpen}
        success={modalOpen}
        onClick={() => {
          setModalOpen(false)
        }}
        onClose={() => {
          setModalOpen(false)
        }}
        message={`Tem certeza que deseja excluir a música '${musicaExcluir.title}'?`}
      >
        <Button
          onClick={() => {
            setModalOpen(false)
          }}
          colorScheme='gray'
          autoFocus
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            excluirMusica(musicaExcluir.title)
          }}
          colorScheme='accent'
        >
          Excluir
        </Button>
      </ModalConfirmacaoForm>
    </PageWrapperPainel>
  )
}

export default MusicasScreen
