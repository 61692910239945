import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const DatePicker = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant='inline'
        format='dd/MM/yyyy'
        margin='normal'
        id={props.id}
        label={props.label}
        value={props.value}
        onChange={(value) => props.onChange(value)}
        KeyboardButtonProps={{
          'aria-label': 'Selecionar data',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
