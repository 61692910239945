import { useState } from 'react'

import GeneroItem from '../components/GeneroItem'
import TopoSessao from '../components/TopoSessao'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import PageWrapperPainel from '../components/PageWrapperPainel'
import { useDatabase } from '../hooks/useDatabase'

const GenerosScreen = () => {
  const { genres, loading, error, deleteGenre } = useDatabase()

  const [modalOpen, setModalOpen] = useState(false)
  const [generoExcluir, setGeneroExcluir] = useState({})

  const openModal = (id, titulo) => {
    setModalOpen(true)
    setGeneroExcluir({ id, titulo })
  }

  const excluirGenero = (id) => {
    deleteGenre(id).then(() => {
      setModalOpen(false)
      setGeneroExcluir(null)
    })
  }

  return (
    <PageWrapperPainel>
      <TopoSessao titulo='Generos'>
        <Link to='editar-genero' className='botao-topo-sessao'>
          Adicionar Gênero
        </Link>
      </TopoSessao>
      <div className='sessao'>
        <table className='lista-painel'>
          <thead>
            <tr>
              <th>Título</th>
              <th>Total de Músicas</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <th>Carregando...</th>
              </tr>
            ) : error ? (
              <tr>
                <th>{error}</th>
              </tr>
            ) : (
              genres &&
              genres.map((genero) => (
                <GeneroItem
                  onExcluir={() => {
                    openModal(genero.id, genero.title)
                  }}
                  key={genero.id}
                  id={genero.id}
                  titulo={genero.title}
                  quantidade={genero.songs?.length}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      <ModalConfirmacaoForm
        open={modalOpen}
        success={modalOpen}
        onClick={() => {
          setModalOpen(false)
        }}
        onClose={() => {
          setModalOpen(false)
        }}
        message={`Tem certeza que deseja excluir o gênero '${generoExcluir.titulo}'?`}
      >
        <Button
          onClick={() => {
            excluirGenero(generoExcluir.id)
          }}
          color='primary'
        >
          Excluir
        </Button>
        <Button
          onClick={() => {
            setModalOpen(false)
          }}
          color='primary'
          autoFocus
        >
          Cancelar
        </Button>
      </ModalConfirmacaoForm>
    </PageWrapperPainel>
  )
}

export default GenerosScreen
