import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './index.css'
import App from './App'
import { ChakraProvider } from '@chakra-ui/react'

import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'

import usuarioReducer from './store/reducers/usuario'
import usuariosReducer from './store/reducers/usuarios'
import vinhetasReducer from './store/reducers/vinhetas'
import categoriasEmpresasReducer from './store/reducers/categoriasEmpresas'
import categoriasVinhetasReducer from './store/reducers/categoriasVinhetas'
import solicitacoesReducer from './store/reducers/solicitacoes'
// import generosReducer from './store/reducers/generos'
// import musicasReducer from './store/reducers/musicas'
// import playerReducer from './store/reducers/player'

import { PlayerProvider } from './hooks/usePlayer'
import theme from './styles/theme'
import { BrowserRouter } from 'react-router-dom'
import { DatabaseProvider } from './hooks/useDatabase'

const rootReducer = combineReducers({
  // generos: generosReducer,
  // musicas: musicasReducer,
  // player: playerReducer,
  usuario: usuarioReducer,
  usuarios: usuariosReducer,
  vinhetas: vinhetasReducer,
  categoriasEmpresas: categoriasEmpresasReducer,
  categoriasVinhetas: categoriasVinhetasReducer,
  solicitacoes: solicitacoesReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(ReduxThunk))
)

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <DatabaseProvider>
          <PlayerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PlayerProvider>
        </DatabaseProvider>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()
