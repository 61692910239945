import { Box, DarkMode, HStack, Progress, Text } from '@chakra-ui/react'

const BarraReproducao = (props) => {
  let s = parseInt(props.currentTime % 60)
  let m = parseInt((props.currentTime / 60) % 60)
  //Add 0 if seconds less than 10
  if (s < 10) {
    s = '0' + s
  }

  let value = 0
  if (props.currentTime > 0) {
    value = Math.floor((100 / props.duration) * props.currentTime)
  }

  return (
    <HStack width='100%'>
      <Box minW={['64', 'xs', 'sm']}>
        <DarkMode>
          <Progress
            marginY='2.5'
            // isIndeterminate={props.isIndeterminate}
            colorScheme='blue'
            size='xs'
            hasStripe={props.isIndeterminate}
            isAnimated={props.isIndeterminate}
            isIndeterminate={props.isLoading}
            value={props.isIndeterminate ? 100 : value}
          />
        </DarkMode>
      </Box>
      {/* <div className='barra-reproducao'>
        <div
          style={{ transform: 'translateX(' + (value - 100) + '%)' }}
          className='progresso-reproducao'
        ></div>
      </div> */}
      {props.showTimer && (
        <Text>
          {m}:{s}
        </Text>
      )}
    </HStack>
  )
}

export default BarraReproducao
