import { Center } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { motion } from 'framer-motion'

const mainVariants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      duration: 0.4,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.4,
    },
  },
}

const PageWrapperPainel = ({ children, loading, ...props }) => {
  return (
    <motion.div
      variants={mainVariants}
      exit='exit'
      initial='hidden'
      animate='visible'
      style={{ marginBottom: '8rem', ...props.style }}
      {...props}
    >
      {loading ? (
        <Center w='100%' h='100%' minH='50vh'>
          <Spinner size='xl' color='blue.600' thickness='4px' />
        </Center>
      ) : (
        children
      )}
    </motion.div>
  )
}

export default PageWrapperPainel
