import { IconButton } from '@chakra-ui/button'
import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { VStack } from '@chakra-ui/layout'
import { ModalBody } from '@chakra-ui/modal'
import { ModalContent } from '@chakra-ui/modal'
import { ModalHeader } from '@chakra-ui/modal'
import { ModalOverlay } from '@chakra-ui/modal'
import { ModalFooter } from '@chakra-ui/modal'
import { Modal } from '@chakra-ui/modal'
import { useToast } from '@chakra-ui/toast'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { BiEdit, BiMessageAdd } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import CustomSelect from '../../../components/forms/CustomSelect'
import CustomTextarea from '../../../components/forms/CustomTextarea'
import { useDatabase } from '../../../hooks/useDatabase'

const EditRequestModal = ({ id, onSave, editButton }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [data, setData] = useState(null)

  const { usuarioInfo } = useSelector((state) => state.usuario)
  const {
    requests: { createRequest, getRequest, updateRequest },
  } = useDatabase()

  const toast = useToast()

  useEffect(() => {
    if (id) {
      getRequest(id).then((result) => {
        setData(result)
      })
    }
  }, [getRequest, id])

  const handleSuccess = () => {
    setData(null)
    toast({
      title: 'Recebemos a sua solicitação!',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
    onSave && onSave()
    onClose()
  }

  const initialValues = {
    type: data?.type || '',
    description: data?.description || '',
  }

  const validationSchema = Yup.object().shape({
    type: Yup.string().required('Preenchimento obrigatório!'),
    description: Yup.string().required('Preenchimento obrigatório!'),
  })

  const handleSubmit = async (values, actions) => {
    let success = false
    if (id) {
      success = await updateRequest(id, {
        ...values,
        status: 'pending',
        uid: usuarioInfo.id,
        userName: usuarioInfo.empresa,
      })
    } else {
      success = await createRequest({
        ...values,
        status: 'pending',
        uid: usuarioInfo.id,
        userName: usuarioInfo.empresa,
      })
    }

    if (success) handleSuccess()
    actions.setSubmitting(false)
  }

  return (
    <>
      {editButton ? (
        <IconButton
          aria-label='Editar'
          _hover={{ color: 'white', bg: 'blue.500' }}
          icon={<BiEdit />}
          onClick={onOpen}
        />
      ) : (
        <Button
          leftIcon={<BiMessageAdd />}
          colorScheme='blueGray'
          variant='outline'
          onClick={onOpen}
        >
          Nova Solicitação
        </Button>
      )}

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nova Solicitação</ModalHeader>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form>
                <ModalBody>
                  <VStack>
                    <CustomSelect
                      name='type'
                      label='O que você precisa?'
                      placeholder='Selecione uma opção'
                    >
                      <option value='Playlist Personalizada'>
                        Playlist Personalizada
                      </option>
                      <option value='Vinheta/Spot Interno'>
                        Vinheta/Spot - Interno
                      </option>
                      <option value='Vinheta/Spot Externo'>
                        Vinheta/Spot - Externo (download)
                      </option>
                    </CustomSelect>
                    <CustomTextarea
                      name='description'
                      label='Descrição'
                      type='text'
                    />
                  </VStack>
                </ModalBody>
                <ModalFooter>
                  <Button mr='3' onClick={onClose} type='reset'>
                    Cancelar
                  </Button>
                  <Button
                    isLoading={props.isSubmitting}
                    minW='40%'
                    colorScheme='accent'
                    type='submit'
                  >
                    Enviar
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditRequestModal
