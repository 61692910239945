import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TopoSessao from '../components/TopoSessao'
import * as Yup from 'yup'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import Axios from 'axios'
import { fetchCategoriasEmpresas } from '../store/actions/categoriasEmpresas'
import PageWrapperPainel from '../components/PageWrapperPainel'

const EditCategoriaEmpresaScreen = (props) => {
  let history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const [categoriasEmpresasSuccess, setCategoriasEmpresasSuccess] =
    useState(false)

  const dispatch = useDispatch()

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const categoriasEmpresasStore = useSelector(
    (state) => state.categoriasEmpresas
  )
  const { categoriasEmpresas, loading } = categoriasEmpresasStore

  let categoriaSelected = {}

  if (props.match.params.id && !loading && categoriasEmpresas.length > 1) {
    categoriaSelected = categoriasEmpresas.find(
      (categoria) => categoria.id === props.match.params.id
    )
  }

  return (
    ((props.match.params.id && !loading && categoriasEmpresas.length > 1) ||
      !props.match.params.id) && (
      <PageWrapperPainel>
        <TopoSessao
          titulo={
            props.match.params.id
              ? 'Atualizar Categoria de Empresa'
              : 'Adicionar Categoria de Empresa'
          }
        />
        <div
          style={{
            width: 'calc(100% - 4rem)',
            maxWidth: '960px',
            margin: '2rem auto',
            padding: '1.5rem',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
          }}
        >
          <Formik
            initialValues={{
              titulo: categoriaSelected.titulo ? categoriaSelected.titulo : '',
            }}
            validationSchema={Yup.object({
              titulo: Yup.string()
                .max(255, 'Tamanho máximo: 255 caracteres')
                .required('Preenchimento Obrigatório!'),
            })}
            onSubmit={(data) => {
              if (props.match.params.id) {
                Axios.post(
                  process.env.REACT_APP_API +
                    '/categoria_empresa/update.php?token=' +
                    encodeURIComponent(usuarioInfo.token),
                  {
                    titulo: data.titulo,
                    id: props.match.params.id,
                  }
                )
                  .then((dataRetorno) => {
                    setCategoriasEmpresasSuccess(true)
                    dispatch(fetchCategoriasEmpresas(usuarioInfo.token))
                  })
                  .catch(function (error) {
                    // handle error
                    console.log(error)
                  })
              } else {
                Axios.post(
                  process.env.REACT_APP_API +
                    '/categoria_empresa/create.php?token=' +
                    encodeURIComponent(usuarioInfo.token),
                  {
                    titulo: data.titulo,
                  }
                )
                  .then((dataRetorno) => {
                    setCategoriasEmpresasSuccess(true)
                    dispatch(fetchCategoriasEmpresas(usuarioInfo.token))
                  })
                  .catch(function (error) {
                    // handle error
                    console.log(error)
                  })
              }

              setModalOpen(true)
            }}
          >
            {(formik) => (
              <Form>
                <Field
                  label='Título da Categoria'
                  name='titulo'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.titulo && formik.errors.titulo
                      ? formik.errors.titulo
                      : ''
                  }
                  error={formik.touched.titulo && formik.errors.titulo}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    style={{ margin: '1em 0' }}
                    color='primary'
                    size='large'
                  >
                    {props.match.params.id
                      ? 'Atualizar Categoria'
                      : 'Cadastrar Categoria'}
                  </Button>
                </div>
                <ModalConfirmacaoForm
                  open={modalOpen}
                  loading={loading}
                  success={categoriasEmpresasSuccess}
                  onClick={() => {
                    formik.handleReset()
                    setModalOpen(false)
                  }}
                  onClose={() => {
                    formik.handleReset()
                    setModalOpen(false)
                  }}
                  message={
                    props.match.params.id
                      ? 'Categoria atualizada com sucesso!'
                      : 'Categoria cadastrada com sucesso!'
                  }
                >
                  <Button
                    onClick={() => {
                      history.push('/categorias-empresas')
                    }}
                    color='primary'
                  >
                    Voltar para Categorias
                  </Button>
                  {!props.match.params.id && (
                    <Button
                      onClick={() => {
                        formik.handleReset()
                        setModalOpen(false)
                      }}
                      color='primary'
                      autoFocus
                    >
                      Cadastrar nova categoria
                    </Button>
                  )}
                </ModalConfirmacaoForm>
              </Form>
            )}
          </Formik>
        </div>
      </PageWrapperPainel>
    )
  )
}

export default EditCategoriaEmpresaScreen
