import {
  USUARIO_LOGIN_REQUEST,
  USUARIO_LOGIN_SUCCESS,
  USUARIO_LOGIN_FAIL,
  USUARIO_LOGOUT,
} from '../actions/usuario'
import Cookie from 'js-cookie'

const initialState = {
  usuarioInfo:
    Cookie.get('usuarioInfo') && JSON.parse(Cookie.get('usuarioInfo')),
}

const usuarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case USUARIO_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case USUARIO_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        usuarioInfo: action.payload,
      }
    case USUARIO_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case USUARIO_LOGOUT:
      return {}
    default:
      return state
  }
}

export default usuarioReducer
