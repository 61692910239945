import { Button } from '@chakra-ui/button'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { usePlayer } from '../../hooks/usePlayer'

const AtivarReproducaoDialog = () => {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef()

  const { usuarioInfo } = useSelector((state) => state.usuario)

  const { updatePlayer } = usePlayer()

  return (
    <>
      <Button
        borderRadius='full'
        variant='solid'
        colorScheme='blackAlpha'
        _hover={{
          backgroundColor: 'accent.500',
          borderColor: 'accent.500',
        }}
        borderWidth='1px'
        borderColor='white'
        size='sm'
        fontSize={['sm', 'md']}
        paddingX='5'
        onClick={() => setIsOpen(true)}
      >
        Reproduzir Aqui
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Reproduzir Aqui
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja reproduzir aqui? Ao confirmar, a reprodução
              em outros players será interrompida!
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme='gray' ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme='accent'
                onClick={() => {
                  onClose()
                  updatePlayer({
                    sessao: usuarioInfo?.idSessao,
                    statusReproducao: 0,
                  })
                }}
                ml={3}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default AtivarReproducaoDialog
