import Axios from 'axios'
import Cookie from 'js-cookie'

export const USUARIO_LOGIN_REQUEST = 'USUARIO_LOGIN_REQUEST'
export const USUARIO_LOGIN_SUCCESS = 'USUARIO_LOGIN_SUCCESS'
export const USUARIO_LOGIN_FAIL = 'USUARIO_LOGIN_FAIL'
export const USUARIO_LOGOUT = 'USUARIO_LOGOUT'

export const login = (usuario, senha) => async (dispatch) => {
  dispatch({ type: USUARIO_LOGIN_REQUEST, payload: { usuario, senha } })
  try {
    const data = await Axios.post(
      process.env.REACT_APP_API + '/usuario/login.php',
      { usuario: usuario, senha: senha }
    )
    // console.log(data);
    const idSessao = Math.floor(Math.random() * 100000000)
    dispatch({
      type: USUARIO_LOGIN_SUCCESS,
      payload: { ...data.data, idSessao },
    })
    Cookie.set(
      'usuarioInfo',
      JSON.stringify({ ...data.data, idSessao: idSessao })
    )
  } catch (error) {
    dispatch({ type: USUARIO_LOGIN_FAIL, payload: error.message })
    console.error(error)
  }
}

export const logout = () => ({
  type: USUARIO_LOGOUT,
})
