import { Button } from '@chakra-ui/button'
import { Stack } from '@chakra-ui/layout'
import { Heading } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/skeleton'
import { Th } from '@chakra-ui/table'
import { Tbody } from '@chakra-ui/table'
import { Tr } from '@chakra-ui/table'
import { Thead } from '@chakra-ui/table'
import { Table } from '@chakra-ui/table'
import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import CategoriaVinhetaItem from '../../../components/CategoriaVinhetaItem'
import ModalConfirmacaoForm from '../../../components/forms/ModalConfirmacaoForm'
import Axios from 'axios'
import { useSelector } from 'react-redux'

const SpotsCategories = ({ userId }) => {
  const { usuarioInfo } = useSelector((state) => state.usuario)

  const [categoriasVinhetas, setCategoriasVinhetas] = useState([])
  const [loadingCategorias, setLoadingCategorias] = useState(true)
  const [errorCategorias, setErrorCategorias] = useState()

  const [modalOpen, setModalOpen] = useState(false)
  const [categoriaExcluir, setCategoriaExcluir] = useState({})

  useEffect(() => {
    setLoadingCategorias(true)
    Axios.get(
      process.env.REACT_APP_API +
        '/categoria_vinheta/read.php?empresa=' +
        userId +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    ).then(({ data }) => {
      if (data.data) {
        setCategoriasVinhetas(data.data)
      } else {
        setErrorCategorias('Nenhuma Categoria Encontrada!')
      }
      setLoadingCategorias(false)
    })
    //eslint-disable-next-line
  }, [])

  const openModal = (id, titulo) => {
    setModalOpen(true)
    setCategoriaExcluir({ id, titulo })
  }

  const excluirCategoria = (id) => {
    Axios.get(
      process.env.REACT_APP_API +
        '/categoria_vinheta/delete.php?id=' +
        id +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    ).then((data) => {
      setLoadingCategorias(true)
      Axios.get(
        process.env.REACT_APP_API +
          '/categoria_vinheta/read.php?empresa=' +
          userId +
          '&token=' +
          encodeURIComponent(usuarioInfo.token),
        {
          headers: {
            Authorization: 'bearer ' + usuarioInfo.token,
            withCredentials: true,
          },
        }
      ).then(({ data }) => {
        if (data.data) {
          setCategoriasVinhetas(data.data)
        } else {
          setErrorCategorias('Nenhuma Categoria Encontrada!')
        }
        setLoadingCategorias(false)
      })

      setModalOpen(false)
    })
  }

  return (
    <>
      <Box w='100%' paddingY='6'>
        <Stack
          direction={['column', null, 'row']}
          justify='space-between'
          borderBottomColor='gray.200'
          borderBottomWidth='1px'
          paddingBottom='2'
          marginBottom='2'
        >
          <Heading fontSize='2xl'>Categorias de Vinhetas</Heading>
          <Link to={'/editar-categoria-vinhetas/empresa/' + userId}>
            <Button
              leftIcon={<BiPlus />}
              colorScheme='blueGray'
              variant='outline'
              size='sm'
            >
              Adicionar Categoria
            </Button>
          </Link>
        </Stack>
        <Table variant='simple' bg='white' borderRadius='md'>
          <Thead>
            <Tr>
              <Th>Título</Th>
              <Th isNumeric>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loadingCategorias ? (
              <>
                <Tr>
                  <Th>
                    <Skeleton height='2rem' />
                  </Th>
                  <Th isNumeric>
                    <Skeleton height='2rem' />
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Skeleton height='2rem' />
                  </Th>
                  <Th isNumeric>
                    <Skeleton height='2rem' />
                  </Th>
                </Tr>
              </>
            ) : errorCategorias ? (
              <Tr>
                <Th>{errorCategorias}</Th>
              </Tr>
            ) : (
              categoriasVinhetas &&
              categoriasVinhetas.map((categoria) => (
                <CategoriaVinhetaItem
                  onExcluir={() => {
                    openModal(categoria.id, categoria.titulo)
                  }}
                  key={categoria.id}
                  id={categoria.id}
                  titulo={categoria.titulo}
                />
              ))
            )}
          </Tbody>
        </Table>
      </Box>
      <ModalConfirmacaoForm
        open={modalOpen}
        success={modalOpen}
        onClick={() => {
          setModalOpen(false)
        }}
        onClose={() => {
          setModalOpen(false)
        }}
        message={`Tem certeza que deseja excluir a categoria '${categoriaExcluir.titulo}'?`}
      >
        <Button
          onClick={() => {
            excluirCategoria(categoriaExcluir.id)
          }}
          color='primary'
        >
          Excluir
        </Button>
        <Button
          onClick={() => {
            setModalOpen(false)
          }}
          color='primary'
          autoFocus
        >
          Cancelar
        </Button>
      </ModalConfirmacaoForm>
    </>
  )
}

export default SpotsCategories
