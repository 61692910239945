import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}))

function CircularProgressWithLabel(props) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress variant='static' {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='caption'
          component='div'
          color='textSecondary'
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default function UploadButtons(props) {
  const classes = useStyles()
  const [fileName, setFileName] = useState()
  return (
    <div
      style={{
        ...props.style,
        ...{
          alignItems: 'center',
          justifyContent: 'flex-start',
          display: 'flex',
        },
      }}
      className={classes.root}
    >
      <input
        name='imagem'
        accept={props.accept}
        className={classes.input}
        id='contained-button-file'
        type='file'
        onChange={(e) => {
          props.onChange(e)
          setFileName(e.target.files[0].name)
        }}
      />
      <label htmlFor='contained-button-file'>
        <Button variant='contained' color='default' component='span'>
          {props.label}
        </Button>
      </label>
      {/* <label htmlFor="icon-button-file">
                <IconButton color="default" aria-label="upload picture" component="span">
                    <PhotoCamera />
                </IconButton>
            </label> */}
      {fileName && (
        <label
          htmlFor='icon-button-file'
          style={{
            backgroundColor: '#2167AD',
            color: '#ffffff',
            padding: '0.3em 0.6em',
            borderRadius: '0.35em',
            fontSize: '9pt',
          }}
        >
          {fileName}
        </label>
      )}
      <CircularProgressWithLabel value={props.progress ? props.progress : 0} />
    </div>
  )
}
