import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TopoSessao from '../components/TopoSessao'
import * as Yup from 'yup'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import Axios from 'axios'
import { fetchSolicitacoes } from '../store/actions/solicitacoes'
import MySelect from '../components/forms/MySelect'
import MenuItem from '@material-ui/core/MenuItem'
import PageWrapperPainel from '../components/PageWrapperPainel'

const EditCategoriaEmpresaScreen = (props) => {
  let history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const [categoriasEmpresasSuccess, setCategoriasEmpresasSuccess] =
    useState(false)

  const dispatch = useDispatch()

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const solicitacoesStore = useSelector((state) => state.solicitacoes)
  const { solicitacoes, loading } = solicitacoesStore

  let solicitacaoSelected = {}

  if (props.match.params.id && !loading && solicitacoes.length > 0) {
    solicitacaoSelected = solicitacoes.find(
      (solicitacaoItem) => solicitacaoItem.id === props.match.params.id
    )
  }

  return (
    ((props.match.params.id && !loading && solicitacoes.length > 0) ||
      !props.match.params.id) && (
      <PageWrapperPainel>
        <TopoSessao
          titulo={
            props.match.params.id
              ? 'Editar Solicitação de Vinheta'
              : 'Solicitar Nova Vinheta/Spot'
          }
        />
        <div
          style={{
            width: 'calc(100% - 4rem)',
            maxWidth: '960px',
            margin: '2rem auto',
            padding: '1.5rem',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
          }}
        >
          <Formik
            initialValues={{
              tipo: solicitacaoSelected.tipo ? solicitacaoSelected.tipo : '',
              solicitacao: solicitacaoSelected.texto
                ? solicitacaoSelected.texto
                : '',
            }}
            validationSchema={Yup.object({
              tipo: Yup.string().required('Preenchimento Obrigatório!'),
              solicitacao: Yup.string()
                .max(4096, 'Tamanho máximo: 4096 caracteres')
                .required('Preenchimento Obrigatório!'),
            })}
            onSubmit={(data) => {
              if (props.match.params.id) {
                Axios.post(
                  process.env.REACT_APP_API +
                    '/solicitacao_vinheta/update.php?token=' +
                    encodeURIComponent(usuarioInfo.token),
                  {
                    texto: data.solicitacao,
                    tipo: data.tipo,
                    id: props.match.params.id,
                  }
                )
                  .then((dataRetorno) => {
                    setCategoriasEmpresasSuccess(true)
                    dispatch(fetchSolicitacoes(usuarioInfo.token))
                  })
                  .catch(function (error) {
                    // handle error
                    console.log(error)
                  })
              } else {
                Axios.post(
                  process.env.REACT_APP_API +
                    '/solicitacao_vinheta/create.php?token=' +
                    encodeURIComponent(usuarioInfo.token),
                  {
                    texto: data.solicitacao,
                    tipo: data.tipo,
                    usuario: usuarioInfo.id,
                  }
                )
                  .then((dataRetorno) => {
                    setCategoriasEmpresasSuccess(true)
                    dispatch(fetchSolicitacoes(usuarioInfo.token))
                  })
                  .catch(function (error) {
                    // handle error
                    console.log(error)
                  })
              }

              setModalOpen(true)
            }}
          >
            {(formik) => (
              <Form>
                <MySelect
                  label='Tipo de Vinheta/Spot'
                  name='tipo'
                  margin='normal'
                >
                  <MenuItem key='1' value='Interna'>
                    Interna
                  </MenuItem>
                  <MenuItem key='2' value='Frente Loja'>
                    Frente Loja
                  </MenuItem>
                  <MenuItem key='3' value='Carro de Som'>
                    Carro de Som
                  </MenuItem>
                </MySelect>
                <Field
                  label='Descreva aqui a sua solicitação'
                  name='solicitacao'
                  fullWidth
                  variant='outlined'
                  multiline
                  rows={5}
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.solicitacao && formik.errors.solicitacao
                      ? formik.errors.solicitacao
                      : ''
                  }
                  error={
                    formik.touched.solicitacao && formik.errors.solicitacao
                  }
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    style={{ margin: '1em 0' }}
                    color='primary'
                    size='large'
                  >
                    {props.match.params.id
                      ? 'Atualizar Solicitação'
                      : 'Cadastrar Solicitação'}
                  </Button>
                </div>
                <ModalConfirmacaoForm
                  open={modalOpen}
                  loading={loading}
                  success={categoriasEmpresasSuccess}
                  onClick={() => {
                    formik.handleReset()
                    setModalOpen(false)
                  }}
                  onClose={() => {
                    formik.handleReset()
                    setModalOpen(false)
                  }}
                  message={
                    props.match.params.id
                      ? 'Solicitação atualizada com sucesso!'
                      : 'Recebemos a sua solicitação! Em até 48h, estará disponível na sessão de vinhetas.'
                  }
                >
                  <Button
                    onClick={() => {
                      history.push('/solicitacoes')
                    }}
                    color='primary'
                  >
                    Voltar para Solicitações
                  </Button>
                  {!props.match.params.id && (
                    <Button
                      onClick={() => {
                        formik.handleReset()
                        setModalOpen(false)
                      }}
                      color='primary'
                      autoFocus
                    >
                      Cadastrar nova solicitação
                    </Button>
                  )}
                </ModalConfirmacaoForm>
              </Form>
            )}
          </Formik>
        </div>
      </PageWrapperPainel>
    )
  )
}

export default EditCategoriaEmpresaScreen
