import { HStack, Box, Text, Link as ChakraLink } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const LinkMenuLateral = ({ icon, to, children, ...props }) => {
  const location = useLocation()
  return (
    <ChakraLink
      paddingY='2.5'
      paddingX='3'
      width='100%'
      fontSize='md'
      // fontWeight='medium'
      color={location.pathname === to ? 'white' : 'whiteAlpha.700'}
      bg={location.pathname === to ? 'blackAlpha.400' : 'initial'}
      borderRadius='md'
      _hover={{
        textDecoration: 'none',
        backgroundColor: 'blackAlpha.200',
        color: 'accent.400',
      }}
      _active={{
        backgroundColor: 'accent.500',
        color: 'white',
      }}
      to={to}
      as={Link}
    >
      <HStack>
        <Box fontSize='xl'>{icon}</Box>
        <Text color={location.pathname.startsWith(to) ? 'white' : 'white'}>
          {children}
        </Text>
      </HStack>
    </ChakraLink>
  )
}

export default LinkMenuLateral
