import Axios from 'axios'

export const CATEGORIAS_VINHETAS_REQUEST = 'CATEGORIAS_VINHETAS_REQUEST'
export const CATEGORIAS_VINHETAS_SUCCESS = 'CATEGORIAS_VINHETAS_SUCCESS'
export const CATEGORIAS_VINHETAS_FAIL = 'CATEGORIAS_VINHETAS_FAIL'
export const ADD_CATEGORIAS_VINHETAS_SUCCESS = 'ADD_CATEGORIAS_VINHETAS_SUCCESS'

export const fetchCategoriasVinhetas = () => async (dispatch, getState) => {
  const usuarioInfo = getState().usuario.usuarioInfo

  try {
    dispatch({ type: CATEGORIAS_VINHETAS_REQUEST })
    let queryEmpresa =
      +usuarioInfo?.nivel !== 0 ? 'empresa=' + usuarioInfo.id : ''
    const { data } = await Axios.get(
      process.env.REACT_APP_API +
        '/categoria_vinheta/read.php?' +
        queryEmpresa +
        '&token=' +
        usuarioInfo.token,
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    )
    dispatch({ type: CATEGORIAS_VINHETAS_SUCCESS, payload: data.data })
  } catch (error) {
    dispatch({ type: CATEGORIAS_VINHETAS_FAIL, payload: error.message })
  }
}

export const addCategoriaVinheta = (token, dados) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIAS_VINHETAS_REQUEST })
    const { data } = await Axios.post(
      process.env.REACT_APP_API +
        '/categoria_vinheta/create.php?token=' +
        token,
      {
        titulo: dados.titulo,
        empresa: dados.empresa,
      }
    )
    dispatch({ type: ADD_CATEGORIAS_VINHETAS_SUCCESS })
    dispatch(fetchCategoriasVinhetas(token))
  } catch (error) {
    dispatch({ type: CATEGORIAS_VINHETAS_FAIL, payload: error.message })
  }
}
