const generateGenrePlaylist = (genre, allGenresData) => {
  const selectedGenreData = allGenresData.find(
    (gen) => String(gen.id) === String(genre)
  )

  //TODO Lidar com playlists pequenas (repetir músicas até ter pelo menos 300 na fila de reprodução)

  if (!selectedGenreData?.songs || selectedGenreData?.songs?.length <= 0) {
    return []
  }

  let newSongs = selectedGenreData?.songs
    .slice(0, 150)
    .map((song) => ({ ...song, isNew: true }))

  let playlist = [
    ...newSongs,
    ...selectedGenreData?.songs
      .slice(151)
      .sort(() => Math.random() - 0.5)
      .slice(0, 300),
  ]

  return playlist
    .sort(() => Math.random() - 0.5)
    .slice(0, 300)
    .map((song) => ({
      ...song,
      genre: { title: selectedGenreData.title, id: selectedGenreData.id },
    }))
}

export default generateGenrePlaylist
