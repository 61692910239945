import {
  CATEGORIAS_VINHETAS_REQUEST,
  CATEGORIAS_VINHETAS_SUCCESS,
  CATEGORIAS_VINHETAS_FAIL,
  ADD_CATEGORIAS_VINHETAS_SUCCESS,
} from '../actions/categoriasVinhetas'

const initialState = {
  categoriasVinhetas: [],
}

const categoriasVinhetas = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORIAS_VINHETAS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CATEGORIAS_VINHETAS_SUCCESS:
      return {
        ...state,
        loading: false,
        categoriasVinhetas: action.payload,
      }
    case CATEGORIAS_VINHETAS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ADD_CATEGORIAS_VINHETAS_SUCCESS:
      return {
        ...state,
        loading: false,
        categoriasVinhetasSuccess: true,
      }
    default:
      return state
  }
}

export default categoriasVinhetas
