import {
  VINHETAS_REQUEST,
  VINHETAS_SUCCESS,
  VINHETAS_FAIL,
  ADD_VINHETA_REQUEST,
  ADD_VINHETA_SUCCESS,
  ADD_VINHETA_FAIL,
  UPDATE_VINHETA,
} from '../actions/vinhetas'

const initialState = {
  vinhetas: [],
  vinhetaSuccess: false,
}

const vinhetasReducer = (state = initialState, action) => {
  switch (action.type) {
    case VINHETAS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case VINHETAS_SUCCESS:
      const vinhetas = action.payload
      vinhetas.reverse()
      return {
        ...state,
        loading: false,
        vinhetas: [...vinhetas],
      }
    case VINHETAS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ADD_VINHETA_REQUEST:
      return {
        ...state,
        loading: true,
        vinhetaSuccess: false,
      }
    case ADD_VINHETA_SUCCESS:
      return {
        ...state,
        loading: false,
        vinhetaSuccess: true,
      }
    case ADD_VINHETA_FAIL:
      return {
        ...state,
        loading: false,
        vinhetaSuccess: false,
        errorAddVinheta: action.payload,
      }
    case UPDATE_VINHETA:
      return {
        ...state,
        vinhetas: [
          ...state.vinhetas?.map((vinheta) =>
            vinheta.id === action.payload.id
              ? { ...vinheta, ...action.payload }
              : vinheta
          ),
        ],
      }
    default:
      return state
  }
}

export default vinhetasReducer
