import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Offline } from "react-detect-offline";

import InfoMusica from "./InfoMusica";
import Volume from "./Volume";
import BarraReproducao from "./BarraReproducao";
import PopupVinhetas from "./PopupVinhetas";
import { usePlayer } from "../../hooks/usePlayer";

import {
  Box,
  HStack,
  Center,
  CircularProgress,
  VStack,
  IconButton,
  Button,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  BiPauseCircle,
  BiPlayCircle,
  BiSkipNext,
  BiSkipPrevious,
} from "react-icons/bi";
import AtivarReproducaoDialog from "./AtivarReproducaoDialog";
import { analytics, storage } from "../../config/firebase";
import MenuRelogio from "./MenuRelogio";

const PERCENTUAL_VOLUME_MUSICA = 0.6;

const Player = () => {
  const { usuarioInfo } = useSelector((state) => state.usuario);

  const { data: playerData, loading, updatePlayer } = usePlayer();

  const {
    playlist,
    statusReproducao,
    // duration,
    volume: syncVolume,
    dataPlaylist,
    sessao,
  } = playerData;

  const [currentTime, setCurrentTime] = useState(0);
  const [dataPlaylistAtual, setDataPlaylistAtual] = useState(null);
  const [volume, setVolume] = useState(syncVolume || 50);
  const [audio, setAudio] = useState();
  const [audioHora, setAudioHora] = useState();
  const [carregouAudioHora, setCarregouAudioHora] = useState(0);
  const [musicaAtual, setMusicaAtual] = useState(null);
  const [proximaMusica, setProximaMusica] = useState(null);
  const [houveInteracao, setHouveInteracao] = useState(false);
  const [clicouVolume, setClicouVolume] = useState(false);
  const [carregandoAudio, setCarregandoAudio] = useState(false);

  const playerAtivo = sessao === usuarioInfo?.idSessao;

  const faixaAtual = playerData.faixaAtual || 0;

  useEffect(() => {
    if (playerAtivo && !houveInteracao) pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      audioHora &&
      audioHora.minuto &&
      !carregouAudioHora &&
      audio?.currentSrc !== audioHora.minuto.currentSrc &&
      playlist[+faixaAtual]?.pathname === "relogio"
    ) {
      audioHora.minuto.addEventListener("canplaythrough", () => {
        setCarregouAudioHora((prev) => prev + 1);
      });
      audioHora.minuto.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioHora]);

  useEffect(() => {
    if (carregouAudioHora === 1 && audioHora) {
      audioHora.pathReferenceHora.getDownloadURL().then((urlHora) => {
        if (
          audio?.currentSrc !== urlHora &&
          audio?.currentSrc !== audioHora.minuto.currentSrc &&
          playlist[+faixaAtual]?.pathname === "relogio"
        ) {
          setAudio(new Audio(urlHora));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregouAudioHora]);

  useEffect(() => {
    if (syncVolume && Number(syncVolume) !== Number(volume)) {
      setVolume(Number(syncVolume));
      setClicouVolume(false);
    } else if (
      Number(statusReproducao) === 1 &&
      typeof syncVolume === "undefined"
    ) {
      updatePlayer({ volume: 50 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncVolume]);

  useEffect(() => {
    if (!playerAtivo && audio) {
      audio.pause();
    }
  }, [playerAtivo, audio]);

  useEffect(() => {
    if (playerAtivo && +statusReproducao === 1 && houveInteracao) {
      if (audio) {
        audio.volume = ["spot", "clock"].includes(playlist[+faixaAtual]?.type)
          ? volume / 100
          : (volume * PERCENTUAL_VOLUME_MUSICA) / 100;
        console.log(
          ["spot", "clock"].includes(playlist[+faixaAtual]?.type)
            ? `Tocando Spot com volume ${volume / 100}`
            : `Tocando Música com volume ${
                (volume * PERCENTUAL_VOLUME_MUSICA) / 100
              }`
        );
        audio.play();
      } else if (!audio && playlist && playlist[+faixaAtual]) {
        initAudio();
      }
    } else if (playerAtivo) {
      if (!houveInteracao) {
        pause();
      } else if (audio) {
        audio.pause();
      }
    }
    //eslint-disable-next-line
  }, [statusReproducao]);

  useEffect(() => {
    if (dataPlaylist?.seconds !== dataPlaylistAtual?.seconds) {
      console.log("Playlist atualizada!");
      setDataPlaylistAtual(dataPlaylist);
      if (playlist && faixaAtual && faixaAtual >= playlist?.length - 2) {
        updatePlayer({ faixaAtual: 1 });
      }
      if (
        playlist &&
        playlist[+faixaAtual] &&
        playlist[+faixaAtual]?.pathname &&
        playlist[+faixaAtual + 1]?.pathname
      ) {
        if (
          musicaAtual !== playlist[+faixaAtual]?.pathname ||
          proximaMusica !== playlist[+faixaAtual + 1]?.pathname
        ) {
          initAudio();
        }
      }
    }
    //eslint-disable-next-line
  }, [playlist]);

  useEffect(() => {
    if (audio && playerAtivo) {
      audio.addEventListener("error", () => {
        console.log("erro no audio: ", audio.error);
        next();
      });
      audio.addEventListener("ended", () => {
        if (
          audioHora &&
          audio?.currentSrc !== audioHora?.minuto?.currentSrc &&
          playlist[+faixaAtual]?.pathname === "relogio"
        ) {
          setAudio(audioHora.minuto);
          setAudioHora(null);
        } else {
          next();
        }
      });
      audio.addEventListener("timeupdate", () => {
        setCurrentTime(audio.currentTime);
      });
      audio.addEventListener("waiting", () => {
        setCarregandoAudio(true);
      });
      audio.addEventListener("playing", () => {
        if (playlist[+faixaAtual]) {
          analytics.logEvent("tocou_musica", {
            ...playlist[+faixaAtual],
            generoId: playlist[+faixaAtual].genre?.id,
            generoTitulo: playlist[+faixaAtual].genre?.title,
          });
        }
        setCarregandoAudio(false);
      });
      audio.addEventListener("canplaythrough", () => {
        setCarregandoAudio(false);
      });

      if (statusReproducao === 1 && houveInteracao) {
        setTimeout(() => {
          updatePlayer({ duration: audio?.duration });
        }, 3000);
        audio.volume = ["spot", "clock"].includes(playlist[+faixaAtual]?.type)
          ? volume / 100
          : (volume * PERCENTUAL_VOLUME_MUSICA) / 100;
        console.log(
          ["spot", "clock"].includes(playlist[+faixaAtual]?.type)
            ? `Tocando Spot com volume ${volume / 100}`
            : `Tocando Música com volume ${
                (volume * PERCENTUAL_VOLUME_MUSICA) / 100
              }`
        );
        audio.play();
      } else {
        pause();
      }
    }

    return () => {
      if (audio) {
        audio.pause();
      }
    };
    //eslint-disable-next-line
  }, [audio]);

  useEffect(() => {
    if (audio) {
      audio.volume = ["spot", "clock"].includes(playlist[+faixaAtual]?.type)
        ? volume / 100
        : (volume * PERCENTUAL_VOLUME_MUSICA) / 100;
      // volume / 100;
    }
    //eslint-disable-next-line
  }, [volume]);

  useEffect(() => {
    if (playlist && playlist[+faixaAtual]) {
      initAudio();
    }
    //eslint-disable-next-line
  }, [faixaAtual]);

  useEffect(() => {
    if (playerAtivo && playlist && playlist[+faixaAtual]) {
      initAudio();
    }
    //eslint-disable-next-line
  }, [playerAtivo]);

  const pause = () => {
    updatePlayer({ statusReproducao: 0 });
    if (playerAtivo && audio) {
      audio.pause();
    }
  };

  const play = () => {
    if (playerAtivo && audio && statusReproducao === 0 && houveInteracao) {
      if (audio.currentSrc.includes(playlist[+faixaAtual]?.pathname)) {
        updatePlayer({ statusReproducao: 1 });
        audio.volume = ["spot", "clock"].includes(playlist[+faixaAtual]?.type)
          ? volume / 100
          : (volume * PERCENTUAL_VOLUME_MUSICA) / 100;
        console.log(
          ["spot", "clock"].includes(playlist[+faixaAtual]?.type)
            ? `Tocando Spot com volume ${volume / 100}`
            : `Tocando Música com volume ${
                (volume * PERCENTUAL_VOLUME_MUSICA) / 100
              }`
        );
        audio.play();
      } else {
        next();
      }
    } else if (!playerAtivo) {
      updatePlayer({ statusReproducao: 1 });
    } else if (
      playerAtivo &&
      houveInteracao &&
      statusReproducao === 0 &&
      playlist &&
      playlist[+faixaAtual]
    ) {
      initAudio();
    } else {
      pause();
    }
  };

  const next = () => {
    if (faixaAtual < playlist?.length - 2) {
      updatePlayer({ faixaAtual: +faixaAtual + 1 });
    } else {
      updatePlayer({ faixaAtual: 1 });
    }
    if (!playerAtivo) {
      setCurrentTime(0);
    }
  };

  const previous = () => {
    if (faixaAtual > 1) {
      updatePlayer({ faixaAtual: +faixaAtual - 1 });
      if (!playerAtivo) {
        setCurrentTime(0);
      }
    }
  };

  useEffect(() => {
    // Essas funções são acionadas quando o usuário utiliza as teclas especiais do teclado (play, pause...)
    if (navigator?.mediaSession) {
      navigator.mediaSession.setActionHandler("previoustrack", function () {
        previous();
      });
      navigator.mediaSession.setActionHandler("nexttrack", function () {
        next();
      });
      navigator.mediaSession.setActionHandler("play", async function () {
        play();
      });
      navigator.mediaSession.setActionHandler("pause", function () {
        pause();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist]);

  const initAudio = () => {
    const caminho = playlist[+faixaAtual]?.pathname;
    const caminhoProxima = playlist[+faixaAtual + 1]?.pathname;
    if (playerAtivo) {
      if (caminho === "relogio" && !audioHora) {
        initAudioRelogio();
      } else {
        if (audioHora) setAudioHora(null);
        //Criar novo objeto Audio
        if (!audio || musicaAtual !== caminho) {
          setAudio(new Audio(caminho));
          //eslint-disable-next-line
          let nextAudio = new Audio(caminhoProxima); //Necessário pra pré-carregar o áudio
        }
      }
    }
    setMusicaAtual(caminho);
    setProximaMusica(caminhoProxima);
  };

  const initAudioRelogio = async () => {
    try {
      setCarregandoAudio(true);
      if (audio) audio.pause();
      const horaAtual = new Date();
      const pathReferenceHora = storage.ref(
        `relogio/horas/${horaAtual.getHours()}.mp3`
      );
      const pathReferenceMinuto = storage.ref(
        `relogio/minutos/${horaAtual.getMinutes()}.mp3`
      );
      const urlMinuto = await pathReferenceMinuto.getDownloadURL();
      setCarregouAudioHora(0);
      setAudioHora({
        minuto: new Audio(urlMinuto),
        pathReferenceHora: pathReferenceHora,
      });
    } catch (error) {
      console.log("Erro hora certa!", error.code);
    }
  };

  return (
    <>
      <Box
        bg="blueGray.800"
        color="white"
        position="fixed"
        bottom="0"
        left="0"
        right="0"
      >
        {((playlist && playlist.length > 1) || loading) && (
          <Stack
            paddingX="6"
            paddingY="3"
            direction={["column", null, null, "row"]}
            alignItems="center"
          >
            {!loading ? (
              <>
                {playerAtivo && !houveInteracao && (
                  <Center
                    w="100%"
                    h="100%"
                    position="absolute"
                    top="0"
                    bottom="0"
                    left="0"
                    right="0"
                    zIndex="99"
                    bg="blackAlpha.900"
                  >
                    <Button
                      borderRadius="full"
                      variant="solid"
                      colorScheme="blackAlpha"
                      _hover={{
                        backgroundColor: "accent.500",
                        borderColor: "accent.500",
                      }}
                      borderWidth="1px"
                      borderColor="white"
                      size="lg"
                      fontSize="md"
                      onClick={() => {
                        setHouveInteracao(true);
                      }}
                    >
                      Clique aqui para ativar o player!
                    </Button>
                  </Center>
                )}
                <Box w={["100%", null, null, "50%"]}>
                  <InfoMusica
                    titulo={
                      playlist[+faixaAtual]?.title &&
                      playlist[+faixaAtual]?.title
                    }
                    genero={
                      playlist[+faixaAtual]?.genre?.title &&
                      playlist[+faixaAtual]?.genre?.title
                    }
                    proximaMusica={
                      playlist[+faixaAtual + 1]?.title &&
                      playlist[+faixaAtual + 1]?.title
                    }
                  />
                </Box>

                <VStack spacing="0">
                  <HStack>
                    <IconButton
                      onClick={previous}
                      id="botao-anterior"
                      aria-label="Música anterior"
                      icon={<BiSkipPrevious />}
                      fontSize="4xl"
                      variant="ghost"
                      colorScheme="whiteAlpha"
                      borderRadius="full"
                      color="white"
                    />
                    {statusReproducao === 0 ? (
                      <IconButton
                        onClick={play}
                        id="botao-play"
                        aria-label="Play"
                        icon={<BiPlayCircle />}
                        fontSize="5xl"
                        size="xl"
                        variant="ghost"
                        colorScheme="whiteAlpha"
                        borderRadius="full"
                        color="white"
                      />
                    ) : (
                      <IconButton
                        onClick={pause}
                        id="botao-pausar"
                        aria-label="Pause"
                        icon={<BiPauseCircle />}
                        fontSize="5xl"
                        size="xl"
                        variant="ghost"
                        colorScheme="whiteAlpha"
                        borderRadius="full"
                        color="white"
                      />
                    )}
                    <IconButton
                      onClick={() => {
                        analytics.logEvent("pulou_musica", {
                          ...playlist[+faixaAtual],
                          generoId: playlist[+faixaAtual].genre?.id,
                          generoTitulo: playlist[+faixaAtual].genre?.title,
                        });
                        next();
                      }}
                      id="botao-proximo"
                      aria-label="Próxima música"
                      icon={<BiSkipNext />}
                      fontSize="4xl"
                      variant="ghost"
                      colorScheme="whiteAlpha"
                      borderRadius="full"
                      color="white"
                    />
                  </HStack>

                  <BarraReproducao
                    showTimer={playerAtivo}
                    isIndeterminate={
                      !playerAtivo && Number(statusReproducao) === 1
                    }
                    isLoading={carregandoAudio}
                    currentTime={currentTime}
                    duration={audio?.duration}
                  />
                </VStack>

                <HStack
                  w={[null, null, null, "50%"]}
                  justifyContent="flex-end"
                  spacing="2"
                >
                  <Stack direction={["row", null, null, "column", "row"]}>
                    <MenuRelogio />
                    <PopupVinhetas />
                  </Stack>

                  <Box
                    onMouseDown={(e) => {
                      setClicouVolume(true);
                    }}
                    onTouchStart={(e) => {
                      setClicouVolume(true);
                    }}
                  >
                    <Volume
                      onChange={(e) => {
                        setVolume(e);
                        if (audio) {
                          audio.volume = ["spot", "clock"].includes(
                            playlist[+faixaAtual]?.type
                          )
                            ? e / 100
                            : (e * PERCENTUAL_VOLUME_MUSICA) / 100;
                          // e / 100;
                        }
                      }}
                      onChangeEnd={(e) => {
                        if (
                          syncVolume !== e &&
                          syncVolume !== volume &&
                          e === volume &&
                          clicouVolume
                        ) {
                          setClicouVolume(false);
                          updatePlayer({ volume: e });
                        }
                      }}
                      onToggleMute={() => {
                        let valorVolume = volume > 0 ? 0 : 50;
                        setVolume(valorVolume);
                      }}
                      value={Number(volume)}
                    />
                  </Box>
                </HStack>
              </>
            ) : (
              <Center w="100%" minH="20">
                <CircularProgress
                  isIndeterminate
                  color="white"
                  trackColor="transparent"
                />
              </Center>
            )}
          </Stack>
        )}

        {!loading && !playerAtivo && (
          <HStack
            bg="black"
            paddingY="2"
            paddingX="6"
            justifyContent="center"
            onClick={() => {
              setHouveInteracao(true);
            }}
          >
            <Text fontSize={["xs", "md"]}>
              Reproduzindo em outro dispositivo!
            </Text>
            <AtivarReproducaoDialog />
          </HStack>
        )}
        <Offline polling={{ url: "https://www.comunicaedu.com/" }}>
          <Box bg="red.600" color="white" padding="0.5">
            <Text textAlign="center" fontSize="sm">
              Parece que há um problema com a sua conexão com a internet!
            </Text>
          </Box>
        </Offline>
      </Box>
    </>
  );
};

export default Player;
