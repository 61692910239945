import { useContext, createContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { db, storage, firebase } from '../config/firebase'
import {
  createRequest,
  getAllRequests,
  getRequest,
  updateRequest,
  deleteRequest,
} from './useDatabase/requests'

const DatabaseContext = createContext({ genres: [] })

export function useDatabase() {
  return useContext(DatabaseContext)
}

export function DatabaseProvider({ children }) {
  const [genres, setGenres] = useState([])
  const [customPlaylists, setCustomPlaylists] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [requestsData, setRequestsData] = useState([])
  const [loadingRequests, setLoadingRequests] = useState(false)

  const { usuarioInfo } = useSelector((state) => state.usuario)

  const loadRequests = async () => {
    setLoadingRequests(true)
    const isAdmin = Number(usuarioInfo.nivel) === 0
    const results = await getAllRequests(isAdmin ? null : usuarioInfo.id)
    setRequestsData(results)
    setLoadingRequests(false)
  }

  const refreshRequests = () => {
    loadRequests()
  }

  useEffect(() => {
    if (usuarioInfo) {
      loadGenres()
      loadRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarioInfo])

  const getCustomPlaylists = async (userId) => {
    try {
      const querySnapshot = await db
        .collection('customPlaylists')
        .where('uid', '==', userId)
        .get()

      let customPlaylists = []
      querySnapshot.forEach((doc) => {
        customPlaylists.push({
          ...doc.data(),
          songs: [
            ...doc.data().songs?.map((song) => ({
              ...song,
              timestamp: song.timestamp?.toDate(),
            })),
          ],
          id: doc.id,
        })
      })

      customPlaylists.sort(function (a, b) {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })
      customPlaylists.forEach((customPlaylist) => {
        customPlaylist.songs.sort(function (a, b) {
          if (a.timestamp > b.timestamp) {
            return -1
          }
          if (a.timestamp < b.timestamp) {
            return 1
          }
          return 0
        })
      })

      return customPlaylists
    } catch (error) {
      console.log(
        'Erro ao recuperar lista de playlists personalizadas: ',
        error
      )
    }
  }

  const getCustomPlaylist = async (playlistId) => {
    try {
      const doc = await db.collection('customPlaylists').doc(playlistId).get()

      const playlistData = {
        ...doc.data(),
        songs: [
          ...doc.data().songs.map((song) => ({
            ...song,
            timestamp: song.timestamp?.toDate(),
          })),
        ],
        id: doc.id,
      }

      return playlistData
    } catch (error) {
      console.log(
        'Erro ao recuperar lista de playlists personalizadas: ',
        error
      )
    }
  }

  const addCustomPlaylist = async (data) => {
    const docRef = await db
      .collection('customPlaylists')
      .add({ ...data, songs: [] })
    return docRef.id
  }

  const updateCustomPlaylist = async (playlistId, data) => {
    await db.collection('customPlaylists').doc(playlistId).update(data)
    return playlistId
  }

  const deleteCustomPlaylist = async (playlistId) => {
    const playlistData = await db
      .collection('customPlaylists')
      .doc(playlistId)
      .get()

    if (playlistData.data()?.pathRef) {
      try {
        const storageRef = storage.ref(playlistData.data()?.pathRef)
        await storageRef.delete()
      } catch (error) {
        console.log(error)
      }
    }

    await db.collection('customPlaylists').doc(playlistId).delete()

    try {
      const storageRef = storage.ref(`songs/customPlaylists/${playlistId}`)
      await storageRef.delete()
    } catch (error) {
      console.log(error)
    }
  }

  function loadGenres() {
    setLoading(true)

    getCustomPlaylists(usuarioInfo?.id).then((dataPlaylists) => {
      setCustomPlaylists(dataPlaylists)
    })

    db.collection('genres')
      .get()
      .then((querySnapshot) => {
        let genresList = []
        querySnapshot.forEach((doc) => {
          genresList.push({
            ...doc.data(),
            songs: [
              ...doc.data().songs.map((song) => ({
                ...song,
                timestamp: song.timestamp.toDate(),
              })),
            ],
            id: doc.id,
          })
        })
        genresList.sort(function (a, b) {
          if (a.title < b.title) {
            return -1
          }
          if (a.title > b.title) {
            return 1
          }
          return 0
        })
        genresList.forEach((genre) => {
          genre.songs.sort(function (a, b) {
            if (a.timestamp > b.timestamp) {
              return -1
            }
            if (a.timestamp < b.timestamp) {
              return 1
            }
            return 0
          })
        })
        setGenres(genresList)
        setLoading(false)
        setError(null)
      })
      .catch((error) => {
        setLoading(false)
        setError(error)
        console.log('Erro ao recuperar lista de gêneros: ', error)
      })
  }

  const deleteMusic = async (title, genreId) => {
    let selectedGenre = genres.find((genre) => genre.id === genreId)
    const songInfo = selectedGenre.songs.find((song) => song.title === title)
    let newSongList = selectedGenre.songs.filter((song) => song.title !== title)
    await db.collection('genres').doc(genreId).update({
      songs: newSongList,
    })

    setGenres((prev) =>
      prev.map((prevGenre) =>
        prevGenre.id === genreId
          ? { ...prevGenre, songs: newSongList }
          : prevGenre
      )
    )
    if (songInfo?.pathRef) {
      const storageRef = storage.ref(songInfo.pathRef)
      await storageRef.delete()
    }
  }

  const deleteMusicFromCustomPlaylist = async (title, playlistId) => {
    let selectedGenre = await getCustomPlaylist(playlistId)

    const songInfo = selectedGenre.songs.find((song) => song.title === title)

    let newSongList = selectedGenre.songs.filter((song) => song.title !== title)

    await db.collection('customPlaylists').doc(playlistId).update({
      songs: newSongList,
    })

    setGenres((prev) =>
      prev.map((prevGenre) =>
        prevGenre.id === playlistId
          ? { ...prevGenre, songs: newSongList }
          : prevGenre
      )
    )
    if (songInfo?.pathRef) {
      const storageRef = storage.ref(songInfo.pathRef)
      await storageRef.delete()
    }
  }

  const deleteGenre = async (genreId) => {
    await db.collection('genres').doc(genreId).delete()

    const storageRef = storage.ref(`songs/${genreId}`)
    await storageRef.delete()

    setGenres((prev) => prev.filter((prevGenre) => prevGenre.id !== genreId))
  }

  const addGenre = async (data) => {
    const docRef = await db.collection('genres').add({ ...data, songs: [] })
    loadGenres()
    return docRef.id
  }

  const updateGenre = async (genreId, data) => {
    await db.collection('genres').doc(genreId).update(data)
    loadGenres()
    return genreId
  }

  const addMusic = async (data) => {
    const newSong = {
      title: data.title,
      pathname: data.pathname,
      timestamp: new Date(),
      pathRef: data.pathRef,
    }

    await db
      .collection('genres')
      .doc(data.genreId)
      .update({
        songs: firebase.firestore.FieldValue.arrayUnion(newSong),
      })
  }

  const addMusicToCustomPlaylist = async (data) => {
    const newSong = {
      title: data.title,
      pathname: data.pathname,
      timestamp: new Date(),
      pathRef: data.pathRef,
    }

    await db
      .collection('customPlaylists')
      .doc(data.playlistId)
      .update({
        songs: firebase.firestore.FieldValue.arrayUnion(newSong),
      })
  }

  const value = {
    genres,
    setGenres,
    loading,
    error,
    deleteMusic,
    addMusic,
    deleteGenre,
    addGenre,
    updateGenre,
    getCustomPlaylists,
    addCustomPlaylist,
    updateCustomPlaylist,
    deleteCustomPlaylist,
    getCustomPlaylist,
    addMusicToCustomPlaylist,
    deleteMusicFromCustomPlaylist,
    customPlaylists,
    requests: {
      data: requestsData,
      loading: loadingRequests,
      createRequest,
      getRequest,
      updateRequest,
      deleteRequest,
      refreshRequests,
    },
  }

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  )
}
