import { Link } from 'react-router-dom'
import { Tr } from '@chakra-ui/table'
import { Td } from '@chakra-ui/table'
import { HStack } from '@chakra-ui/layout'
import { IconButton } from '@chakra-ui/button'
import { BiBookmarks, BiEdit, BiTrash } from 'react-icons/bi'

const CategoriaVinhetaItem = (props) => {
  return (
    <Tr>
      <Td>
        <IconButton
          aria-label='Ícone de Música'
          icon={<BiBookmarks />}
          fontSize='3xl'
          size='lg'
          borderRadius='full'
          marginRight='4'
          disabled
          _hover={{ cursor: 'default' }}
          _disabled={{ color: 'gray.600' }}
        />
        <Link to={`/vinheta/${props.id}`}>{props.titulo}</Link>
      </Td>
      <Td isNumeric>
        <HStack align='flex-start' justify='flex-end'>
          <Link to={`/editar-categoria-vinhetas/id/${props.id}`}>
            <IconButton
              aria-label='Editar'
              _hover={{ color: 'white', bg: 'blue.500' }}
              icon={<BiEdit />}
            />
          </Link>
          <IconButton
            aria-label='Excluir'
            _hover={{ color: 'white', bg: 'red.500' }}
            icon={<BiTrash />}
            onClick={props.onExcluir}
          />
        </HStack>
      </Td>
    </Tr>
  )
}

export default CategoriaVinhetaItem
