const includeSpotInPlaylist = ({ playlist, faixaAtual, vinhetas, spot }) => {
  let vinheta = vinhetas.find((vinhetaItem) => vinhetaItem.id === spot)

  let vinhetaReproducao = {
    ...vinheta,
    title: vinheta.titulo,
    type: 'spot',
    pathname: `https://comunicaedu.com/vinhetas/${vinheta.categoria.id}/${vinheta.id}.mp3`,
    genre: { title: vinheta.categoria?.titulo, id: vinheta.categoria?.id },
  }

  let playlistFiltrada = playlist.filter(
    (faixa, indexFaixa) =>
      !(
        +indexFaixa < +faixaAtual + 2 &&
        +indexFaixa > +faixaAtual &&
        faixa.tipo === 'vinheta'
      ) &&
      !(
        faixa.caminho === vinhetaReproducao.caminho &&
        +indexFaixa < +faixaAtual + 5 &&
        +indexFaixa > +faixaAtual
      )
  )

  let novaPlaylist = [
    ...playlistFiltrada.slice(0, +faixaAtual),
    vinhetaReproducao,
    ...playlistFiltrada.slice(+faixaAtual + 1),
  ]

  return novaPlaylist
}

export default includeSpotInPlaylist
