import logoSite from '../imagens/logo.png'
import LinkMenuLateral from './LinkMenuLateral'
import { useSelector } from 'react-redux'

import { Box, VStack } from '@chakra-ui/react'
import {
  BiAlbum,
  BiCog,
  BiCommentDetail,
  BiHome,
  BiMusic,
  BiStation,
  BiStore,
} from 'react-icons/bi'

const MenuLateral = () => {
  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  return (
    <Box
      bg='blueGray.700'
      paddingX='4'
      paddingY='8'
      color='white'
      minW='56'
      minH='100vh'
      display={['none', null, 'initial']}
    >
      <VStack w='100%'>
        <Box w='36' maxW='100%' paddingY='8'>
          <img
            src={logoSite}
            alt='ComunicaEdu'
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>

        <VStack alignItems='stretch' spacing='2' w='100%'>
          <LinkMenuLateral to='/' icon={<BiHome />}>
            Inicio
          </LinkMenuLateral>
          {usuarioInfo?.nivel === '0' && (
            <LinkMenuLateral to='/empresas' icon={<BiStore />}>
              Empresas
            </LinkMenuLateral>
          )}
          <LinkMenuLateral to='/vinheta' label='Vinhetas' icon={<BiStation />}>
            Vinhetas
          </LinkMenuLateral>
          {usuarioInfo?.nivel === '0' && (
            <LinkMenuLateral to='/generos' icon={<BiAlbum />}>
              Gêneros
            </LinkMenuLateral>
          )}
          {usuarioInfo?.nivel === '0' && (
            <LinkMenuLateral to='/adicionar-musica' icon={<BiMusic />}>
              Cadastrar Músicas
            </LinkMenuLateral>
          )}
          <LinkMenuLateral to='/solicitacoes' icon={<BiCommentDetail />}>
            Solicitações
          </LinkMenuLateral>
          <LinkMenuLateral to='/configuracoes' icon={<BiCog />}>
            Configurações
          </LinkMenuLateral>
        </VStack>
      </VStack>
    </Box>
  )
}

export default MenuLateral
