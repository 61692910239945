import { IconButton } from '@chakra-ui/button'
import { Button } from '@chakra-ui/button'
import { FormLabel } from '@chakra-ui/form-control'
import { FormControl } from '@chakra-ui/form-control'
import { useDisclosure } from '@chakra-ui/hooks'
import { Input } from '@chakra-ui/input'
import { Box } from '@chakra-ui/layout'
import { VStack } from '@chakra-ui/layout'
import { ModalContent } from '@chakra-ui/modal'
import { ModalFooter } from '@chakra-ui/modal'
import { ModalBody } from '@chakra-ui/modal'
import { ModalHeader } from '@chakra-ui/modal'
import { ModalOverlay } from '@chakra-ui/modal'
import { Modal } from '@chakra-ui/modal'
import { Progress } from '@chakra-ui/progress'
import { useToast } from '@chakra-ui/toast'
import { useEffect, useState } from 'react'
import { BiEdit } from 'react-icons/bi'
import { storage } from '../../../config/firebase'
import { useDatabase } from '../../../hooks/useDatabase'

const EditCustomPlaylist = ({ playlistId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [title, setTitle] = useState('')
  const [image, setImage] = useState(null)
  const [imageProgress, setImageProgress] = useState(null)

  const { updateCustomPlaylist, getCustomPlaylist } = useDatabase()

  const toast = useToast()

  const handleSuccess = () => {
    toast({
      title: 'Playlist cadastrada com sucesso!',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
    setTitle('')
    setImage(null)
    setImageProgress(null)
    onClose()
  }

  useEffect(() => {
    getCustomPlaylist(playlistId).then((data) => {
      setTitle(data?.title)
    })
  }, [getCustomPlaylist, playlistId])

  const updateWithImage = (data, image) => {
    const fileExtension = image.name.split('.').pop()

    const storageRef = storage.ref(
      `images/customPlaylists/${playlistId}.${fileExtension}`
    )

    let uploadTask = storageRef.put(image)

    uploadTask.on(
      'state_changed',
      function (snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setImageProgress(progress)
      },
      function (error) {
        toast({
          title: 'Ocorreu um Erro!',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setImageProgress(null)
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          updateCustomPlaylist(playlistId, {
            ...data,
            imagePath: downloadURL,
            pathRef: storageRef.fullPath,
          }).then(handleSuccess)
        })
      }
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setImageProgress(1)
    updateCustomPlaylist(playlistId, { title }).then((playlistId) => {
      if (image) {
        updateWithImage({ title }, image)
      } else {
        handleSuccess()
      }
    })
  }

  return (
    <>
      <IconButton
        aria-label='Editar'
        _hover={{ color: 'white', bg: 'blue.500' }}
        icon={<BiEdit />}
        onClick={onOpen}
      />

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Playlist Exclusiva</ModalHeader>
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <VStack>
                <FormControl id='title'>
                  <FormLabel>Título</FormLabel>
                  <Input
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                </FormControl>
                <FormControl id='title'>
                  <FormLabel>Substituir Imagem</FormLabel>
                  <Input
                    type='file'
                    onChange={(e) => {
                      setImage(e.target.files[0])
                    }}
                  />
                </FormControl>
              </VStack>
              {imageProgress && (
                <Box marginY='4'>
                  <Progress
                    colorScheme='green'
                    hasStripe
                    isAnimated
                    size='md'
                    value={imageProgress}
                  />
                </Box>
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Cancelar</Button>
              <Button
                minW='28'
                marginLeft='3'
                type='submit'
                colorScheme='green'
                isLoading={imageProgress}
              >
                Salvar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditCustomPlaylist
