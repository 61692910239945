import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import MySelect from '../components/forms/MySelect'
import Button from '@material-ui/core/Button'
import { addUsuario, updateUsuario } from '../store/actions/usuarios'
import TopoSessao from '../components/TopoSessao'
import ButtonUpload from '../components/forms/ButtonUpload'
import * as Yup from 'yup'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import imageExists from 'image-exists'
import PageWrapperPainel from '../components/PageWrapperPainel'

const AddEmpresaScreen = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()

  const [imagem, setImagem] = useState()
  const [imagemAnterior, setImagemAnterior] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const categoriasEmpresasStore = useSelector(
    (state) => state.categoriasEmpresas
  )
  const { categoriasEmpresas, loading, error } = categoriasEmpresasStore

  const usuariosStore = useSelector((state) => state.usuarios)
  const {
    usuarios,
    loadingUsuarios,
    loadingImagem,
    errorImagem,
    progressImagem,
    usuarioSuccess,
  } = usuariosStore

  let dadosForm = {
    nome: '',
    usuario: '',
    senha: '',
    email: '',
    contato: '',
    empresa: '',
    categoria: '',
  }

  if (props.match.params.id && !loadingUsuarios && usuarios.length > 1) {
    const usuarioSelected = usuarios.find(
      (user) => user.id === props.match.params.id
    )
    dadosForm.nome = usuarioSelected.nome
    dadosForm.usuario = usuarioSelected.usuario
    dadosForm.email = usuarioSelected.email
    dadosForm.contato = usuarioSelected.contato
    dadosForm.empresa = usuarioSelected.empresa
    dadosForm.categoria = usuarioSelected.categoria.id
  }

  useEffect(() => {
    imageExists(
      '../imagens/empresas/' + props.match.params.id + '.jpeg',
      (image) => {
        if (image) {
          setImagemAnterior(
            '../imagens/empresas/' + props.match.params.id + '.jpeg'
          )
        } else {
          imageExists(
            '../imagens/empresas/' + props.match.params.id + '.jpg',
            (image) => {
              if (image) {
                setImagemAnterior(
                  '../imagens/empresas/' + props.match.params.id + '.jpg'
                )
              } else {
                imageExists(
                  '../imagens/empresas/' + props.match.params.id + '.png',
                  (image) => {
                    if (image) {
                      setImagemAnterior(
                        '../imagens/empresas/' + props.match.params.id + '.png'
                      )
                    }
                  }
                )
              }
            }
          )
        }
      }
    )
    //eslint-disable-next-line
  }, [])

  const validationSchema = props.match.params.id
    ? Yup.object({
        nome: Yup.string()
          .max(255, 'Tamanho máximo: 255 caracteres')
          .required('Preenchimento Obrigatório!'),
        usuario: Yup.string()
          .max(64, 'Tamanho máximo: 64 caracteres')
          .min(3, 'Tamanho mínimo: 3 caracteres')
          .required('Preenchimento Obrigatório!'),
        senha: Yup.string()
          .max(64, 'Tamanho máximo: 64 caracteres')
          .min(6, 'Tamanho mínimo: 6 caracteres'),
        email: Yup.string().email('Email inválido!'),
        empresa: Yup.string().required('Preenchimento Obrigatório!'),
        categoria: Yup.number().required('Preenchimento Obrigatório!'),
      })
    : Yup.object({
        nome: Yup.string()
          .max(255, 'Tamanho máximo: 255 caracteres')
          .required('Preenchimento Obrigatório!'),
        usuario: Yup.string()
          .max(64, 'Tamanho máximo: 64 caracteres')
          .min(3, 'Tamanho mínimo: 3 caracteres')
          .required('Preenchimento Obrigatório!'),
        senha: Yup.string()
          .max(64, 'Tamanho máximo: 64 caracteres')
          .min(6, 'Tamanho mínimo: 6 caracteres')
          .required('Preenchimento Obrigatório!'),
        email: Yup.string().email('Email inválido!'),
        empresa: Yup.string().required('Preenchimento Obrigatório!'),
        categoria: Yup.number().required('Preenchimento Obrigatório!'),
      })

  return (
    ((props.match.params.id && !loadingUsuarios && usuarios.length > 1) ||
      !props.match.params.id) && (
      <PageWrapperPainel>
        <TopoSessao
          titulo={
            props.match.params.id
              ? 'Editar Empresa: ' + dadosForm.empresa
              : 'Adicionar Empresa'
          }
        />
        <div
          style={{
            width: 'calc(100% - 4rem)',
            maxWidth: '960px',
            margin: '2rem auto',
            padding: '1.5rem',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
          }}
        >
          <Formik
            initialValues={{
              nome: dadosForm.nome,
              usuario: dadosForm.usuario,
              senha: dadosForm.senha,
              email: dadosForm.email,
              contato: dadosForm.contato,
              empresa: dadosForm.empresa,
              categoria: dadosForm.categoria,
            }}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              if (props.match.params.id) {
                dispatch(
                  updateUsuario(
                    usuarioInfo.token,
                    data,
                    imagem,
                    props.match.params.id
                  )
                )
              } else {
                dispatch(addUsuario(usuarioInfo.token, data, imagem))
              }
              setModalOpen(true)
            }}
          >
            {(formik) => (
              <Form>
                <Field
                  label='Nome Completo (usuário)'
                  name='nome'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.nome && formik.errors.nome
                      ? formik.errors.nome
                      : ''
                  }
                  error={formik.touched.nome && formik.errors.nome}
                />
                <Field
                  label='Usuário'
                  name='usuario'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.usuario && formik.errors.usuario
                      ? formik.errors.usuario
                      : ''
                  }
                  error={formik.touched.usuario && formik.errors.usuario}
                />
                <Field
                  label='Senha'
                  name='senha'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.senha && formik.errors.senha
                      ? formik.errors.senha
                      : ''
                  }
                  error={formik.touched.senha && formik.errors.senha}
                />
                <Field
                  label='Email'
                  name='email'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ''
                  }
                  error={formik.touched.email && formik.errors.email}
                />
                <Field
                  label='Contato'
                  name='contato'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.contato && formik.errors.contato
                      ? formik.errors.contato
                      : ''
                  }
                  error={formik.touched.contato && formik.errors.contato}
                />
                <Field
                  label='Nome da Empresa'
                  name='empresa'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.empresa && formik.errors.empresa
                      ? formik.errors.empresa
                      : ''
                  }
                  error={formik.touched.empresa && formik.errors.empresa}
                />
                <MySelect
                  label='Categoria da Empresa'
                  name='categoria'
                  margin='normal'
                >
                  {loading ? (
                    <div>Carregando...</div>
                  ) : error ? (
                    <div>{error}</div>
                  ) : (
                    categoriasEmpresas &&
                    categoriasEmpresas.map((categoriaEmpresa) => (
                      <MenuItem value={categoriaEmpresa.id}>
                        {categoriaEmpresa.titulo}
                      </MenuItem>
                    ))
                  )}
                </MySelect>

                {imagemAnterior && (
                  <div style={{ margin: '1em 0' }}>
                    <p
                      style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '10pt' }}
                    >
                      Logotipo Atual:
                    </p>
                    <img
                      style={{ height: '6em', width: 'auto' }}
                      src={imagemAnterior}
                      alt='Logotipo Atual'
                    />
                  </div>
                )}

                <ButtonUpload
                  accept='image/*'
                  label='Escolher foto'
                  name='imagem'
                  style={{ margin: '0.5em 0' }}
                  progress={progressImagem}
                  onChange={(e) => {
                    setImagem(e.target.files[0])
                  }}
                />
                {errorImagem && <p>Erro no envio da imagem!</p>}

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    disabled={loadingImagem}
                    type='submit'
                    variant='contained'
                    style={{ margin: '1em 0' }}
                    color='primary'
                    size='large'
                  >
                    {props.match.params.id
                      ? 'Salvar alterações'
                      : 'Cadastrar empresa'}
                  </Button>
                </div>

                <ModalConfirmacaoForm
                  open={modalOpen}
                  success={usuarioSuccess}
                  onClick={() => {
                    formik.handleReset()
                    setImagem(null)
                    setModalOpen(false)
                  }}
                  onClose={() => {
                    formik.handleReset()
                    setImagem(null)
                    setModalOpen(false)
                  }}
                  message='Usuário cadastrado com sucesso!'
                >
                  <Button
                    onClick={() => {
                      history.push('/empresas')
                    }}
                    color='primary'
                  >
                    Voltar para Empresas
                  </Button>

                  {!props.match.params.id && (
                    <Button
                      onClick={() => {
                        formik.handleReset()
                        setImagem(null)
                        setModalOpen(false)
                      }}
                      color='primary'
                      autoFocus
                    >
                      Cadastrar nova empresa
                    </Button>
                  )}
                </ModalConfirmacaoForm>
              </Form>
            )}
          </Formik>
        </div>
      </PageWrapperPainel>
    )
  )
}

export default AddEmpresaScreen
