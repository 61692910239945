import Genero from '../components/Genero'
import { usePlayer } from '../hooks/usePlayer'
import { Heading } from '@chakra-ui/layout'
import { AnimatePresence, motion } from 'framer-motion'
import PageWrapperPainel from '../components/PageWrapperPainel'
import { Wrap } from '@chakra-ui/layout'
import { useDatabase } from '../hooks/useDatabase'

const HomeScreen = () => {
  const { data } = usePlayer()
  const { genres, customPlaylists, loading, error } = useDatabase()

  return (
    <>
      <motion.div
        exit={{
          // opacity: 0,
          y: '-100%',
          transition: {
            duration: 0.4,
          },
        }}
        initial={{
          // opacity: 0,
          y: '-100%',
          transition: {
            duration: 0.4,
          },
        }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.4,
          },
        }}
        className='topo-sessao'
        style={{ zIndex: '-9999' }}
      >
        <Heading textAlign={['center', 'left']}>Gêneros</Heading>
      </motion.div>

      <PageWrapperPainel className='sessao'>
        <AnimatePresence>
          <Wrap
            spacing={['4', null, null, '6']}
            margin={[null, null, null, '2']}
          >
            {loading ? (
              <div style={{ color: '#ffffff' }}>Carregando...</div>
            ) : error ? (
              <div style={{ color: '#ffffff' }}>{error}</div>
            ) : (
              <>
                <Genero
                  reproduzindo={
                    String(data?.genero) === '0' &&
                    !data?.isCustomPlaylist &&
                    String(data.statusReproducao) === '1'
                  }
                  id={0}
                  index={0}
                  titulo='Aleatório'
                  imagem='/imagens/generos/aleatorio.jpg'
                />
                {customPlaylists?.map((customPlaylist, index) => (
                  <Genero
                    reproduzindo={
                      String(data?.playlistId) === String(customPlaylist.id) &&
                      data?.isCustomPlaylist &&
                      String(data.statusReproducao) === '1'
                    }
                    isCustomPlaylist
                    key={customPlaylist.id}
                    id={customPlaylist.id}
                    index={index}
                    titulo={customPlaylist.title}
                    imagem={
                      customPlaylist.imagePath
                        ? customPlaylist.imagePath
                        : '/imagens/generos/' + customPlaylist.id + '.jpg'
                    }
                  />
                ))}
                {genres?.map((genero, index) => (
                  <Genero
                    reproduzindo={
                      String(data?.genero) === String(genero.id) &&
                      !data?.isCustomPlaylist &&
                      String(data.statusReproducao) === '1'
                    }
                    key={genero.id}
                    id={genero.id}
                    index={index}
                    titulo={genero.title}
                    imagem={
                      genero.imagePath
                        ? genero.imagePath
                        : '/imagens/generos/' + genero.id + '.jpg'
                    }
                  />
                ))}
              </>
            )}
          </Wrap>
        </AnimatePresence>
      </PageWrapperPainel>
    </>
  )
}

export default HomeScreen
