import { useState, useEffect } from 'react'
import { uniqueId } from 'lodash'
import filesize from 'filesize'
import DropZone from 'react-dropzone'

import Button from '@material-ui/core/Button'

import TopoSessao from '../components/TopoSessao'
import MusicaUpload from '../components/forms/MusicaUpload'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import { useHistory } from 'react-router'
import PageWrapperPainel from '../components/PageWrapperPainel'
import { useDatabase } from '../hooks/useDatabase'
import { storage } from '../config/firebase'
import { Input } from '@chakra-ui/input'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { VStack } from '@chakra-ui/layout'
import Axios from 'axios'
import { useSelector } from 'react-redux'

const AddSongsToPlaylistScreen = (props) => {
  let history = useHistory()
  const { usuarioInfo } = useSelector((state) => state.usuario)

  const { addMusicToCustomPlaylist, getCustomPlaylist } = useDatabase()

  const [musicasUpload, setMusicasUpload] = useState([])
  const [playlist, setPlaylist] = useState('')
  const [uploadConcluido, setUploadConcluido] = useState(0)
  const [uploadEmAndamento, setUploadEmAndamento] = useState(false)
  const [usuario, setUsuario] = useState()

  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    getCustomPlaylist(props.match.params.customPlaylistId).then((data) => {
      setPlaylist(data)
    })
  }, [getCustomPlaylist, props.match.params.customPlaylistId])

  useEffect(() => {
    if (musicasUpload.length > 0 && uploadConcluido === musicasUpload.length)
      setModalOpen(true)
    //eslint-disable-next-line
  }, [uploadConcluido])

  useEffect(() => {
    if (playlist && usuarioInfo) {
      Axios.get(
        process.env.REACT_APP_API +
          '/usuario/read_single.php?id=' +
          playlist.uid +
          '&token=' +
          encodeURIComponent(usuarioInfo.token),
        {
          headers: {
            Authorization: 'bearer ' + usuarioInfo.token,
            withCredentials: true,
          },
        }
      ).then((data) => {
        setUsuario(data.data)
      })
    }
  }, [playlist, usuarioInfo])

  const handleReset = () => {
    setMusicasUpload([])
    setUploadConcluido(0)
    setUploadEmAndamento(false)
  }

  const handleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name.split('.')[0],
      readableSize: filesize(file.size),
      progress: 0,
      uploaded: false,
      uploading: false,
      error: false,
      duplicada: false,
    }))
    setMusicasUpload((prevState) => {
      return [...prevState, ...uploadedFiles]
    })
  }

  const handleSubmit = () => {
    let titulosPreenchidos = false

    musicasUpload.forEach((musica) => {
      if (musica.name === '' || musica.name === null) {
        titulosPreenchidos = false
      } else {
        titulosPreenchidos = true
      }
    })

    if (!titulosPreenchidos) {
      alert('Preencha todos os campos de título!')
    } else {
      setUploadEmAndamento(true)
      musicasUpload.forEach(processUpload)
    }
  }

  const updateFile = (id, data) => {
    console.log(id, data)
    setMusicasUpload((musicasAnteriores) => [
      ...musicasAnteriores.map((file) => {
        return file.id === id ? { ...file, ...data } : { ...file }
      }),
    ])
  }

  const processUpload = (uploadedFile) => {
    updateFile(uploadedFile.id, {
      uploading: true,
      error: false,
      uploaded: false,
      progress: 0,
    })

    const fileExtension = uploadedFile.file.name.split('.').pop()

    const storageRef = storage.ref(
      `songs/customPlaylists/${
        props.match.params.customPlaylistId
      }/${uploadedFile.name.substring(0, 23)}-${Math.random()
        .toString(36)
        .substring(2, 9)}.${fileExtension}`
    )

    const uploadTask = storageRef.put(uploadedFile.file)

    uploadTask.on(
      'state_changed',
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        updateFile(uploadedFile.id, { progress })
      },
      function (error) {
        updateFile(uploadedFile.id, { error: error.code, uploading: false })
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          addMusicToCustomPlaylist({
            title: uploadedFile.name,
            playlistId: props.match.params.customPlaylistId,
            pathname: downloadURL,
            pathRef: storageRef.fullPath,
          })
            .then(() => {
              updateFile(uploadedFile.id, {
                uploaded: true,
                uploading: false,
                progress: 100,
              })
              setUploadConcluido((previousTotal) => previousTotal + 1)
            })
            .catch((error) => {
              updateFile(uploadedFile.id, { error: true, uploading: false })
              console.error(error)
            })
        })
      }
    )
  }

  return (
    <PageWrapperPainel>
      <TopoSessao titulo='Adicionar Músicas' />
      <VStack spacing='4' padding='6' align='stretch'>
        <VStack w='100%' bg='white' borderRadius='md' padding='4'>
          <FormControl id='user'>
            <FormLabel>Cliente</FormLabel>
            <Input readOnly value={usuario?.empresa} />
          </FormControl>
          <FormControl id='playlist'>
            <FormLabel>Título da Playlist</FormLabel>
            <Input readOnly value={playlist?.title} />
          </FormControl>
        </VStack>

        {!uploadEmAndamento && (
          <DropZone accept='audio/*' onDropAccepted={handleUpload}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div
                style={{
                  backgroundColor: '#ffffff',
                  outlineWidth: '2px',
                  outlineColor: isDragReject
                    ? '#dd2244'
                    : isDragActive
                    ? '#059327'
                    : '#000000',
                  outlineStyle: 'dashed',
                  outlineOffset: '-0.6em',
                  padding: '4em',
                  textAlign: 'center',
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p
                  style={{
                    color: isDragReject
                      ? '#dd2244'
                      : isDragActive
                      ? '#059327'
                      : '#000000',
                  }}
                >
                  <strong>Clique para adicionar arquivos</strong> ou arraste e
                  solte aqui.
                </p>
              </div>
            )}
          </DropZone>
        )}

        <ul className='arquivos-upload'>
          {musicasUpload.map((musica) => (
            <MusicaUpload
              hideGenre
              playlist={playlist}
              key={musica.id}
              id={musica.id}
              titulo={musica.name}
              concluido={musica.uploaded}
              enviando={musica.uploading}
              progresso={musica.progress}
              erro={musica.error}
              duplicada={musica.duplicada}
              setDuplicada={(value, key) => {
                updateFile(key, { duplicada: value })
              }}
              onTituloChange={(e, key) => {
                updateFile(key, { name: e.target.value })
              }}
              onExcluir={(key) => {
                const musicas = musicasUpload.filter((file) => file.id !== key)
                setMusicasUpload([...musicas])
              }}
            />
          ))}
        </ul>
        <Button
          disabled={uploadEmAndamento}
          onClick={handleSubmit}
          variant='contained'
          color='primary'
        >
          Enviar Músicas
        </Button>
      </VStack>
      <ModalConfirmacaoForm
        open={modalOpen}
        loading={false}
        success={true}
        onClick={() => {
          handleReset()
          setModalOpen(false)
        }}
        onClose={() => {
          handleReset()
          setModalOpen(false)
        }}
        message='Músicas adicionadas com sucesso!'
      >
        <Button
          onClick={() => {
            history.push('/')
          }}
          color='primary'
        >
          Voltar para o painel
        </Button>
        {!props.match.params.id && (
          <Button
            onClick={() => {
              handleReset()
              setModalOpen(false)
            }}
            color='primary'
            autoFocus
          >
            Adicionar mais músicas
          </Button>
        )}
      </ModalConfirmacaoForm>
    </PageWrapperPainel>
  )
}

export default AddSongsToPlaylistScreen
