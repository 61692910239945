import { Image } from '@chakra-ui/image'
import { Box } from '@chakra-ui/layout'
import { AspectRatio } from '@chakra-ui/layout'
import { Heading } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { analytics } from '../config/firebase'
import { usePlayer } from '../hooks/usePlayer'
import IconeReproduzindo from './IconeReproduzindo'

const Genero = (props) => {
  const { updatePlayer } = usePlayer()

  const [loadingReproducao, setLoadingReproducao] = useState(false)

  const reproduzirGenero = () => {
    setLoadingReproducao(true)

    let dataToUpdate = {}

    if (props.isCustomPlaylist) {
      dataToUpdate.isCustomPlaylist = true
      dataToUpdate.playlistId = props.id
    } else {
      dataToUpdate.isCustomPlaylist = false
      dataToUpdate.genero = props.id
    }

    updatePlayer({
      ...dataToUpdate,
    }).then(() => {
      analytics.logEvent('reproduziu_genero', {
        generoId: props.id,
      })
      setLoadingReproducao(false)
    })
  }

  return (
    <Box
      onClick={reproduzirGenero}
      w={[
        'calc(100% / 2 - 1rem)',
        'calc(100% / 3 - 1rem)',
        'calc(100% / 4 - 1rem)',
        'calc(100% / 5 - 1.5rem)',
        'calc(100% / 6 - 1.5rem)',
      ]}
      borderRadius='sm'
      position='relative'
      color='white'
      cursor='pointer'
      transition='transform 0.2s'
      _hover={{ transform: 'scale(1.04)', transition: 'transform 0.1s' }}
      zIndex='0'
    >
      <motion.div
        exit={{
          opacity: 0,
          y: 20,
          transition: {
            duration: 0.5,
            delay: props.index * 0.025,
          },
        }}
        initial={{
          opacity: 0,
          y: 20,
          transition: {
            duration: 0.5,
          },
        }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.5,
            delay: props.index * 0.025,
          },
        }}
      >
        <Box>
          <Heading
            fontSize={['lg', 'lg', 'xl']}
            marginY='2'
            position='absolute'
            zIndex='1'
            bottom='1'
            right='2'
            textAlign='right'
            textShadow='0 0 1.2rem rgba(0,0,0,0.7)'
          >
            {props.titulo}
          </Heading>
          {loadingReproducao && (
            <Box position='absolute' top='0.4rem' left='0.4rem' zIndex='2'>
              <Spinner size='md' />
            </Box>
          )}
          {props.reproduzindo && !loadingReproducao && (
            <IconeReproduzindo
              style={{
                position: 'absolute',
                top: '0.4rem',
                left: '0.4rem',
                backgroundColor: '#000',
                padding: '0.2em',
                width: '1.3em',
                height: '1.3em',
                borderRadius: '2em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.1rem',
                zIndex: '2',
              }}
            />
          )}
          <AspectRatio w='100%' ratio={4 / 3}>
            <Image
              src={props.imagem}
              fallbackSrc='/imagens/generos/aleatorio.jpg'
              alt={props.titulo}
              objectFit='cover'
            />
          </AspectRatio>
        </Box>
      </motion.div>
    </Box>
  )
}

export default Genero
