import Axios from 'axios'

export const VINHETAS_REQUEST = 'VINHETAS_REQUEST'
export const VINHETAS_SUCCESS = 'VINHETAS_SUCCESS'
export const VINHETAS_FAIL = 'VINHETAS_FAIL'
export const ADD_VINHETA_REQUEST = 'ADD_VINHETA_REQUEST'
export const ADD_VINHETA_SUCCESS = 'ADD_VINHETA_SUCCESS'
export const ADD_VINHETA_FAIL = 'ADD_VINHETA_FAIL'
export const UPDATE_VINHETA = 'UPDATE_VINHETA'

export const fetchVinhetas = (token) => async (dispatch, getState) => {
  const usuarioInfo = getState().usuario.usuarioInfo

  try {
    dispatch({ type: VINHETAS_REQUEST })
    let queryEmpresa =
      +usuarioInfo?.nivel !== 0 ? 'empresa=' + usuarioInfo.id : ''

    const { data } = await Axios.get(
      process.env.REACT_APP_API +
        '/vinheta/read.php?' +
        queryEmpresa +
        '&token=' +
        usuarioInfo.token,
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    )
    dispatch({ type: VINHETAS_SUCCESS, payload: data.data })
  } catch (error) {
    dispatch({ type: VINHETAS_FAIL, payload: error.message })
  }
}

export const updateVinheta = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_VINHETA, payload: data })
}

export const addVinheta = (token, dados, audio) => async (dispatch) => {
  try {
    dispatch({ type: ADD_VINHETA_REQUEST })

    const offset = dados.inicio.getTimezoneOffset()
    let dataInicio = new Date(dados.inicio.getTime() - offset * 60 * 1000)
    let dataFim = new Date(dados.fim.getTime() - offset * 60 * 1000)

    const formulario = new FormData()

    formulario.append('arquivo', audio)
    formulario.append('titulo', dados.titulo)
    formulario.append('categoria', dados.categoria)
    formulario.append('frequencia', dados.frequencia)
    formulario.append('inicio', dataInicio.toISOString().split('T')[0])
    formulario.append('fim', dataFim.toISOString().split('T')[0])

    await Axios.post(
      process.env.REACT_APP_API + '/vinheta/create.php?token=' + token,
      formulario
    )
    dispatch({ type: ADD_VINHETA_SUCCESS })

    dispatch(fetchVinhetas(token))
  } catch (error) {
    dispatch({ type: ADD_VINHETA_FAIL, payload: error.message })
  }
}
