import { Button } from '@chakra-ui/button'
import { IconButton } from '@chakra-ui/button'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/modal'
import { useToast } from '@chakra-ui/toast'
import { useRef, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { useDatabase } from '../../../hooks/useDatabase'

const DeleteRequestDialog = ({ id, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef()

  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const {
    requests: { deleteRequest },
  } = useDatabase()

  const handleDelete = () => {
    setIsLoading(true)
    deleteRequest(id).then(() => {
      onSuccess && onSuccess()
      onClose()
      setIsLoading(false)
      toast({
        title: 'Solicitação excluida com sucesso!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    })
  }

  return (
    <>
      <IconButton
        aria-label='Excluir'
        _hover={{ color: 'white', bg: 'red.500' }}
        icon={<BiTrash />}
        onClick={() => setIsOpen(true)}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Excluir Solicitação
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja excluir a solicitação?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} disabled={isLoading} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme='red'
                isLoading={isLoading}
                onClick={handleDelete}
                ml={3}
              >
                Excluir
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default DeleteRequestDialog
