import { IconButton } from '@chakra-ui/button'
import { Stack } from '@chakra-ui/layout'
import { Heading } from '@chakra-ui/layout'
import { HStack } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/skeleton'
import { Th } from '@chakra-ui/table'
import { Tbody } from '@chakra-ui/table'
import { Tr } from '@chakra-ui/table'
import { Thead } from '@chakra-ui/table'
import { Table } from '@chakra-ui/table'
import { useEffect, useState } from 'react'
import { BiRefresh } from 'react-icons/bi'
import { useDatabase } from '../../../hooks/useDatabase'
import CreateCustomPlaylist from './CreateCustomPlaylist'
import CustomPlaylistItem from './CustomPlaylistItem'

const CustomPlaylists = ({ userId }) => {
  const [playlists, setPlaylists] = useState([])
  const [loading, setLoading] = useState(true)

  const { getCustomPlaylists } = useDatabase()

  useEffect(() => {
    getCustomPlaylists(userId).then((data) => {
      setPlaylists(data)
      setLoading(false)
    })
  }, [getCustomPlaylists, userId])

  return (
    <>
      <Box w='100%' paddingY='6'>
        <Stack
          direction={['column', null, 'row']}
          justify='space-between'
          borderBottomColor='gray.200'
          borderBottomWidth='1px'
          paddingBottom='2'
          marginBottom='2'
        >
          <Heading fontSize='2xl'>Playlists Exclusivas</Heading>
          <HStack>
            <CreateCustomPlaylist uid={userId} />
            <IconButton
              colorScheme='blueGray'
              variant='outline'
              size='sm'
              aria-label='Atualizar lista'
              icon={<BiRefresh />}
              onClick={() => {
                setLoading(true)
                getCustomPlaylists(userId).then((data) => {
                  setPlaylists(data)
                  setLoading(false)
                })
              }}
            />
          </HStack>
        </Stack>
        <Table variant='simple' bg='white' borderRadius='md'>
          <Thead>
            <Tr>
              <Th>Título</Th>
              <Th isNumeric>Total de Músicas</Th>
              <Th isNumeric>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <>
                <Tr>
                  <Th>
                    <Skeleton height='2rem' />
                  </Th>
                  <Th isNumeric>
                    <Skeleton height='2rem' />
                  </Th>
                  <Th isNumeric>
                    <Skeleton height='2rem' />
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Skeleton height='2rem' />
                  </Th>
                  <Th isNumeric>
                    <Skeleton height='2rem' />
                  </Th>
                  <Th isNumeric>
                    <Skeleton height='2rem' />
                  </Th>
                </Tr>
              </>
            ) : (
              playlists?.map((playlist) => (
                <CustomPlaylistItem
                  key={playlist.id}
                  id={playlist.id}
                  titulo={playlist.title}
                  quantidade={playlist.songs.length}
                />
              ))
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}

export default CustomPlaylists
