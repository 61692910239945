import { useEffect, useState } from 'react'
import Cookie from 'js-cookie'
import { useHistory } from 'react-router'
import { logout } from '../store/actions/usuario'
import { useDispatch } from 'react-redux'
import imageExists from 'image-exists'
import * as constants from '../util/constants'
import logoSite from '../imagens/logo.png'

import imageUsuarioPadrao from '../imagens/usuario.png'
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa'
import { BiUser } from 'react-icons/bi'
import MenuDrawer from './MenuDrawer'
import { analytics, auth } from '../config/firebase'

const TopoSite = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [imagemEmpresa, setImagemEmpresa] = useState(false)

  useEffect(() => {
    imageExists(
      constants.baseUrl + 'imagens/empresas/' + props.idEmpresa + '.jpeg',
      (image) => {
        if (image) {
          setImagemEmpresa(
            constants.baseUrl + 'imagens/empresas/' + props.idEmpresa + '.jpeg'
          )
        } else {
          imageExists(
            constants.baseUrl + 'imagens/empresas/' + props.idEmpresa + '.jpg',
            (image) => {
              if (image) {
                setImagemEmpresa(
                  constants.baseUrl +
                    'imagens/empresas/' +
                    props.idEmpresa +
                    '.jpg'
                )
              } else {
                imageExists(
                  constants.baseUrl +
                    'imagens/empresas/' +
                    props.idEmpresa +
                    '.png',
                  (image) => {
                    if (image) {
                      setImagemEmpresa(
                        constants.baseUrl +
                          'imagens/empresas/' +
                          props.idEmpresa +
                          '.png'
                      )
                    }
                  }
                )
              }
            }
          )
        }
      }
    )
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Flex
        w='100%'
        align='center'
        justify='space-between'
        paddingY={[null, null, '2']}
        paddingX='6'
        bg='blueGray.800'
        zIndex='9999'
      >
        <Box>
          <MenuDrawer
            imagemEmpresa={imagemEmpresa}
            nomeEmpresa={props.nomeEmpresa}
          />
        </Box>
        <Box display={['initial', null, 'none']}>
          <Image
            src={logoSite}
            boxSize='24'
            objectFit='contain'
            alt={props.nomeEmpresa}
            maxW='100%'
          />
        </Box>
        <HStack display={['none', null, 'flex']} zIndex='9999'>
          <Box>
            {imagemEmpresa ? (
              <Image
                src={imagemEmpresa ? imagemEmpresa : imageUsuarioPadrao}
                boxSize='24'
                objectFit='contain'
                alt={props.nomeEmpresa}
                maxW='100%'
              />
            ) : (
              <Box
                color='gray.200'
                fontSize='3xl'
                borderRadius='full'
                borderWidth='2px'
                borderColor='gray.200'
                marginY='4'
                padding='1'
              >
                <BiUser />
              </Box>
            )}
          </Box>
          <Menu>
            <MenuButton
              variant='ghost'
              colorScheme='whiteAlpha'
              color='white'
              as={Button}
              rightIcon={<FaChevronDown />}
            >
              {props.nomeEmpresa}
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  Cookie.remove('usuarioInfo')
                  dispatch(logout())
                  if (auth.currentUser) {
                    auth.signOut()
                    analytics.logEvent('signout', { userId: props.idEmpresa })
                  }
                  history.push('/')
                }}
              >
                Sair
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </>
  )
}

export default TopoSite
