import Axios from 'axios'

export const CATEGORIAS_EMPRESAS_REQUEST = 'CATEGORIAS_EMPRESAS_REQUEST'
export const CATEGORIAS_EMPRESAS_SUCCESS = 'CATEGORIAS_EMPRESAS_SUCCESS'
export const CATEGORIAS_EMPRESAS_FAIL = 'CATEGORIAS_EMPRESAS_FAIL'
export const ADD_CATEGORIAS_EMPRESAS_SUCCESS = 'ADD_CATEGORIAS_EMPRESAS_SUCCESS'

export const fetchCategoriasEmpresas = (token) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIAS_EMPRESAS_REQUEST })
    const { data } = await Axios.get(
      process.env.REACT_APP_API + '/categoria_empresa/read.php?token=' + token,
      {
        headers: {
          Authorization: 'bearer ' + token,
          withCredentials: true,
        },
      }
    )
    dispatch({ type: CATEGORIAS_EMPRESAS_SUCCESS, payload: data.data })
  } catch (error) {
    dispatch({ type: CATEGORIAS_EMPRESAS_FAIL, payload: error.message })
  }
}

export const addCategoriaEmpresa = (token, dados) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIAS_EMPRESAS_REQUEST })
    await Axios.post(
      process.env.REACT_APP_API +
        '/categoria_empresa/create.php?token=' +
        token,
      {
        titulo: dados.titulo,
      }
    )
    dispatch({ type: ADD_CATEGORIAS_EMPRESAS_SUCCESS })
    dispatch(fetchCategoriasEmpresas(token))
  } catch (error) {
    dispatch({ type: CATEGORIAS_EMPRESAS_FAIL, payload: error.message })
  }
}
