import { useState, useEffect } from 'react'
import Axios from 'axios'

import TopoSessao from '../../components/TopoSessao'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import PageWrapperPainel from '../../components/PageWrapperPainel'
import { Box } from '@chakra-ui/layout'
import { Heading } from '@chakra-ui/layout'
import Info from './components/Info'
import { BiEdit } from 'react-icons/bi'
import { Button } from '@chakra-ui/button'
import { VStack } from '@chakra-ui/layout'
import { Divider } from '@chakra-ui/layout'
import { SimpleGrid } from '@chakra-ui/layout'
import SpotsCategories from './components/SpotsCategories'
import CustomPlaylists from './components/CustomPlaylists'
import { db } from '../../config/firebase'

const EmpresaScreen = (props) => {
  const { usuarioInfo } = useSelector((state) => state.usuario)

  const [usuario, setUsuario] = useState()
  const [loadingUsuario, setLoadingUsuario] = useState(true)

  const [loadingLastAccess, setLoadingLastAccess] = useState(true)
  const [lastAccess, setLastAccess] = useState(null)

  useEffect(() => {
    Axios.get(
      process.env.REACT_APP_API +
        '/usuario/read_single.php?id=' +
        props.match.params.id +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    ).then((data) => {
      setUsuario(data.data)
      setLoadingUsuario(false)

      setLoadingLastAccess(true)
      try {
        db.collection('players')
          .doc(props.match.params.id)
          .get()
          .then((doc) => {
            const data = doc.data()
            if (typeof data?.ultimaAtualizacao !== 'undefined') {
              setLastAccess(
                `${data?.ultimaAtualizacao
                  ?.toDate()
                  .toLocaleDateString('pt-BR')} às ${data?.ultimaAtualizacao
                  ?.toDate()
                  .toLocaleTimeString('pt-BR')}`
              )
            }
            setLoadingLastAccess(false)
          })
      } catch (error) {
        console.log(error)
        setLoadingLastAccess(false)
      }
    })
    //eslint-disable-next-line
  }, [])

  return loadingUsuario ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress style={{ color: '#2167AD' }} size={50} />
    </div>
  ) : (
    <PageWrapperPainel>
      <TopoSessao titulo={usuario.empresa}>
        <Link to={`/editar-empresa/${usuario.id}`}>
          <Button
            leftIcon={<BiEdit />}
            colorScheme='blueGray'
            variant='outline'
          >
            Editar Empresa
          </Button>
        </Link>
      </TopoSessao>

      <VStack spacing='4' padding='6'>
        <Box w='100%' bg='white' borderRadius='md' padding='4'>
          <Heading fontSize='xl'>Informações do Usuário</Heading>
          <Divider marginY='3' />
          <SimpleGrid minChildWidth='16rem' spacing='4'>
            <Info label='Categoria' value={usuario.categoria.titulo} />
            <Info label='Responsável' value={usuario.nome} />
            <Info label='Usuário' value={usuario.usuario} />
            <Info label='Email' value={usuario.email} />
            <Info label='Contato' value={usuario.contato} />
            <Info
              label='Situação'
              value={usuario.situacao === '1' ? 'Ativo' : 'Inativo'}
            />
            <Info
              label='Último Acesso'
              value={lastAccess}
              loading={loadingLastAccess}
            />
          </SimpleGrid>
        </Box>

        <SpotsCategories userId={usuario.id} />

        <CustomPlaylists userId={usuario.id} />
      </VStack>
    </PageWrapperPainel>
  )
}

export default EmpresaScreen
