import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../store/actions/usuario'

import logo from '../imagens/logo.png'
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link as ChakraLink,
  Text,
  VStack,
  IconButton,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import {
  FaEye,
  FaEyeSlash,
  FaLock,
  FaUserAlt,
  FaWhatsapp,
} from 'react-icons/fa'
import PageWrapperPainel from '../components/PageWrapperPainel'
import { analytics } from '../config/firebase'

const LoginScreen = (props) => {
  const [usuario, setUsuario] = useState('')
  const [senha, setSenha] = useState('')
  const [verSenha, setVerSenha] = useState(false)

  const usuarioStore = useSelector((state) => state.usuario)
  const { loading, usuarioInfo, error } = usuarioStore

  useEffect(() => {
    if (usuarioInfo) {
      props.history.push('/')
    }
    //eslint-disable-next-line
  }, [usuarioInfo])

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(usuario, senha))
  }

  return (
    <>
      <Box
        backgroundImage="url('../imagens/bg-login.png')"
        backgroundSize='cover'
        overflow='hidden'
      >
        <PageWrapperPainel>
          <Center w='100vw' minH='100vh'>
            <VStack>
              <Box>
                <Container
                  style={{ backdropFilter: 'blur(10px)' }}
                  w='calc(100vw - 2rem)'
                  maxW='sm'
                  backgroundColor='blackAlpha.500'
                  borderRadius='lg'
                  paddingX='10'
                  paddingY='6'
                >
                  <Center>
                    <ChakraLink to='/' as={Link}>
                      <Box cursor='pointer'>
                        <Image
                          src={logo}
                          boxSize='48'
                          objectFit='contain'
                          alt='ComunicaEdu'
                          maxW='100%'
                        />
                      </Box>
                    </ChakraLink>
                  </Center>
                  <form onSubmit={submitHandler}>
                    <FormControl id='username' marginTop='2'>
                      <FormLabel fontSize='sm' color='gray.300'>
                        Usuário
                      </FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                          children={
                            <Box color='gray.400'>
                              <FaUserAlt />
                            </Box>
                          }
                        />
                        <Input
                          bg='white'
                          type='text'
                          value={usuario}
                          onChange={(e) => {
                            setUsuario(e.target.value)
                          }}
                          required
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl id='password' marginTop='2'>
                      <FormLabel fontSize='sm' color='gray.300'>
                        Senha
                      </FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                          children={
                            <Box color='gray.400'>
                              <FaLock />
                            </Box>
                          }
                        />
                        <Input
                          bg='white'
                          type={verSenha ? 'text' : 'password'}
                          value={senha}
                          onChange={(e) => {
                            setSenha(e.target.value)
                          }}
                          required
                        />
                        <InputRightElement width='3rem'>
                          <IconButton
                            color='gray.600'
                            aria-label='Exibir senha'
                            icon={verSenha ? <FaEyeSlash /> : <FaEye />}
                            size='sm'
                            h='1.75rem'
                            onClick={() => {
                              setVerSenha(!verSenha)
                            }}
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    <Center marginTop='8' marginBottom='6'>
                      <Button
                        isLoading={loading}
                        minW='40%'
                        colorScheme='accent'
                        color='white'
                        type='submit'
                      >
                        Entrar
                      </Button>
                    </Center>
                  </form>
                  {error && (
                    <Text color='white' textAlign='center'>
                      Usuário ou senha inválidos <br /> {error}
                    </Text>
                  )}
                </Container>
              </Box>
              <Box textAlign='center' w='calc(100vw - 2rem)' maxW='sm'>
                <Text color='white' marginY='2' fontSize='sm'>
                  Ainda não é usuário e quer experimentar?
                </Text>
                <a
                  href='https://api.whatsapp.com/send?phone=5591984255650'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Button
                    size='sm'
                    colorScheme='blackAlpha'
                    borderColor='whiteAlpha.400'
                    borderWidth='1px'
                    leftIcon={<FaWhatsapp />}
                    _hover={{
                      color: 'whatsapp.300',
                    }}
                    onClick={() => {
                      analytics.logEvent('botao_contato')
                    }}
                  >
                    Entre em contato
                  </Button>
                </a>
              </Box>
            </VStack>
          </Center>
        </PageWrapperPainel>
      </Box>
    </>
  )
}

export default LoginScreen
