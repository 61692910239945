import { IconButton } from '@chakra-ui/button'
import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/layout'
import { ModalBody } from '@chakra-ui/modal'
import { ModalContent } from '@chakra-ui/modal'
import { ModalHeader } from '@chakra-ui/modal'
import { ModalOverlay } from '@chakra-ui/modal'
import { ModalFooter } from '@chakra-ui/modal'
import { Modal } from '@chakra-ui/modal'
import { Divider } from '@material-ui/core'
import { BiShow } from 'react-icons/bi'

const ShowRequestModal = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <IconButton
        aria-label='Editar'
        _hover={{ color: 'white', bg: 'blue.500' }}
        icon={<BiShow />}
        onClick={onOpen}
      />

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Solicitação de {data?.type}</ModalHeader>
          <ModalBody>
            <Box paddingY='2'>
              <Text fontSize='sm'>Status:</Text>
              <Text>
                {data?.status === 'pending'
                  ? 'Pendente'
                  : data?.status === 'closed'
                  ? 'Atendida'
                  : 'Pendente'}
              </Text>
            </Box>
            <Divider />
            <Box paddingY='2'>
              <Text fontSize='sm'>Descrição:</Text>
              <Text>{data?.description}</Text>
            </Box>
            <Divider />
          </ModalBody>
          <ModalFooter>
            <Button mr='3' onClick={onClose} type='reset'>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ShowRequestModal
