import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  VStack,
  ModalFooter,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BiAlarm } from 'react-icons/bi'
import { analytics } from '../../config/firebase'
import { usePlayer } from '../../hooks/usePlayer'
import NumberInput from '../NumberInput'

const MenuRelogio = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data, updatePlayer } = usePlayer()

  const [intervaloRelogio, setIntervaloRelogio] = useState(
    data.intervaloRelogio || 0
  )
  const [trocandoIntervalo, setTrocandoIntervalo] = useState(false)

  useEffect(() => {
    setIntervaloRelogio(data.intervaloRelogio || 0)
  }, [data.intervaloRelogio])

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<BiAlarm />}
          onClick={() => {
            analytics.logEvent('abriu_menu_relogio')
          }}
          colorScheme='blackAlpha'
          size='sm'
          height={['8', null, null, '6', '8']}
          fontSize='xs'
          borderRadius='full'
        >
          Relógio
        </MenuButton>
        <MenuList bg='blueGray.900' borderColor='blueGray.900'>
          <MenuItem
            _hover={{ bg: 'whiteAlpha.100' }}
            _active={{ bg: 'whiteAlpha.100' }}
            _focus={{ bg: 'whiteAlpha.100' }}
            borderBottomWidth='1px'
            borderBottomColor='whiteAlpha.100'
            onClick={() => {
              analytics.logEvent('botao_reproduzir_relogio_imediatamente')
              updatePlayer({
                playRelogio: 'imediatamente',
              })
            }}
          >
            Reproduzir imediatamente
          </MenuItem>
          <MenuItem
            _hover={{ bg: 'whiteAlpha.100' }}
            _active={{ bg: 'whiteAlpha.100' }}
            _focus={{ bg: 'whiteAlpha.100' }}
            borderBottomWidth='1px'
            borderBottomColor='whiteAlpha.100'
            onClick={() => {
              analytics.logEvent('botao_reproduzir_relogio_a_seguir')
              updatePlayer({
                playRelogio: 'proximo',
              })
            }}
          >
            Reproduzir a seguir
          </MenuItem>
          <MenuItem
            _hover={{ bg: 'whiteAlpha.100' }}
            _active={{ bg: 'whiteAlpha.100' }}
            _focus={{ bg: 'whiteAlpha.100' }}
            onClick={() => {
              analytics.logEvent('botao_config_relogio')
              onOpen()
            }}
          >
            Definir intervalo de reprodução
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configurar Relógio</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              analytics.logEvent('fechou_config_relogio_botao')
            }}
          />
          <Text textAlign='center' margin='2'>
            A cada quantas músicas a hora certa deve tocar?
          </Text>
          <ModalBody>
            <VStack>
              <NumberInput
                value={intervaloRelogio}
                onChange={(e) => setIntervaloRelogio(e.target.value)}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              isLoading={trocandoIntervalo}
              colorScheme='accent'
              onClick={() => {
                setTrocandoIntervalo(true)
                updatePlayer({ intervaloRelogio })
                  .then(() => {
                    onClose()
                    setTrocandoIntervalo(false)
                  })
                  .catch(() => {
                    setTrocandoIntervalo(false)
                  })
              }}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MenuRelogio
