import { FormLabel } from '@chakra-ui/form-control'
import { FormErrorMessage } from '@chakra-ui/form-control'
import { FormControl } from '@chakra-ui/form-control'
import { Textarea } from '@chakra-ui/textarea'
import { useField } from 'formik'

const CustomTextarea = ({ label, name, ...rest }) => {
  const [field, meta] = useField({ name, ...rest })
  return (
    <FormControl marginTop='2' isInvalid={meta.error && meta.touched}>
      <FormLabel fontSize='sm' color='gray.600' htmlFor={name}>
        {label}
      </FormLabel>
      <Textarea id={name} name={name} {...field} {...rest} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

export default CustomTextarea
