import { Button, IconButton } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { Image } from '@chakra-ui/image'
import { Box, HStack, VStack } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  // DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/modal'
import { useRef } from 'react'
import {
  BiAlbum,
  BiCog,
  BiCommentDetail,
  BiHome,
  BiMenu,
  BiMusic,
  BiStation,
  BiStore,
  BiUser,
} from 'react-icons/bi'
import { FaChevronDown } from 'react-icons/fa'
import Cookie from 'js-cookie'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/actions/usuario'
import LinkMenuLateral from './LinkMenuLateral'

const MenuDrawer = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  return (
    <>
      <IconButton
        ref={btnRef}
        colorScheme='blackAlpha'
        onClick={onOpen}
        aria-label='Abrir menu'
        icon={<BiMenu />}
        fontSize='3xl'
        display={['inline-flex', null, 'none']}
        marginY='3'
      />
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent bg='blueGray.800' color='white'>
            <DrawerCloseButton />
            <DrawerHeader>
              <HStack>
                <Box>
                  {props.imagemEmpresa ? (
                    <Image
                      src={props.imagemEmpresa ? props.imagemEmpresa : null}
                      boxSize='24'
                      objectFit='contain'
                      alt={props.nomeEmpresa}
                      maxW='100%'
                    />
                  ) : (
                    <Box
                      color='gray.200'
                      fontSize='3xl'
                      borderRadius='full'
                      borderWidth='2px'
                      borderColor='gray.200'
                      marginY='4'
                      padding='1'
                    >
                      <BiUser />
                    </Box>
                  )}
                </Box>
                <Menu>
                  <MenuButton
                    variant='ghost'
                    colorScheme='whiteAlpha'
                    color='white'
                    as={Button}
                    rightIcon={<FaChevronDown />}
                  >
                    {props.nomeEmpresa}
                  </MenuButton>
                  <MenuList color='black'>
                    <MenuItem
                      onClick={() => {
                        Cookie.remove('usuarioInfo')
                        dispatch(logout())
                        history.push('/')
                      }}
                    >
                      Sair
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </DrawerHeader>

            <DrawerBody>
              <VStack
                alignItems='stretch'
                spacing='2'
                w='100%'
                onClick={onClose}
              >
                <LinkMenuLateral to='/' icon={<BiHome />}>
                  Inicio
                </LinkMenuLateral>
                {usuarioInfo?.nivel === '0' && (
                  <LinkMenuLateral to='/empresas' icon={<BiStore />}>
                    Empresas
                  </LinkMenuLateral>
                )}
                <LinkMenuLateral
                  to='/vinheta'
                  label='Vinhetas'
                  icon={<BiStation />}
                >
                  Vinhetas
                </LinkMenuLateral>
                {usuarioInfo?.nivel === '0' && (
                  <LinkMenuLateral to='/generos' icon={<BiAlbum />}>
                    Gêneros
                  </LinkMenuLateral>
                )}
                {usuarioInfo?.nivel === '0' && (
                  <LinkMenuLateral to='/adicionar-musica' icon={<BiMusic />}>
                    Cadastrar Músicas
                  </LinkMenuLateral>
                )}
                <LinkMenuLateral to='/solicitacoes' icon={<BiCommentDetail />}>
                  Solicitações
                </LinkMenuLateral>
                <LinkMenuLateral to='/configuracoes' icon={<BiCog />}>
                  Configurações
                </LinkMenuLateral>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

export default MenuDrawer
