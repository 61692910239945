const generatePlaylist = (
  songsPlaylist,
  spotsPlaylist,
  spotsInterval,
  clockInterval
) => {
  const songsPlaylistFinal = songsPlaylist
    .sort(() => Math.random() - 0.5)
    .slice(0, 500)

  let finalPlaylist = []

  let indexSpots = 0

  let finalSpotsInterval = 1

  let relogioObjeto = {
    title: 'Hora Certa',
    type: 'clock',
    pathname: 'relogio',
    genre: { title: 'Relógio' },
  }

  if (spotsInterval) {
    finalSpotsInterval = spotsInterval
  }

  if (spotsPlaylist.length > 0 || clockInterval) {
    songsPlaylistFinal.forEach((song, index) => {
      finalPlaylist.push(song)
      if (spotsPlaylist.length > 0 && index % finalSpotsInterval === 0) {
        finalPlaylist.push(spotsPlaylist[indexSpots])
        if (indexSpots < spotsPlaylist.length - 1) {
          indexSpots++
        } else {
          indexSpots = 0
        }
      }
      if (clockInterval && index % clockInterval === 0) {
        finalPlaylist.push(relogioObjeto)
      }
    })
  } else {
    finalPlaylist = songsPlaylistFinal
  }

  return finalPlaylist.slice(0, 500)
}

export default generatePlaylist
