import iconeEditar from '../imagens/editar.png'
import iconeExcluir from '../imagens/excluir.png'

import iconeMusica from '../imagens/musica.png'
import { Link } from 'react-router-dom'

const GeneroItem = (props) => {
  return (
    <tr>
      <td>
        <img className='icone-lista' src={iconeMusica} alt='' />
        <Link to={'musicas/' + props.id}>{props.titulo}</Link>
      </td>
      <td>{props.quantidade}</td>
      <td className='acoes-painel'>
        <Link to={`/editar-genero/${props.id}`}>
          <img src={iconeEditar} alt='Editar' />
        </Link>
        <div className='botao-excluir' onClick={props.onExcluir}>
          <img src={iconeExcluir} alt='Excluir' />
        </div>
      </td>
    </tr>
  )
}

export default GeneroItem
