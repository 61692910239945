import generateGenrePlaylist from './generateGenrePlaylist'

const generateSongsPlaylist = (genre, randomGenres, allGenresData) => {
  if (typeof genre === 'undefined') {
    return
  }

  let songsPlaylist = []

  if (genre === '0') {
    if (randomGenres?.length > 0) {
      randomGenres.forEach((gen) => {
        songsPlaylist.push(...generateGenrePlaylist(gen, allGenresData))
      })
    } else {
      allGenresData.forEach((genreData) => {
        songsPlaylist.push(
          ...generateGenrePlaylist(genreData.id, allGenresData)
        )
      })
    }
  } else {
    songsPlaylist.push(...generateGenrePlaylist(genre, allGenresData))
  }

  return songsPlaylist
    .sort(() => Math.random() - 0.5)
    .slice(0, 300)
    .map((song) => ({
      genre: song.genre,
      title: song.title,
      type: 'song',
      isNew: song.isNew ? true : false,
      pathname: song.pathname
        ? song.pathname
        : `https://comunicaedu.com/musicas/${song.genre?.id}/${song.id}.mp3`,
    }))
}

export default generateSongsPlaylist
