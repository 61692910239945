import { useState, useEffect } from 'react'

import { CircularProgress } from '@material-ui/core'
import TopoSessao from '../components/TopoSessao'
import styles from './ConfigScreen.module.css'
import { usePlayer } from '../hooks/usePlayer'
import PageWrapperPainel from '../components/PageWrapperPainel'
import { Button } from '@chakra-ui/button'
import { useDatabase } from '../hooks/useDatabase'
import { HStack, Text } from '@chakra-ui/layout'

const ConfigScreen = () => {
  const { genres, loading: genLoading, error: genError } = useDatabase()

  const { data: playerData, loading, updatePlayer } = usePlayer()

  const [generosSelecionados, setGenerosSelecionados] = useState([])

  useEffect(() => {
    if (playerData.generosPlaylistAleatoria) {
      setGenerosSelecionados(playerData.generosPlaylistAleatoria)
    }
  }, [playerData])

  const [salvando, setSalvando] = useState(false)
  const [salvou, setSalvou] = useState(false)

  const handleGeneroClick = (id) => {
    let generosSelecionadosNovo = []
    if (generosSelecionados.includes(id)) {
      generosSelecionadosNovo = generosSelecionados.filter(
        (genero) => genero !== id
      )
      setGenerosSelecionados([...generosSelecionadosNovo])
    } else {
      generosSelecionadosNovo = [...generosSelecionados]
      generosSelecionadosNovo.push(id)
      setGenerosSelecionados([...generosSelecionadosNovo])
    }
  }

  const handleSalvar = async () => {
    setSalvou(false)
    setSalvando(true)
    updatePlayer({ generosPlaylistAleatoria: generosSelecionados }).then(() => {
      setSalvando(false)
      setSalvou(true)
    })
  }

  return (
    <PageWrapperPainel>
      <TopoSessao titulo='Configurações'>
        <HStack justifyContent='flex-end' alignItems='center'>
          <Text
            fontSize='sm'
            bg='gray.500'
            color='white'
            borderRadius='full'
            paddingY='0.5'
            paddingX='2'
          >
            Versão do App: 1.3.0
          </Text>
        </HStack>
      </TopoSessao>
      {salvou && (
        <div className={styles.mensagemSucesso}>
          <p>Alterações salvas com sucesso!</p>
        </div>
      )}
      {salvando && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '360px',
              padding: '3em',
              backgroundColor: '#ffffff',
              borderRadius: '0.2em',
            }}
          >
            <CircularProgress style={{ color: '#2167AD' }} size={30} />
          </div>
        </div>
      )}
      <div className={styles.sessao}>
        {genLoading || loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ color: '#2167AD' }} size={30} />
          </div>
        ) : genError ? (
          <p>genError</p>
        ) : (
          <>
            <h1>Playlist Aleatória</h1>
            <p>Escolha quais gêneros você quer ouvir na playlist aleatória:</p>
            <div className={styles.generos}>
              {genres.map((genero) => (
                <div
                  className={
                    generosSelecionados.includes(genero.id)
                      ? styles.genero + ' ' + styles.generoSelecionado
                      : styles.genero
                  }
                  onClick={() => handleGeneroClick(genero.id)}
                >
                  <h2>{genero.title}</h2>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.divBotaoSalvar}>
        <p style={{ margin: 0, fontSize: '11pt' }}>
          Atenção: As alterações serão aplicadas imediatamente após clicar em
          salvar!
        </p>
      </div>
      <div className={styles.divBotaoSalvar}>
        <Button
          colorScheme='accent'
          disabled={salvando}
          className={styles.botaoSalvar}
          onClick={handleSalvar}
        >
          Salvar configurações
        </Button>
      </div>
    </PageWrapperPainel>
  )
}

export default ConfigScreen
