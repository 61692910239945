import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'

import { fetchUsuarios } from './store/actions/usuarios'
import { fetchVinhetas } from './store/actions/vinhetas'
import { fetchCategoriasEmpresas } from './store/actions/categoriasEmpresas'
import { fetchSolicitacoes } from './store/actions/solicitacoes'

import TopoSite from './components/TopoSite'
import MenuLateral from './components/MenuLateral'

import HomeScreen from './screens/HomeScreen'
import EmpresasScreen from './screens/EmpresasScreen'
import EmpresaScreen from './screens/EmpresaScreen/EmpresaScreen.jsx'
import VinhetasScreen from './screens/VinhetasScreen'
import MusicasScreen from './screens/MusicasScreen'
import LoginScreen from './screens/LoginScreen'
import GenerosScreen from './screens/GenerosScreen'
import CategoriasEmpresasScreen from './screens/CategoriasEmpresasScreen'
import EditEmpresaScreen from './screens/EditEmpresaScreen'
import AddMusicasScreen from './screens/AddMusicasScreen'
import EditMusicaScreen from './screens/EditMusicaScreen'
import EditVinhetaScreen from './screens/EditVinhetaScreen'
import EditCategoriaEmpresaScreen from './screens/EditCategoriaEmpresaScreen'
import EditCategoriaVinhetaScreen from './screens/EditCategoriaVinhetaScreen'
import EditGeneroScreen from './screens/EditGeneroScreen'
import EditSolicitacaoVinhetaScreen from './screens/EditSolicitacaoVinhetaScreen'
import SolicitacaoScreen from './screens/SolicitacaoScreen'
import Player from './components/player/Player'
import { fetchCategoriasVinhetas } from './store/actions/categoriasVinhetas'
import ConfigScreen from './screens/ConfigScreen'
import { AnimatePresence } from 'framer-motion'
import { analytics, auth } from './config/firebase'
import { Box, Flex } from '@chakra-ui/layout'
import AddSongsToPlaylistScreen from './screens/AddSongsToPlaylistScreen'
import SolicitatcoesScreen from './screens/SolicitacoesScreen/SolicitacoesScreen'

function App() {
  const dispatch = useDispatch()
  const location = useLocation()

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  useEffect(() => {
    if (usuarioInfo) {
      dispatch(fetchUsuarios(usuarioInfo.token))
      dispatch(fetchVinhetas(usuarioInfo.token))
      dispatch(fetchCategoriasEmpresas(usuarioInfo.token))
      dispatch(fetchSolicitacoes(usuarioInfo.token))
      dispatch(fetchCategoriasVinhetas(usuarioInfo.token))
      if (auth.currentUser) {
        auth.signInAnonymously()
        analytics.setUserId(usuarioInfo.id)
        analytics.logEvent('login', { userId: usuarioInfo.id })
      }
    }
    //eslint-disable-next-line
  }, [usuarioInfo])

  return (
    <>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route path='/login' exact={true} component={LoginScreen} />
          {usuarioInfo ? (
            <Route path='/'>
              <Flex w='100%' maxW='100%'>
                <MenuLateral />
                <Box bg='gray.100' flexGrow='1' paddingBottom='20'>
                  <TopoSite
                    nomeEmpresa={usuarioInfo.empresa}
                    idEmpresa={usuarioInfo.id}
                  />
                  <Route path='/' exact={true} component={HomeScreen} />
                  <Route
                    path='/empresa/:id'
                    exact={true}
                    component={EmpresaScreen}
                  />
                  <Route
                    path='/solicitacao/:id'
                    exact={true}
                    component={SolicitacaoScreen}
                  />
                  <Route
                    path='/vinheta'
                    exact={true}
                    component={VinhetasScreen}
                  />
                  <Route
                    path='/vinheta/:categoria'
                    exact={true}
                    component={VinhetasScreen}
                  />
                  <Route
                    path='/musicas'
                    exact={true}
                    component={MusicasScreen}
                  />
                  <Route path='/musicas/:genero' component={MusicasScreen} />
                  <Route
                    path='/playlist-exclusiva/:customPlaylistId'
                    exact={true}
                    component={MusicasScreen}
                  />
                  <Route
                    path='/solicitacoes'
                    exact={true}
                    component={SolicitatcoesScreen}
                  />
                  <Route
                    path='/editar-solicitacao'
                    exact={true}
                    component={EditSolicitacaoVinhetaScreen}
                  />
                  <Route
                    path='/editar-solicitacao/:id'
                    exact={true}
                    component={EditSolicitacaoVinhetaScreen}
                  />
                  {usuarioInfo?.nivel === '0' && (
                    <>
                      <Route
                        path='/empresas'
                        exact={true}
                        component={EmpresasScreen}
                      />
                      <Route
                        path='/vinheta/:categoria/adicionar-vinheta/'
                        component={EditVinhetaScreen}
                      />
                      <Route
                        path='/editar-vinheta/:id'
                        component={EditVinhetaScreen}
                      />
                      <Route
                        path='/editar-categoria-vinhetas/id/:id'
                        exact={true}
                        component={EditCategoriaVinhetaScreen}
                      />
                      <Route
                        path='/editar-categoria-vinhetas/empresa/:empresa'
                        exact={true}
                        component={EditCategoriaVinhetaScreen}
                      />
                      <Route
                        path='/generos'
                        exact={true}
                        component={GenerosScreen}
                      />
                      <Route
                        path='/editar-genero'
                        exact={true}
                        component={EditGeneroScreen}
                      />
                      <Route
                        path='/editar-genero/:id'
                        exact={true}
                        component={EditGeneroScreen}
                      />
                      <Route
                        path='/categorias-empresas'
                        exact={true}
                        component={CategoriasEmpresasScreen}
                      />
                      <Route
                        path='/editar-categoria-empresas'
                        exact={true}
                        component={EditCategoriaEmpresaScreen}
                      />
                      <Route
                        path='/editar-categoria-empresas/:id'
                        exact={true}
                        component={EditCategoriaEmpresaScreen}
                      />
                      <Route
                        path='/editar-empresa'
                        exact={true}
                        component={EditEmpresaScreen}
                      />
                      <Route
                        path='/editar-empresa/:id'
                        exact={true}
                        component={EditEmpresaScreen}
                      />
                      <Route
                        path='/adicionar-musica'
                        exact={true}
                        component={AddMusicasScreen}
                      />
                      <Route
                        path='/playlist-exclusiva/:customPlaylistId/adicionar-musicas'
                        component={AddSongsToPlaylistScreen}
                      />
                      <Route
                        path='/editar-musica/:id'
                        exact={true}
                        component={EditMusicaScreen}
                      />
                    </>
                  )}
                  <Route
                    path='/configuracoes'
                    exact={true}
                    component={ConfigScreen}
                  />
                </Box>
              </Flex>
            </Route>
          ) : (
            <Route path='/' component={LoginScreen} />
          )}
        </Switch>
      </AnimatePresence>
      {usuarioInfo && <Player />}
    </>
  )
}

export default App
