import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const ModalConfirmacaoForm = (props) => {
  return (
    <Backdrop
      style={{ zIndex: '999' }}
      open={props.open}
      onClick={props.onClick}
    >
      {props.loading && <CircularProgress color='inherit' />}
      <Dialog
        style={{ zIndex: '9999' }}
        open={props.open && props.success}
        onClose={props.onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{props.message}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            O que deseja fazer a seguir?
          </DialogContentText>
        </DialogContent>
        <DialogActions>{props.children}</DialogActions>
      </Dialog>
    </Backdrop>
  )
}

export default ModalConfirmacaoForm
