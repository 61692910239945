import {
  SOLICITACOES_REQUEST,
  SOLICITACOES_SUCCESS,
  SOLICITACOES_FAIL,
} from '../actions/solicitacoes'

const initialState = {
  solicitacoes: '',
}

const solicitacoesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOLICITACOES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SOLICITACOES_SUCCESS:
      return {
        ...state,
        loading: false,
        solicitacoes: action.payload,
      }
    case SOLICITACOES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default solicitacoesReducer
