import generateGenrePlaylist from './generateGenrePlaylist'

const generateSongsCustomPlaylist = (genre, allGenresData) => {
  if (typeof genre === 'undefined') {
    return
  }

  let songsPlaylist = []

  songsPlaylist.push(...generateGenrePlaylist(genre, allGenresData))

  return songsPlaylist
    .sort(() => Math.random() - 0.5)
    .map((song) => ({
      genre: song.genre,
      title: song.title,
      type: 'song',
      isNew: song.isNew ? true : false,
      pathname: song.pathname
        ? song.pathname
        : `https://comunicaedu.com/musicas/${song.genre?.id}/${song.id}.mp3`,
    }))
}

export default generateSongsCustomPlaylist
