import {
  BiVolume,
  BiVolumeFull,
  BiVolumeLow,
  BiVolumeMute,
} from 'react-icons/bi'
import {
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  HStack,
} from '@chakra-ui/react'

const Volume = (props) => {
  let IconeVolume = BiVolumeFull
  if (Number(props.value) === 0) {
    IconeVolume = BiVolumeMute
  } else if (props.value < 25) {
    IconeVolume = BiVolume
  } else if (props.value < 70) {
    IconeVolume = BiVolumeLow
  } else {
    IconeVolume = BiVolumeFull
  }

  return (
    <HStack>
      <IconButton
        colorScheme='white'
        variant='ghost'
        size='sm'
        fontSize='2xl'
        onClick={props.onToggleMute}
        aria-label='Botão de Volume'
        icon={<IconeVolume />}
      />
      <Slider
        name='volume'
        aria-label='Volume'
        min={0}
        max={100}
        value={props.value}
        onChange={props.onChange}
        focusThumbOnChange={false}
        onChangeEnd={props.onChangeEnd}
        width='32'
      >
        <SliderTrack bg='gray.600'>
          <SliderFilledTrack bg='gray.300' />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </HStack>
  )
}

export default Volume
