import { Button } from '@chakra-ui/button'
import { Divider, HStack, Text, VStack } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import NumberInput from './NumberInput'
import { Link } from 'react-router-dom'
import { BiEdit, BiTrash } from 'react-icons/bi'

const VinhetaOptions = ({
  onClose,
  isOpen,
  onChangeEstado,
  estado,
  setIntervaloVinheta,
  intervaloVinheta,
  changeFrequenciaVinheta,
  isAdmin,
  id,
  onExcluir,
  titulo,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Configurar "{titulo.trim()}"</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack margin='2' marginBottom='6'>
            <Divider />
            <Text>Estado da vinheta:</Text>
            <Button
              onClick={() => onChangeEstado()}
              colorScheme={estado === '1' ? 'green' : 'red'}
              _hover={
                estado === '1'
                  ? {
                      bg: 'red.100',
                      color: 'red.700',
                    }
                  : {
                      bg: 'green.100',
                      color: 'green.700',
                    }
              }
            >
              {estado === '1' ? 'Ativo' : 'Desativ.'}
            </Button>
            <Divider />
            <Text>Frequência da vinheta:</Text>
            <NumberInput
              onChange={(e) => {
                setIntervaloVinheta(e.target.value)
                changeFrequenciaVinheta(e.target.value)
              }}
              value={intervaloVinheta}
              readOnly
              width='16'
              size='md'
              fontSize='md'
            />
            <Divider />
            {isAdmin && (
              <>
                <Text>Opções de administrador:</Text>
                <HStack>
                  <Link to={`/editar-vinheta/${id}`}>
                    <Button colorScheme='blue' leftIcon={<BiEdit />}>
                      Editar
                    </Button>
                  </Link>
                  <Button
                    colorScheme='red'
                    leftIcon={<BiTrash />}
                    onClick={onExcluir}
                  >
                    Excluir
                  </Button>
                </HStack>
              </>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default VinhetaOptions
