import { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useDatabase } from '../../hooks/useDatabase'

const MusicaUpload = (props) => {
  const [carregandoDuplicada, setCarregandoDuplicada] = useState(false)
  const [tituloGenero, setTituloGenero] = useState('')

  const { genres, loading: genLoading, error: genError } = useDatabase()

  const verificaDuplicada = (titulo, genero) => {
    const currentGenre = genres.find((gen) => gen.id === genero)
    if (props.playlist) {
      if (props.playlist.songs.some((s) => s.title === titulo)) {
        props.setDuplicada(true, props.id)
        return
      }
    } else if (currentGenre.songs.some((s) => s.title === titulo)) {
      props.setDuplicada(true, props.id)
      return
    }
    props.setDuplicada(false, props.id)
  }

  useEffect(() => {
    verificaDuplicada(props.titulo, props.genero)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let generoSelecionado = genres.find((gen) => gen.id === props.genero)
    if (generoSelecionado) setTituloGenero(generoSelecionado.title)
    //eslint-disable-next-line
  }, [props.genero])

  return (
    <li
      className='arquivo-upload'
      style={{
        borderLeftColor: carregandoDuplicada
          ? '#ceceee'
          : props.duplicada
          ? '#DD2244'
          : '#059327',
      }}
    >
      <div
        className='botao-excluir-arquivo'
        onClick={() => props.onExcluir(props.id)}
      >
        X
      </div>
      <img src='imagens/musica.png' alt='Ícone Música' />
      <div className='campos-formulario-multi'>
        {!(props.enviando || props.concluido) ? (
          <>
            <div className='campo-formulario-multi'>
              <TextField
                onChange={(e) => {
                  props.onTituloChange(e, props.id)
                  verificaDuplicada(e.target.value, props.genero)
                }}
                value={props.titulo}
                label='Titulo'
                variant='outlined'
                fullWidth
              />
            </div>
            {!props.hideGenre && (
              <div className='campo-formulario-multi'>
                <FormControl variant='outlined' style={{ width: '100%' }}>
                  <InputLabel id='genero-label'>Genero</InputLabel>
                  <Select
                    labelId='genero-label'
                    id='genero'
                    value={props.genero}
                    onChange={(e) => {
                      props.onGeneroChange(e, props.id)
                      verificaDuplicada(props.titulo, e.target.value)
                    }}
                    label='Genero'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {genLoading
                      ? 'Carregando...'
                      : genError
                      ? genError
                      : genres &&
                        genres.map((gen) => (
                          <MenuItem key={gen.id} value={gen.id}>
                            {gen.title}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        ) : (
          <div className='titulo-musica-formulario'>
            <p>{`${props.titulo} | ${tituloGenero}`}</p>
          </div>
        )}
        {!props.concluido && !props.erro ? (
          <LinearProgress
            style={{ marginTop: '1.5em', height: '0.5em' }}
            variant='determinate'
            value={!props.concluido ? props.progresso : 100}
          />
        ) : props.erro ? (
          <LinearProgress
            style={{
              marginTop: '1.5em',
              height: '0.5em',
              backgroundColor: '#DD2244',
            }}
            variant='determinate'
            value={0}
          />
        ) : (
          <LinearProgress
            style={{
              marginTop: '1.5em',
              height: '0.5em',
              backgroundColor: '#059327',
            }}
            variant='determinate'
            value={0}
          />
        )}
      </div>
    </li>
  )
}

export default MusicaUpload
