import Axios from 'axios';

export const MUSICAS_REQUEST = 'MUSICAS_REQUEST';
export const MUSICAS_SUCCESS = 'MUSICAS_SUCCESS';
export const MUSICAS_FAIL = 'MUSICAS_FAIL';

export const fetchMusicas = (token) => async (dispatch) => {

    try {

        dispatch({ type: MUSICAS_REQUEST });
        const { data } = await Axios.get(process.env.REACT_APP_API + '/musica/read.php?token=' + token, {
            headers: {
                'Authorization': ('bearer ' + token),
                withCredentials: true
            }
        });
        dispatch({ type: MUSICAS_SUCCESS, payload: data.data });

    } catch (error) {

        dispatch({ type: MUSICAS_FAIL, payload: error.message });

    }

};
