import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TopoSessao from '../components/TopoSessao'
import * as Yup from 'yup'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import MySelect from '../components/forms/MySelect'
import MenuItem from '@material-ui/core/MenuItem'
import Axios from 'axios'
import { fetchMusicas } from '../store/actions/musicas'
import PageWrapperPainel from '../components/PageWrapperPainel'

const EditCategoriaVinhetaScreen = (props) => {
  let history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const [musicaSuccess, setMusicaSuccess] = useState(false)

  const dispatch = useDispatch()

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const generosStore = useSelector((state) => state.generos)
  const { generos, genLoading, genError } = generosStore

  const musicasStore = useSelector((state) => state.musicas)
  const { musicas, loadingMusicas } = musicasStore

  let musicaSelected = {}

  if (props.match.params.id && !loadingMusicas && musicas.length > 1) {
    musicaSelected = musicas.find(
      (musica) => musica.id === props.match.params.id
    )
  }

  return (
    ((props.match.params.id && !loadingMusicas && musicas.length > 1) ||
      !props.match.params.id) && (
      <PageWrapperPainel>
        <TopoSessao titulo='Editar Música' />
        <div
          style={{
            width: 'calc(100% - 4rem)',
            maxWidth: '960px',
            margin: '2rem auto',
            padding: '1.5rem',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
          }}
        >
          <Formik
            initialValues={{
              titulo: musicaSelected.titulo ? musicaSelected.titulo : '',
              genero: musicaSelected.genero.id ? musicaSelected.genero.id : '',
            }}
            validationSchema={Yup.object({
              titulo: Yup.string()
                .max(255, 'Tamanho máximo: 255 caracteres')
                .required('Preenchimento Obrigatório!'),
              genero: Yup.number().required('Preenchimento Obrigatório!'),
            })}
            onSubmit={(data) => {
              if (props.match.params.id) {
                setModalOpen(true)
                Axios.post(
                  process.env.REACT_APP_API +
                    '/musica/update.php?token=' +
                    encodeURIComponent(usuarioInfo.token),
                  {
                    titulo: data.titulo,
                    genero: data.genero,
                    id: props.match.params.id,
                  }
                )
                  .then((dataRetorno) => {
                    setMusicaSuccess(true)
                    dispatch(fetchMusicas(usuarioInfo.token))
                  })
                  .catch(function (error) {
                    // handle error
                    console.log(error)
                  })
              }
            }}
          >
            {(formik) => (
              <Form>
                <Field
                  label='Título'
                  name='titulo'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.titulo && formik.errors.titulo
                      ? formik.errors.titulo
                      : ''
                  }
                  error={formik.touched.titulo && formik.errors.titulo}
                />

                <MySelect label='Gênero' name='genero' margin='normal'>
                  {genLoading
                    ? 'Carregando...'
                    : genError
                    ? genError
                    : generos &&
                      generos.map((gen) => (
                        <MenuItem key={gen.id} value={gen.id}>
                          {gen.titulo}
                        </MenuItem>
                      ))}
                </MySelect>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    style={{ margin: '1em 0' }}
                    color='primary'
                    size='large'
                  >
                    Atualizar Música
                  </Button>
                </div>
                <ModalConfirmacaoForm
                  open={modalOpen}
                  loading={loadingMusicas}
                  success={musicaSuccess}
                  onClick={() => {
                    formik.handleReset()
                    setModalOpen(false)
                  }}
                  onClose={() => {
                    formik.handleReset()
                    setModalOpen(false)
                  }}
                  message='Música atualizada com sucesso!'
                >
                  <Button
                    onClick={() => {
                      history.push('/')
                    }}
                    color='primary'
                  >
                    Voltar para o Início
                  </Button>
                </ModalConfirmacaoForm>
              </Form>
            )}
          </Formik>
        </div>
      </PageWrapperPainel>
    )
  )
}

export default EditCategoriaVinhetaScreen
