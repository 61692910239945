import { useState, useEffect } from 'react'
import Axios from 'axios'

import TopoSessao from '../components/TopoSessao'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import PageWrapperPainel from '../components/PageWrapperPainel'

const SolicitacaoScreen = (props) => {
  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const [solicitacao, setSolicitacao] = useState()
  const [loadingSolicitacao, setLoadingSolicitacao] = useState(true)

  useEffect(() => {
    Axios.get(
      process.env.REACT_APP_API +
        '/solicitacao_vinheta/read_single.php?id=' +
        props.match.params.id +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    ).then((data) => {
      setSolicitacao(data.data)
      setLoadingSolicitacao(false)
    })
    //eslint-disable-next-line
  }, [])

  return loadingSolicitacao ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress style={{ color: '#2167AD' }} size={50} />
    </div>
  ) : (
    <PageWrapperPainel>
      <TopoSessao titulo={'Solicitação de ' + solicitacao.usuario.empresa}>
        <Link to={`/editar-solicitacao/${solicitacao.id}`}>
          <div className='botao-topo-sessao'>Editar Solicitação</div>
        </Link>
      </TopoSessao>
      <div className='sessao'>
        <div className='informacoes-perfil'>
          <h2>Informações da Solicitacão</h2>
          <div className='informacao-perfil'>
            <h4>Cliente:</h4>
            <p>{solicitacao.usuario.empresa}</p>
          </div>
          <div className='informacao-perfil'>
            <h4>Contato:</h4>
            <p>{solicitacao.usuario.contato}</p>
          </div>
          <div className='informacao-perfil'>
            <h4>Tipo:</h4>
            <p>{solicitacao.tipo}</p>
          </div>
          <div className='informacao-perfil'>
            <h4>Texto:</h4>
            <p>{solicitacao.texto}</p>
          </div>
        </div>
      </div>
    </PageWrapperPainel>
  )
}

export default SolicitacaoScreen
