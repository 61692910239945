import { FormLabel } from '@chakra-ui/form-control'
import { FormErrorMessage } from '@chakra-ui/form-control'
import { FormControl } from '@chakra-ui/form-control'
import { Select } from '@chakra-ui/select'
import { useField } from 'formik'

const CustomSelect = ({ label, name, children, ...rest }) => {
  const [field, meta] = useField({ name, ...rest })
  return (
    <FormControl marginTop='2' isInvalid={meta.error && meta.touched}>
      <FormLabel fontSize='sm' color='gray.600' htmlFor={name}>
        {label}
      </FormLabel>
      <Select id={name} name={name} {...field} {...rest}>
        {children}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}

export default CustomSelect
