import Axios from 'axios'

export const SOLICITACOES_REQUEST = 'SOLICITACOES_REQUEST'
export const SOLICITACOES_SUCCESS = 'SOLICITACOES_SUCCESS'
export const SOLICITACOES_FAIL = 'SOLICITACOES_FAIL'

export const fetchSolicitacoes = (token) => async (dispatch, getState) => {
  const usuarioInfo = getState().usuario.usuarioInfo

  try {
    dispatch({ type: SOLICITACOES_REQUEST })
    let queryEmpresa =
      +usuarioInfo?.nivel !== 0 ? 'usuario=' + usuarioInfo.id : ''
    const { data } = await Axios.get(
      process.env.REACT_APP_API +
        '/solicitacao_vinheta/read.php?' +
        queryEmpresa +
        '&token=' +
        token,
      {
        headers: {
          Authorization: 'bearer ' + token,
          withCredentials: true,
        },
      }
    )
    dispatch({ type: SOLICITACOES_SUCCESS, payload: data.data })
  } catch (error) {
    dispatch({ type: SOLICITACOES_FAIL, payload: error.message })
  }
}
