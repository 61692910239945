import { Text } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/layout'
import { Skeleton } from '@chakra-ui/skeleton'

const Info = ({ label, value, loading }) => {
  return (
    <Box>
      <Text fontSize='sm' color='gray.500'>
        {label}
      </Text>
      {loading ? (
        <Box w='100%'>
          <Skeleton height='20px' />
        </Box>
      ) : (
        <Text color='gray.900' fontWeight='medium'>
          {value}
        </Text>
      )}
    </Box>
  )
}

export default Info
