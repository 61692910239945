import { useState } from 'react'

import CategoriaEmpresaItem from '../components/CategoriaEmpresaItem'
import TopoSessao from '../components/TopoSessao'
import { Link } from 'react-router-dom'
import { fetchCategoriasEmpresas } from '../store/actions/categoriasEmpresas'

import Button from '@material-ui/core/Button'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import { useDispatch, useSelector } from 'react-redux'
import Axios from 'axios'
import PageWrapperPainel from '../components/PageWrapperPainel'

const GenerosScreen = () => {
  const categoriasEmpresasStore = useSelector(
    (state) => state.categoriasEmpresas
  )
  const { categoriasEmpresas, loading, error } = categoriasEmpresasStore

  const dispatch = useDispatch()

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const [modalOpen, setModalOpen] = useState(false)
  const [categoriaExcluir, setCategoriaExcluir] = useState({})

  const openModal = (id, titulo) => {
    setModalOpen(true)
    setCategoriaExcluir({ id, titulo })
  }

  const excluirCategoria = (id) => {
    Axios.get(
      process.env.REACT_APP_API +
        '/categoria_empresa/delete.php?id=' +
        id +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    ).then((data) => {
      dispatch(fetchCategoriasEmpresas(usuarioInfo.token))
      setModalOpen(false)
    })
  }

  return (
    <PageWrapperPainel>
      <TopoSessao titulo='Categorias de Empresas'>
        <Link to='/editar-categoria-empresas' className='botao-topo-sessao'>
          Adicionar Categoria
        </Link>
      </TopoSessao>
      <div className='sessao'>
        <table className='lista-painel'>
          <thead>
            <tr>
              <th>Título</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <th>Carregando...</th>
              </tr>
            ) : error ? (
              <tr>
                <th>{error}</th>
              </tr>
            ) : (
              categoriasEmpresas &&
              categoriasEmpresas.map((categoria) => (
                <CategoriaEmpresaItem
                  onExcluir={() => {
                    openModal(categoria.id, categoria.titulo)
                  }}
                  key={categoria.id}
                  id={categoria.id}
                  titulo={categoria.titulo}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      <ModalConfirmacaoForm
        open={modalOpen}
        success={modalOpen}
        onClick={() => {
          setModalOpen(false)
        }}
        onClose={() => {
          setModalOpen(false)
        }}
        message={`Tem certeza que deseja excluir a categoria '${categoriaExcluir.titulo}'?`}
      >
        <Button
          onClick={() => {
            excluirCategoria(categoriaExcluir.id)
          }}
          color='primary'
        >
          Excluir
        </Button>
        <Button
          onClick={() => {
            setModalOpen(false)
          }}
          color='primary'
          autoFocus
        >
          Cancelar
        </Button>
      </ModalConfirmacaoForm>
    </PageWrapperPainel>
  )
}

export default GenerosScreen
