import { db } from '../../config/firebase'
import firebase from 'firebase/app'

const savePlayerChangesToFirebase = async (data, userId) => {
  try {
    await db
      .collection('players')
      .doc(userId)
      .update({
        ...data,
        ultimaAtualizacao: firebase.firestore.FieldValue.serverTimestamp(),
      })
    return true
  } catch (error) {
    if (error.code === 'not-found') {
      db.collection('players')
        .doc(userId)
        .set({
          ...data,
          ultimaAtualizacao: firebase.firestore.FieldValue.serverTimestamp(),
        })
    }
    console.log('Error updating document: ', error.message)
    return false
  }
}

export default savePlayerChangesToFirebase
