import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
})

const colors = {
  accent: {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
  },
  blueGray: {
    50: '#EEF0F7',
    100: '#CFD5E8',
    200: '#AFB9D9',
    300: '#909ECB',
    400: '#7183BC',
    500: '#5268AD',
    600: '#41538B',
    700: '#272f47',
    800: '#1a2137',
    900: '#101523',
  },
}

const theme = extendTheme({ colors, breakpoints })

export default theme
