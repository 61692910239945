import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { fetchVinhetas } from '../store/actions/vinhetas'
import TopoSessao from '../components/TopoSessao'
import ButtonUpload from '../components/forms/ButtonUpload'
import * as Yup from 'yup'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import DatePicker from '../components/forms/DatePicker'
import Axios from 'axios'
import moment from 'moment'
import PageWrapperPainel from '../components/PageWrapperPainel'

const EditVinhetaScreen = (props) => {
  let history = useHistory()

  const [audio, setAudio] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [vinhetaSuccess, setVinhetaSuccess] = useState(false)
  const [vinhetaSelected, setVinhetaSelected] = useState()
  const [categoriaVinheta, setCategoriaVinheta] = useState()

  const dispatch = useDispatch()

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const categoriaId = vinhetaSelected
    ? vinhetaSelected.categoria.id
    : props.match.params.categoria

  useEffect(() => {
    if (categoriaId) {
      Axios.get(
        process.env.REACT_APP_API +
          '/categoria_vinheta/read_single.php?id=' +
          categoriaId +
          '&token=' +
          encodeURIComponent(usuarioInfo.token),
        {
          headers: {
            Authorization: 'bearer ' + usuarioInfo.token,
            withCredentials: true,
          },
        }
      ).then((data) => {
        setCategoriaVinheta(data.data)
      })
    }
    //eslint-disable-next-line
  }, [categoriaId])

  useEffect(() => {
    if (props.match.params.id) {
      Axios.get(
        process.env.REACT_APP_API +
          '/vinheta/read_single.php?id=' +
          props.match.params.id +
          '&token=' +
          encodeURIComponent(usuarioInfo.token),
        {
          headers: {
            Authorization: 'bearer ' + usuarioInfo.token,
            withCredentials: true,
          },
        }
      ).then((data) => {
        setVinhetaSelected(data.data)
      })
    }
    //eslint-disable-next-line
  }, [])

  return (
    ((props.match.params.id && vinhetaSelected) || !props.match.params.id) && (
      <PageWrapperPainel>
        <TopoSessao
          titulo={
            props.match.params.id ? 'Editar Vinheta' : 'Adicionar Vinheta'
          }
        />
        <div
          style={{
            width: 'calc(100% - 4rem)',
            maxWidth: '960px',
            margin: '2rem auto',
            padding: '1.5rem',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
          }}
        >
          {categoriaVinheta && (
            <Formik
              initialValues={{
                titulo: vinhetaSelected ? vinhetaSelected.titulo : '',
                categoria: categoriaVinheta.titulo,
                frequencia: vinhetaSelected ? vinhetaSelected.frequencia : '',
                inicio: vinhetaSelected
                  ? moment(vinhetaSelected.inicio)
                  : moment(),
                fim: vinhetaSelected ? moment(vinhetaSelected.fim) : moment(),
              }}
              validationSchema={Yup.object({
                titulo: Yup.string()
                  .max(255, 'Tamanho máximo: 255 caracteres')
                  .required('Preenchimento Obrigatório!'),
                categoria: Yup.string().required('Preenchimento Obrigatório!'),
                frequencia: Yup.number().required('Preenchimento Obrigatório!'),
                inicio: Yup.date().required('Preenchimento Obrigatório!'),
                fim: Yup.date().required('Preenchimento Obrigatório!'),
              })}
              onSubmit={(data) => {
                const formulario = new FormData()

                formulario.append('titulo', data.titulo)
                formulario.append('categoria', categoriaId)
                formulario.append('frequencia', data.frequencia)
                formulario.append(
                  'inicio',
                  data.inicio.toISOString().split('T')[0]
                )
                formulario.append('fim', data.fim.toISOString().split('T')[0])

                if (props.match.params.id) {
                  formulario.append('id', props.match.params.id)

                  Axios.post(
                    process.env.REACT_APP_API +
                      '/vinheta/update.php?token=' +
                      encodeURIComponent(usuarioInfo.token),
                    formulario
                  )
                    .then((dataRetorno) => {
                      setVinhetaSuccess(true)
                      dispatch(fetchVinhetas(usuarioInfo.token))
                    })
                    .catch(function (error) {
                      // handle error
                      console.log(error)
                    })
                } else {
                  formulario.append('arquivo', audio)

                  Axios.post(
                    process.env.REACT_APP_API +
                      '/vinheta/create.php?token=' +
                      encodeURIComponent(usuarioInfo.token),
                    formulario
                  )
                    .then((dataRetorno) => {
                      setVinhetaSuccess(true)
                      dispatch(fetchVinhetas(usuarioInfo.token))
                    })
                    .catch(function (error) {
                      // handle error
                      console.log(error)
                    })
                }

                setModalOpen(true)
              }}
            >
              {(formik) => (
                <Form>
                  <Field
                    label='Título'
                    name='titulo'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    as={TextField}
                    helperText={
                      formik.touched.titulo && formik.errors.titulo
                        ? formik.errors.titulo
                        : ''
                    }
                    error={formik.touched.titulo && formik.errors.titulo}
                  />
                  <Field
                    label='Categoria'
                    name='categoria'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    onChange={() => {}}
                    as={TextField}
                    helperText={
                      formik.touched.categoria && formik.errors.categoria
                        ? formik.errors.categoria
                        : ''
                    }
                    error={formik.touched.categoria && formik.errors.categoria}
                  />
                  <Field
                    label='Reproduzir a cada quantas músicas?'
                    name='frequencia'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    as={TextField}
                    helperText={
                      formik.touched.frequencia && formik.errors.frequencia
                        ? formik.errors.frequencia
                        : ''
                    }
                    error={
                      formik.touched.frequencia && formik.errors.frequencia
                    }
                  />
                  <Field
                    label='Reproduzir a partir de:'
                    name='inicio'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    onChange={(value) => formik.setFieldValue('inicio', value)}
                    as={DatePicker}
                    helperText={
                      formik.touched.inicio && formik.errors.inicio
                        ? formik.errors.inicio
                        : ''
                    }
                    error={formik.touched.inicio && formik.errors.inicio}
                  />
                  <Field
                    label='Reproduzir até:'
                    name='fim'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    onChange={(value) => {
                      formik.setFieldValue('fim', value)
                    }}
                    as={DatePicker}
                    helperText={
                      formik.touched.fim && formik.errors.fim
                        ? formik.errors.fim
                        : ''
                    }
                    error={formik.touched.fim && formik.errors.fim}
                  />

                  {!props.match.params.id && (
                    <ButtonUpload
                      name='audio'
                      label='Escolher Áudio'
                      style={{ margin: '0.5em 0' }}
                      onChange={(e) => {
                        setAudio(e.target.files[0])
                      }}
                      accept='.mp3'
                    />
                  )}

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      type='submit'
                      variant='contained'
                      style={{ margin: '1em 0' }}
                      color='primary'
                      size='large'
                    >
                      {props.match.params.id
                        ? 'Atualizar Vinheta'
                        : 'Cadastrar Vinheta'}
                    </Button>
                  </div>

                  <ModalConfirmacaoForm
                    open={modalOpen}
                    success={vinhetaSuccess}
                    onClick={() => {
                      formik.handleReset()
                      setAudio(null)
                      setModalOpen(false)
                    }}
                    onClose={() => {
                      formik.handleReset()
                      setAudio(null)
                      setModalOpen(false)
                    }}
                    message={
                      props.match.params.id
                        ? 'Vinheta atualizada com sucesso!'
                        : 'Vinheta cadastrada com sucesso!'
                    }
                  >
                    <Button
                      onClick={() => {
                        if (props.match.params.id) {
                          history.push(
                            '/vinhetas/' + vinhetaSelected.categoria.id
                          )
                        } else {
                          history.push(
                            '/vinhetas/' + props.match.params.categoria
                          )
                        }
                      }}
                      color='primary'
                    >
                      Voltar para Vinhetas
                    </Button>
                    {!props.match.params.id && (
                      <Button
                        onClick={() => {
                          formik.handleReset()
                          setAudio(null)
                          setModalOpen(false)
                        }}
                        color='primary'
                        autoFocus
                      >
                        Cadastrar nova vinheta
                      </Button>
                    )}
                  </ModalConfirmacaoForm>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </PageWrapperPainel>
    )
  )
}

export default EditVinhetaScreen
