import { useState, useEffect } from 'react'
import { uniqueId } from 'lodash'
import filesize from 'filesize'
import DropZone from 'react-dropzone'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import TopoSessao from '../components/TopoSessao'
import MusicaUpload from '../components/forms/MusicaUpload'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import { useHistory } from 'react-router'
import PageWrapperPainel from '../components/PageWrapperPainel'
import { useDatabase } from '../hooks/useDatabase'
import { storage } from '../config/firebase'

const AddMusicasScreen = (props) => {
  let history = useHistory()

  const {
    addMusic,
    genres,
    loading: genLoading,
    error: genError,
  } = useDatabase()

  console.log(genres)

  const [musicasUpload, setMusicasUpload] = useState([])
  const [genero, setGenero] = useState('')
  const [uploadConcluido, setUploadConcluido] = useState(0)
  const [uploadEmAndamento, setUploadEmAndamento] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    console.log(uploadConcluido)
    console.log(musicasUpload.length)
    if (musicasUpload.length > 0 && uploadConcluido === musicasUpload.length)
      setModalOpen(true)
    //eslint-disable-next-line
  }, [uploadConcluido])

  const handleReset = () => {
    setMusicasUpload([])
    setUploadConcluido(0)
    setUploadEmAndamento(false)
  }

  const handleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name.split('.')[0],
      readableSize: filesize(file.size),
      progress: 0,
      uploaded: false,
      uploading: false,
      error: false,
      genero: genero,
      duplicada: false,
    }))
    setMusicasUpload((prevState) => {
      return [...prevState, ...uploadedFiles]
    })
  }

  const handleSubmit = () => {
    let generosPreenchidos = false
    let titulosPreenchidos = false

    musicasUpload.forEach((musica) => {
      if (musica.genero === '' || musica.genero === null) {
        generosPreenchidos = false
      } else {
        generosPreenchidos = true
      }
      if (musica.name === '' || musica.name === null) {
        titulosPreenchidos = false
      } else {
        titulosPreenchidos = true
      }
    })

    if (!generosPreenchidos) {
      alert('Preencha todos os campos de gênero!')
    } else if (!titulosPreenchidos) {
      alert('Preencha todos os campos de título!')
    } else {
      setUploadEmAndamento(true)
      musicasUpload.forEach(processUpload)
    }
  }

  const updateFile = (id, data) => {
    setMusicasUpload((musicasAnteriores) => [
      ...musicasAnteriores.map((file) => {
        return file.id === id ? { ...file, ...data } : { ...file }
      }),
    ])
  }

  const processUpload = (uploadedFile) => {
    updateFile(uploadedFile.id, {
      uploading: true,
      error: false,
      uploaded: false,
      progress: 0,
    })

    const fileExtension = uploadedFile.file.name.split('.').pop()

    const storageRef = storage.ref(
      `songs/${uploadedFile.genero}/${uploadedFile.name.substring(
        0,
        23
      )}-${Math.random().toString(36).substring(2, 9)}.${fileExtension}`
    )

    const uploadTask = storageRef.put(uploadedFile.file)

    uploadTask.on(
      'state_changed',
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        updateFile(uploadedFile.id, { progress })
      },
      function (error) {
        updateFile(uploadedFile.id, { error: error.code, uploading: false })
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          addMusic({
            title: uploadedFile.name,
            genreId: uploadedFile.genero,
            pathname: downloadURL,
            pathRef: storageRef.fullPath,
          })
            .then(() => {
              updateFile(uploadedFile.id, {
                uploaded: true,
                uploading: false,
                progress: 100,
              })
              setUploadConcluido((previousTotal) => previousTotal + 1)
            })
            .catch((error) => {
              updateFile(uploadedFile.id, { error: true, uploading: false })
              console.error(error)
            })
        })
      }
    )
  }

  return (
    <PageWrapperPainel>
      <div className='formulario'>
        <TopoSessao titulo='Adicionar Músicas' />

        <FormControl
          variant='outlined'
          style={{
            width: '100%',
            backgroundColor: '#ffffff',
            marginBottom: '1em',
          }}
        >
          <InputLabel id='genero-principal-label'>Genero</InputLabel>
          <Select
            labelId='genero-principal-label'
            id='genero-principal'
            disabled={uploadEmAndamento}
            value={genero}
            onChange={(e) => {
              setGenero(e.target.value)
              const musicas = musicasUpload.map((file) => ({
                ...file,
                genero: e.target.value,
              }))
              setMusicasUpload([...musicas])
            }}
            label='Genero'
          >
            <MenuItem value=''>
              <em> </em>
            </MenuItem>
            {genLoading
              ? 'Carregando...'
              : genError
              ? genError
              : genres &&
                genres.map((gen) => (
                  <MenuItem key={gen.id} value={gen.id}>
                    {gen.title}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>

        {!uploadEmAndamento && (
          <DropZone accept='audio/*' onDropAccepted={handleUpload}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div
                style={{
                  backgroundColor: '#ffffff',
                  outlineWidth: '2px',
                  outlineColor: isDragReject
                    ? '#dd2244'
                    : isDragActive
                    ? '#059327'
                    : '#000000',
                  outlineStyle: 'dashed',
                  outlineOffset: '-0.6em',
                  padding: '4em',
                  textAlign: 'center',
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p
                  style={{
                    color: isDragReject
                      ? '#dd2244'
                      : isDragActive
                      ? '#059327'
                      : '#000000',
                  }}
                >
                  <strong>Clique para adicionar arquivos</strong> ou arraste e
                  solte aqui.
                </p>
              </div>
            )}
          </DropZone>
        )}

        <ul className='arquivos-upload'>
          {musicasUpload.map((musica) => (
            <MusicaUpload
              key={musica.id}
              id={musica.id}
              titulo={musica.name}
              genero={musica.genero}
              concluido={musica.uploaded}
              enviando={musica.uploading}
              progresso={musica.progress}
              erro={musica.error}
              duplicada={musica.duplicada}
              setDuplicada={(value, key) => {
                updateFile(key, { duplicada: value })
              }}
              onGeneroChange={(e, key) => {
                updateFile(key, { genero: e.target.value })
              }}
              onTituloChange={(e, key) => {
                updateFile(key, { name: e.target.value })
              }}
              onExcluir={(key) => {
                const musicas = musicasUpload.filter((file) => file.id !== key)
                setMusicasUpload([...musicas])
              }}
            />
          ))}
        </ul>
        <Button
          disabled={uploadEmAndamento}
          onClick={handleSubmit}
          variant='contained'
          color='primary'
        >
          Enviar Músicas
        </Button>
      </div>
      <ModalConfirmacaoForm
        open={modalOpen}
        loading={false}
        success={true}
        onClick={() => {
          handleReset()
          setModalOpen(false)
        }}
        onClose={() => {
          handleReset()
          setModalOpen(false)
        }}
        message='Músicas adicionadas com sucesso!'
      >
        <Button
          onClick={() => {
            history.push('/')
          }}
          color='primary'
        >
          Voltar para o painel
        </Button>
        {!props.match.params.id && (
          <Button
            onClick={() => {
              handleReset()
              setModalOpen(false)
            }}
            color='primary'
            autoFocus
          >
            Adicionar mais músicas
          </Button>
        )}
      </ModalConfirmacaoForm>
    </PageWrapperPainel>
  )
}

export default AddMusicasScreen
