import {
  CATEGORIAS_EMPRESAS_REQUEST,
  CATEGORIAS_EMPRESAS_SUCCESS,
  CATEGORIAS_EMPRESAS_FAIL,
  ADD_CATEGORIAS_EMPRESAS_SUCCESS,
} from '../actions/categoriasEmpresas'

const initialState = {
  categoriasEmpresas: [],
}

const categoriasEmpresasReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORIAS_EMPRESAS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CATEGORIAS_EMPRESAS_SUCCESS:
      return {
        ...state,
        loading: false,
        categoriasEmpresas: action.payload,
      }
    case CATEGORIAS_EMPRESAS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ADD_CATEGORIAS_EMPRESAS_SUCCESS:
      return {
        ...state,
        loading: false,
        categoriasEmpresasSuccess: true,
      }
    default:
      return state
  }
}

export default categoriasEmpresasReducer
