import { useState } from 'react'

import EmpresaItem from '../components/EmpresaItem'
import TopoSessao from '../components/TopoSessao'
import { Link } from 'react-router-dom'
import { fetchUsuarios } from '../store/actions/usuarios'

import Button from '@material-ui/core/Button'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import { useDispatch, useSelector } from 'react-redux'
import Axios from 'axios'
import PageWrapperPainel from '../components/PageWrapperPainel'
import {
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from '@chakra-ui/react'

const EmpresasScreen = () => {
  const usuariosStore = useSelector((state) => state.usuarios)
  const { usuarios, loading, error } = usuariosStore

  const dispatch = useDispatch()

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const [modalOpen, setModalOpen] = useState(false)
  const [empresaExcluir, setEmpresaExcluir] = useState({})
  const [search, setSearch] = useState('')

  const openModal = (id, titulo) => {
    setModalOpen(true)
    setEmpresaExcluir({ id, titulo })
  }

  const excluirEmpresa = (id) => {
    Axios.get(
      process.env.REACT_APP_API +
        '/usuario/delete.php?id=' +
        id +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    ).then((data) => {
      dispatch(fetchUsuarios(usuarioInfo.token))
      setModalOpen(false)
    })
  }

  return (
    <PageWrapperPainel>
      <TopoSessao titulo='Empresas'>
        <Link to='/categorias-empresas'>
          <div className='botao-topo-sessao'>Categorias de Empresas</div>
        </Link>
        <Link to='/editar-empresa'>
          <div className='botao-topo-sessao'>Adicionar Empresa</div>
        </Link>
      </TopoSessao>
      <HStack px={['4', null, '6']} py='4' justify='flex-start'>
        <InputGroup>
          <InputLeftAddon bg='gray.200'>Buscar: </InputLeftAddon>
          <Input
            placeholder='Digite o nome da empresa'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            bg='white'
            maxW='sm'
          />
        </InputGroup>
      </HStack>
      <div className='sessao'>
        <table className='lista-painel'>
          <thead>
            <tr>
              <th>Nome</th>
              {/* <th>Último Acesso</th> */}
              <th>Categoria</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <th>Carregando...</th>
              </tr>
            ) : error ? (
              <tr>
                <th>{error}</th>
              </tr>
            ) : (
              usuarios &&
              usuarios
                .filter((empresa) =>
                  empresa.empresa.toLowerCase().includes(search.toLowerCase())
                )
                .map((usuario) => (
                  <EmpresaItem
                    onExcluir={() => {
                      openModal(usuario.id, usuario.empresa)
                    }}
                    key={usuario.id}
                    id={usuario.id}
                    nome={usuario.empresa}
                    categoria={usuario.categoria.titulo}
                  />
                ))
            )}
          </tbody>
        </table>
      </div>
      <ModalConfirmacaoForm
        open={modalOpen}
        success={modalOpen}
        onClick={() => {
          setModalOpen(false)
        }}
        onClose={() => {
          setModalOpen(false)
        }}
        message={`Tem certeza que deseja excluir a empresa '${empresaExcluir.titulo}'?`}
      >
        <Button
          onClick={() => {
            excluirEmpresa(empresaExcluir.id)
          }}
          color='primary'
        >
          Excluir
        </Button>
        <Button
          onClick={() => {
            setModalOpen(false)
          }}
          color='primary'
          autoFocus
        >
          Cancelar
        </Button>
      </ModalConfirmacaoForm>
    </PageWrapperPainel>
  )
}

export default EmpresasScreen
