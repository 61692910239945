import { Link } from 'react-router-dom'
import iconeEditar from '../imagens/editar.png'
import iconeExcluir from '../imagens/excluir.png'

import iconeEmpresa from '../imagens/empresa.png'
// import { useEffect, useState } from 'react'
// import { db } from '../config/firebase'
// import { Skeleton } from '@chakra-ui/skeleton'
// import { Box } from '@chakra-ui/layout'

const EmpresaItem = (props) => {
  // const [loading, setLoading] = useState(true)
  // const [data, setData] = useState(null)

  // useEffect(() => {
  //   setLoading(true)
  //   try {
  //     db.collection('players')
  //       .doc(props.id)
  //       .get()
  //       .then((doc) => {
  //         const data = doc.data()
  //         if (typeof data?.ultimaAtualizacao !== 'undefined') {
  //           setData(
  //             `${data?.ultimaAtualizacao
  //               ?.toDate()
  //               .toLocaleDateString(
  //                 'pt-BR'
  //               )} às ${data?.ultimaAtualizacao
  //               ?.toDate()
  //               .toLocaleTimeString('pt-BR')}`
  //           )
  //         }
  //         setLoading(false)
  //       })
  //   } catch (error) {
  //     console.log(error)
  //     setLoading(false)
  //   }
  // }, [])

  return (
    <tr>
      <td>
        <img className='icone-lista' src={iconeEmpresa} alt='' />
        <Link to={`/empresa/${props.id}`}>{props.nome}</Link>
      </td>
      {/* <td>
        {loading ? (
          <Box w='100%'>
            <Skeleton height='20px' />
          </Box>
        ) : (
          data
        )}
      </td> */}
      <td>{props.categoria}</td>
      <td className='acoes-painel'>
        <Link to={`/editar-empresa/${props.id}`}>
          <img src={iconeEditar} alt='Editar' />
        </Link>
        <div className='botao-excluir' onClick={props.onExcluir}>
          <img src={iconeExcluir} alt='Excluir' />
        </div>
      </td>
    </tr>
  )
}

export default EmpresaItem
