import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  useDisclosure,
  VStack,
  Text,
  Center,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@chakra-ui/react'
import VinhetaItem from '../VinhetaItem'
import { BiStation } from 'react-icons/bi'
import { analytics } from '../../config/firebase'
import Axios from 'axios'
import { usePlayer } from '../../hooks/usePlayer'
import { updateVinheta } from '../../store/actions/vinhetas'

const PopupVinhetas = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const dispatch = useDispatch()

  const { refreshPlaylist } = usePlayer()

  const {
    vinhetas,
    loading: loadingVinhetas,
    error: errorVinhetas,
  } = useSelector((state) => state.vinhetas)

  const { categoriasVinhetas, loading: loadingCategoriasVinhetas } =
    useSelector((state) => state.categoriasVinhetas)

  const { usuarioInfo } = useSelector((state) => state.usuario)

  const temVinhetas =
    Array.isArray(categoriasVinhetas) && categoriasVinhetas.length > 0

  const [categoriaVinhetaSelecionada, setCategoriaVinhetaSelecionada] =
    useState()

  const [vinhetasFiltradas, setVinhetasFiltradas] = useState([])

  useEffect(() => {
    setVinhetasFiltradas([])
    //eslint-disable-next-line
  }, [vinhetas])

  useEffect(() => {
    if (vinhetas)
      setVinhetasFiltradas(
        vinhetas.filter(
          (vinh) => vinh.categoria.id === categoriaVinhetaSelecionada
        )
      )
    //eslint-disable-next-line
  }, [categoriaVinhetaSelecionada])

  const changeEstadoVinheta = (id, estado) => {
    Axios.get(
      process.env.REACT_APP_API +
        '/vinheta/change_status.php?id=' +
        id +
        '&status=' +
        estado +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    )
      .then((data) => {
        refreshPlaylist()
        dispatch(updateVinheta({ ativo: estado, id }))
      })
      .catch(() => {
        dispatch(updateVinheta({ ativo: estado === '1' ? '0' : '1', id }))
      })
  }

  return (
    <>
      <Button
        leftIcon={<BiStation />}
        onClick={() => {
          analytics.logEvent('abriu_popup_vinhetas')
          onOpen()
        }}
        colorScheme='blackAlpha'
        size='sm'
        height={['8', null, null, '6', '8']}
        fontSize='xs'
        borderRadius='full'
      >
        Vinhetas
      </Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Vinhetas</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              analytics.logEvent('fechou_popup_vinhetas_botao')
            }}
          />
          <VStack marginX='10' marginY='4'>
            <Select
              name='categoria'
              value={categoriaVinhetaSelecionada || ''}
              onChange={(e) => {
                setCategoriaVinhetaSelecionada(e.target.value)
              }}
            >
              <option value=''>Selecione uma categoria</option>
              {temVinhetas &&
                categoriasVinhetas.map((categoriaVinheta) => (
                  <option key={categoriaVinheta.id} value={categoriaVinheta.id}>
                    {categoriaVinheta.titulo}
                  </option>
                ))}
            </Select>
            <Text fontSize='xs' textAlign='center'>
              Clique no ícone da vinheta para reproduzir imediatamente:
            </Text>
          </VStack>
          <ModalBody>
            {loadingVinhetas || loadingCategoriasVinhetas ? (
              <Center>
                <CircularProgress isIndeterminate color='accent.400' />
              </Center>
            ) : (
              <>
                <VStack>
                  <table className='lista-painel lista-vinhetas-tabela'>
                    <tbody>
                      {vinhetasFiltradas.map((vinheta) => (
                        <VinhetaItem
                          formatoPopup
                          key={vinheta.id}
                          id={vinheta.id}
                          titulo={vinheta.titulo}
                          categoria={vinheta.categoria.titulo}
                          idCategoria={vinheta.categoria.id}
                          estado={vinheta.ativo}
                          frequencia={vinheta.frequencia}
                          onChangeEstado={changeEstadoVinheta}
                          // onReproduzir={props.onClose}
                        />
                      ))}
                    </tbody>
                  </table>

                  {(errorVinhetas || !vinhetas || vinhetas.length === 0) && (
                    <Center>
                      <Text>Nenhuma vinheta encontrada...</Text>
                    </Center>
                  )}
                </VStack>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PopupVinhetas
