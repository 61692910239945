import { IconButton } from '@chakra-ui/button'
import { HStack } from '@chakra-ui/layout'
import { Td, Tr } from '@chakra-ui/table'
import { BiMusic, BiTrash } from 'react-icons/bi'

const MusicaItem = (props) => {
  return (
    <>
      <Tr>
        <Td>
          <IconButton
            aria-label='Ícone de Música'
            icon={<BiMusic />}
            fontSize='3xl'
            size='lg'
            borderRadius='full'
            marginRight='4'
            disabled
            _hover={{ cursor: 'default' }}
            _disabled={{ color: 'gray.600' }}
          />
          {props.titulo}
        </Td>
        <Td>{String(props.cadastro)}</Td>
        <Td>
          <HStack alignItems='center'>
            {/* <Link to={`/editar-musica/${props.id}`}>
              <IconButton
                aria-label='Editar'
                _hover={{ color: 'white', bg: 'blue.500' }}
                icon={<BiEdit />}
              />
            </Link> */}
            <IconButton
              aria-label='Excluir'
              _hover={{ color: 'white', bg: 'red.500' }}
              icon={<BiTrash />}
              onClick={props.onExcluir}
            />
          </HStack>
        </Td>
      </Tr>
    </>
  )
}

export default MusicaItem
