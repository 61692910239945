const includeClockInPlaylist = ({ playlist, faixaAtual, when }) => {
  let relogioObjeto = {
    title: 'Hora Certa',
    type: 'clock',
    pathname: 'relogio',
    genre: { title: 'Relógio' },
  }

  let novaPlaylist

  if (when === 'imediatamente') {
    novaPlaylist = [
      ...playlist.slice(0, +faixaAtual),
      relogioObjeto,
      ...playlist.slice(+faixaAtual + 1),
    ]
  } else {
    novaPlaylist = [
      ...playlist.slice(0, +faixaAtual + 1),
      relogioObjeto,
      ...playlist.slice(+faixaAtual + 2),
    ]
  }

  return novaPlaylist
}

export default includeClockInPlaylist
