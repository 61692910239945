const generateSpotsPlaylist = (spots) => {
  const tempSpotsPlaylist = spots
    .filter(
      (vinheta) => Number(vinheta.ativo) === 1 && Number(vinheta.frequencia) > 0
    )
    .map((spot) => ({
      title: spot.titulo,
      id: spot.id,
      type: 'spot',
      pathname: `https://comunicaedu.com/vinhetas/${spot.categoria.id}/${spot.id}.mp3`,
      genre: { title: spot.categoria?.titulo, id: spot.categoria?.id },
      tempFreq: spot.frequencia,
      frequencia: spot.frequencia,
    }))

  let spotsPlaylist = []

  while (tempSpotsPlaylist.length > 0) {
    tempSpotsPlaylist.forEach((spot, index) => {
      if (spot.tempFreq > 0) {
        const position =
          (spotsPlaylist.length / spot.frequencia) *
          (spot.frequencia - spot.tempFreq)
        spotsPlaylist.splice(position, 0, spot)
        spot.tempFreq--
      } else {
        tempSpotsPlaylist.splice(index, 1)
      }
    })
  }

  return spotsPlaylist
}

export default generateSpotsPlaylist
