import { Stack } from '@chakra-ui/layout'
import { Box, Flex, Heading } from '@chakra-ui/layout'

const TopoSessao = ({ titulo, children }) => {
  return (
    <Box paddingX={['4', null, '6']} paddingTop='10' paddingBottom='2'>
      <Stack
        direction={['column', null, 'row']}
        justify='space-between'
        borderBottomColor='gray.200'
        borderBottomWidth='1px'
        paddingBottom='4'
        flexWrap='wrap'
      >
        <Heading margin='0'>{titulo}</Heading>
        <Flex justifyContent='flex-end' flexWrap='wrap'>
          {children}
        </Flex>
      </Stack>
    </Box>
  )
}

export default TopoSessao
