import { Link } from 'react-router-dom'
import { Tr } from '@chakra-ui/table'
import { Td } from '@chakra-ui/table'
import { HStack } from '@chakra-ui/layout'
import { IconButton } from '@chakra-ui/button'
import { BiAlbum, BiPlusCircle } from 'react-icons/bi'
import DeleteCustomPlaylist from './DeleteCustomPlaylist'
import EditCustomPlaylist from './EditCustomPlaylist'

const CustomPlaylistItem = (props) => {
  return (
    <Tr>
      <Td>
        <IconButton
          aria-label='Ícone de Música'
          icon={<BiAlbum />}
          fontSize='3xl'
          size='lg'
          borderRadius='full'
          marginRight='4'
          disabled
          _hover={{ cursor: 'default' }}
          _disabled={{ color: 'gray.600' }}
        />
        <Link to={`/playlist-exclusiva/${props.id}`}>{props.titulo}</Link>
      </Td>
      <Td isNumeric>{props.quantidade}</Td>
      <Td isNumeric>
        <HStack align='flex-start' justify='flex-end'>
          <Link to={`/playlist-exclusiva/${props.id}/adicionar-musicas`}>
            <IconButton
              aria-label='Adicionar Músicas'
              _hover={{ color: 'white', bg: 'green.500' }}
              icon={<BiPlusCircle />}
            />
          </Link>
          <EditCustomPlaylist playlistId={props.id} />
          <DeleteCustomPlaylist playlistId={props.id} />
        </HStack>
      </Td>
    </Tr>
  )
}

export default CustomPlaylistItem
