import { db } from '../../config/firebase'

const getAllRequests = async (userId) => {
  try {
    let requests

    if (userId && Number(userId) > 0) {
      requests = await db
        .collection('requests')
        .where('uid', '==', userId)
        .get()
    } else {
      requests = await db.collection('requests').get()
    }

    const results = []

    requests.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id })
    })

    return results
  } catch (error) {
    console.error(error)
    return false
  }
}

const getRequest = async (id) => {
  try {
    const request = await db.collection('requests').doc(id).get()
    return { ...request.data(), id: request.id }
  } catch (error) {
    console.error(error)
    return false
  }
}

const createRequest = async (data) => {
  try {
    const docRef = await db.collection('requests').add(data)
    return docRef.id
  } catch (error) {
    console.error(error)
    return false
  }
}

const updateRequest = async (id, data) => {
  if (!id || !data) return
  try {
    await db.collection('requests').doc(id).update(data)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

const deleteRequest = async (id) => {
  if (!id) return
  try {
    await db.collection('requests').doc(id).delete()
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export {
  getAllRequests,
  getRequest,
  createRequest,
  updateRequest,
  deleteRequest,
}
