import { IconButton } from '@chakra-ui/button'
import { Input } from '@chakra-ui/input'
import { HStack } from '@chakra-ui/layout'
import { BiMinus, BiPlus } from 'react-icons/bi'

const NumberInput = ({ value, onChange, readOnly, width, size, fontSize }) => {
  return (
    <HStack maxW='320px'>
      <IconButton
        aria-label='Diminuir valor'
        icon={<BiMinus />}
        size={size || 'md'}
        onClick={() => {
          if (+value > 1) {
            let newValue = +value - 1
            onChange({ target: { value: newValue } })
          }
        }}
        disabled={+value === 1}
      />
      <Input
        textAlign='center'
        fontSize={fontSize || 'lg'}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        width={width || '24'}
        size={size || 'md'}
        borderRadius='md'
      />
      <IconButton
        aria-label='Aumentar valor'
        icon={<BiPlus />}
        size={size || 'md'}
        onClick={() => {
          let newValue = +value + 1
          onChange({ target: { value: newValue } })
        }}
      />
    </HStack>
  )
}

export default NumberInput
