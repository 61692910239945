import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TopoSessao from '../components/TopoSessao'
import ButtonUpload from '../components/forms/ButtonUpload'
import * as Yup from 'yup'
import imageExists from 'image-exists'
import PageWrapperPainel from '../components/PageWrapperPainel'
import { useDatabase } from '../hooks/useDatabase'
import { storage } from '../config/firebase'
import { useToast } from '@chakra-ui/toast'

const EditGeneroScreen = (props) => {
  let history = useHistory()

  const {
    genres,
    loading: loadingMusicas,
    error,
    addGenre,
    updateGenre,
  } = useDatabase()

  const toast = useToast()

  const [imagem, setImagem] = useState()
  const [imagemAnterior, setImagemAnterior] = useState(false)
  const [generoSelected, setGeneroSelected] = useState()
  const [imageProgress, setImageProgress] = useState(0)

  useEffect(() => {
    if (genres && props.match.params.id && !loadingMusicas && !error) {
      let genreSelected = genres.find(
        (genre) => genre.id === props.match.params.id
      )
      setGeneroSelected(genreSelected)

      if (genreSelected?.imagePath) {
        setImagemAnterior(genreSelected.imagePath)
      } else {
        imageExists(
          '../imagens/generos/' + props.match.params.id + '.jpeg',
          (image) => {
            if (image) {
              setImagemAnterior(
                '../imagens/generos/' + props.match.params.id + '.jpeg'
              )
            } else {
              imageExists(
                '../imagens/generos/' + props.match.params.id + '.jpg',
                (image) => {
                  if (image) {
                    setImagemAnterior(
                      '../imagens/generos/' + props.match.params.id + '.jpg'
                    )
                  } else {
                    imageExists(
                      '../imagens/generos/' + props.match.params.id + '.png',
                      (image) => {
                        if (image) {
                          setImagemAnterior(
                            '../imagens/generos/' +
                              props.match.params.id +
                              '.png'
                          )
                        }
                      }
                    )
                  }
                }
              )
            }
          }
        )
      }
    }
  }, [error, genres, loadingMusicas, props.match.params.id])

  const handleSuccess = () => {
    toast({
      title: props.match.params.id
        ? 'Gênero atualizado com sucesso!'
        : 'Gênero cadastrado com sucesso!',
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
    history.push('/generos')
  }

  const updateWithImage = (genreId, data, image) => {
    const fileExtension = image.name.split('.').pop()

    const storageRef = storage.ref(`images/genres/${genreId}.${fileExtension}`)

    let uploadTask = storageRef.put(image)

    uploadTask.on(
      'state_changed',
      function (snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setImageProgress(progress)
      },
      function (error) {
        toast({
          title: 'Ocorreu um Erro!',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          updateGenre(genreId, {
            ...data,
            imagePath: downloadURL,
            pathRef: storageRef.fullPath,
          }).then(handleSuccess)
        })
      }
    )
  }

  const handleSubmit = (data) => {
    if (props.match.params.id) {
      if (imagem) {
        updateWithImage(props.match.params.id, data, imagem)
      } else {
        updateGenre(props.match.params.id, data).then(handleSuccess)
      }
    } else {
      addGenre(data).then((genreId) => {
        if (imagem) {
          updateWithImage(genreId, data, imagem)
        }
      })
    }
  }

  return (
    ((props.match.params.id && generoSelected) || !props.match.params.id) && (
      <PageWrapperPainel>
        <TopoSessao titulo='Adicionar Gênero' />
        <div
          style={{
            width: 'calc(100% - 4rem)',
            maxWidth: '960px',
            margin: '2rem auto',
            padding: '1.5rem',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
          }}
        >
          <Formik
            initialValues={{
              title: generoSelected ? generoSelected.title : '',
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(255, 'Tamanho máximo: 255 caracteres')
                .required('Preenchimento Obrigatório!'),
            })}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Field
                  label='Título'
                  name='title'
                  fullWidth
                  variant='outlined'
                  margin='normal'
                  as={TextField}
                  helperText={
                    formik.touched.title && formik.errors.title
                      ? formik.errors.title
                      : ''
                  }
                  error={formik.touched.title && formik.errors.title}
                />

                {imagemAnterior && (
                  <div style={{ margin: '1em 0' }}>
                    <p
                      style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '10pt' }}
                    >
                      Imagem Atual:
                    </p>
                    <img
                      style={{ height: '6em', width: 'auto' }}
                      src={imagemAnterior}
                      alt='Imagem atual'
                    />
                  </div>
                )}

                <ButtonUpload
                  name='image'
                  label='Escolher Imagem'
                  style={{ margin: '0.5em 0' }}
                  onChange={(e) => {
                    setImagem(e.target.files[0])
                  }}
                  accept='image/*'
                  progress={imageProgress}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    style={{ margin: '1em 0' }}
                    color='primary'
                    size='large'
                  >
                    {props.match.params.id
                      ? 'Atualizar Gênero'
                      : 'Cadastrar Gênero'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </PageWrapperPainel>
    )
  )
}

export default EditGeneroScreen
