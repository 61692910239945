import {
  USUARIOS_REQUEST,
  USUARIOS_SUCCESS,
  USUARIOS_FAIL,
  ADD_USUARIO_SUCCESS,
  UPLOAD_IMAGEM_FAIL,
  UPLOAD_IMAGEM_SUCCESS,
  UPLOAD_IMAGEM_PROGRESS,
  UPLOAD_IMAGEM_REQUEST,
} from '../actions/usuarios'

const initialState = {
  usuarios: [],
  loading: false,
  loadingImagem: false,
  error: false,
  usuarioSuccess: false,
  errorImagem: false,
  errorMessageImagem: '',
  progressImagem: 0,
  uploadedImagem: false,
}

const usuariosReducer = (state = initialState, action) => {
  switch (action.type) {
    case USUARIOS_REQUEST:
      return {
        ...state,
        loading: true,
        loadingUsuarios: true,
      }
    case USUARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingUsuarios: false,
        usuarios: action.payload,
      }
    case USUARIOS_FAIL:
      return {
        ...state,
        loading: false,
        loadingUsuarios: false,
        error: action.payload,
      }
    case ADD_USUARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        usuarioSuccess: true,
      }
    case UPLOAD_IMAGEM_REQUEST:
      return {
        ...state,
        loadingImagem: true,
        progressImagem: action.payload.progress,
        uploadedImagem: false,
      }
    case UPLOAD_IMAGEM_PROGRESS:
      return {
        ...state,
        loadingImagem: true,
        progressImagem: action.payload.progress,
        uploadedImagem: false,
      }
    case UPLOAD_IMAGEM_SUCCESS:
      return {
        ...state,
        loadingImagem: false,
        progressImagem: action.payload.progress,
        uploadedImagem: true,
        usuarioSuccess: true,
      }
    case UPLOAD_IMAGEM_FAIL:
      return {
        ...state,
        loadingImagem: false,
        errorImagem: true,
        errorMessageImagem: action.payload.errorMessage,
        uploadedImagem: false,
      }
    default:
      return state
  }
}

export default usuariosReducer
