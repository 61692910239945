import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TopoSessao from '../components/TopoSessao'
import * as Yup from 'yup'
import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import Axios from 'axios'
import PageWrapperPainel from '../components/PageWrapperPainel'

const EditCategoriaVinhetaScreen = (props) => {
  let history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const [categoriasVinhetasSuccess, setCategoriasVinhetasSuccess] =
    useState(false)
  const [categoriaSelected, setCategoriaSelected] = useState()

  const usuarioStore = useSelector((state) => state.usuario)
  const { usuarioInfo } = usuarioStore

  const empresaId = props.match.params.empresa
    ? props.match.params.empresa
    : categoriaSelected
    ? categoriaSelected.empresa
    : ''

  const usuariosStore = useSelector((state) =>
    state.usuarios.usuarios.find((user) => user.id === empresaId)
  )

  let nomeEmpresa = ''

  if (usuariosStore) {
    nomeEmpresa = usuariosStore.empresa
  }

  useEffect(() => {
    if (props.match.params.id) {
      Axios.get(
        process.env.REACT_APP_API +
          '/categoria_vinheta/read_single.php?id=' +
          props.match.params.id +
          '&token=' +
          encodeURIComponent(usuarioInfo.token),
        {
          headers: {
            Authorization: 'bearer ' + usuarioInfo.token,
            withCredentials: true,
          },
        }
      ).then((data) => {
        setCategoriaSelected(data.data)
      })
    }
    //eslint-disable-next-line
  }, [])

  return (
    ((props.match.params.id && categoriaSelected) ||
      !props.match.params.id) && (
      <PageWrapperPainel>
        <TopoSessao
          titulo={
            props.match.params.id
              ? 'Atualizar Categoria de Vinhetas'
              : 'Adicionar Categoria de Vinhetas'
          }
        />
        <div
          style={{
            width: 'calc(100% - 4rem)',
            maxWidth: '960px',
            margin: '2rem auto',
            padding: '1.5rem',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
          }}
        >
          {usuariosStore && (
            <Formik
              initialValues={{
                empresa: nomeEmpresa,
                titulo: categoriaSelected ? categoriaSelected.titulo : '',
              }}
              validationSchema={Yup.object({
                empresa: Yup.string().required('Preenchimento Obrigatório!'),
                titulo: Yup.string()
                  .max(255, 'Tamanho máximo: 255 caracteres')
                  .required('Preenchimento Obrigatório!'),
              })}
              onSubmit={(data) => {
                if (props.match.params.id) {
                  Axios.post(
                    process.env.REACT_APP_API +
                      '/categoria_vinheta/update.php?token=' +
                      encodeURIComponent(usuarioInfo.token),
                    {
                      titulo: data.titulo,
                      empresa: empresaId,
                      id: props.match.params.id,
                    }
                  )
                    .then((dataRetorno) => {
                      setCategoriasVinhetasSuccess(true)
                    })
                    .catch(function (error) {
                      // handle error
                      console.log(error)
                    })
                } else {
                  Axios.post(
                    process.env.REACT_APP_API +
                      '/categoria_vinheta/create.php?token=' +
                      encodeURIComponent(usuarioInfo.token),
                    {
                      titulo: data.titulo,
                      empresa: empresaId,
                    }
                  )
                    .then((dataRetorno) => {
                      setCategoriasVinhetasSuccess(true)
                    })
                    .catch(function (error) {
                      // handle error
                      console.log(error)
                    })
                }
                setModalOpen(true)
              }}
            >
              {(formik) => (
                <Form>
                  <Field
                    label='Empresa'
                    name='empresa'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    as={TextField}
                    onChange={() => {}}
                    helperText={
                      formik.touched.empresa && formik.errors.empresa
                        ? formik.errors.empresa
                        : ''
                    }
                    error={formik.touched.empresa && formik.errors.empresa}
                  />
                  <Field
                    label='Título da Categoria'
                    name='titulo'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    as={TextField}
                    helperText={
                      formik.touched.titulo && formik.errors.titulo
                        ? formik.errors.titulo
                        : ''
                    }
                    error={formik.touched.titulo && formik.errors.titulo}
                  />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      type='submit'
                      variant='contained'
                      style={{ margin: '1em 0' }}
                      color='primary'
                      size='large'
                    >
                      {props.match.params.id
                        ? 'Atualizar Categoria'
                        : 'Cadastrar Categoria'}
                    </Button>
                  </div>
                  <ModalConfirmacaoForm
                    open={modalOpen}
                    success={categoriasVinhetasSuccess}
                    onClick={() => {
                      formik.handleReset()
                      setModalOpen(false)
                    }}
                    onClose={() => {
                      formik.handleReset()
                      setModalOpen(false)
                    }}
                    message={
                      props.match.params.id
                        ? 'Categoria atualizada com sucesso!'
                        : 'Categoria cadastrada com sucesso!'
                    }
                  >
                    <Button
                      onClick={() => {
                        if (props.match.params.id) {
                          history.push('/empresa/' + categoriaSelected.empresa)
                        } else {
                          history.push('/empresa/' + props.match.params.empresa)
                        }
                      }}
                      color='primary'
                    >
                      Voltar para a empresa
                    </Button>
                    {!props.match.params.id && (
                      <Button
                        onClick={() => {
                          formik.handleReset()
                          setModalOpen(false)
                        }}
                        color='primary'
                        autoFocus
                      >
                        Cadastrar nova categoria
                      </Button>
                    )}
                  </ModalConfirmacaoForm>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </PageWrapperPainel>
    )
  )
}

export default EditCategoriaVinhetaScreen
