import { useField } from 'formik'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'

const MySelect = ({ label, margin, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <FormControl
      fullWidth
      variant='outlined'
      margin={margin}
      error={meta.touched && meta.error}
    >
      <InputLabel id={props.id + '-label'}>{label}</InputLabel>
      <Select
        labelId={props.id + '-label'}
        id={props.id}
        {...field}
        {...props}
        label={label}
      />
      <FormHelperText>{meta.error}</FormHelperText>
    </FormControl>
  )
}

export default MySelect
