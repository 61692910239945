import { useState, useEffect } from 'react'
import TopoSessao from '../components/TopoSessao'
import VinhetaItem from '../components/VinhetaItem'
import { Link } from 'react-router-dom'

import ModalConfirmacaoForm from '../components/forms/ModalConfirmacaoForm'
import { useDispatch, useSelector } from 'react-redux'
import Axios from 'axios'
import { fetchVinhetas } from '../store/actions/vinhetas'
import { usePlayer } from '../hooks/usePlayer'
import PopupSpotInterval from './VinhetasScreen/components/PopupSpotInterval'
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/table'
import { Box, Center, Text, Wrap, WrapItem } from '@chakra-ui/layout'
import { CircularProgress } from '@chakra-ui/progress'
import { Input } from '@chakra-ui/input'
import { Button } from '@chakra-ui/button'
import { BiPlus } from 'react-icons/bi'
import { Select } from '@chakra-ui/select'
import PageWrapperPainel from '../components/PageWrapperPainel'

const VinhetasScreen = (props) => {
  const { vinhetas, loading } = useSelector((state) => state.vinhetas)

  const { usuarioInfo } = useSelector((state) => state.usuario)

  const { categoriasVinhetas } = useSelector(
    (state) => state.categoriasVinhetas
  )

  const dispatch = useDispatch()

  const { refreshPlaylist } = usePlayer()

  const [vinhetasLista, setVinhetasLista] = useState(vinhetas)
  const [pesquisa, setPesquisa] = useState('')
  const [page, setPage] = useState(1)
  const [results, setResults] = useState(vinhetas.slice(0, 20))
  const [loadingMore, setLoadingMore] = useState(false)

  const [categoriaVinhetaSelecionada, setCategoriaVinhetaSelecionada] =
    useState()

  useEffect(() => {
    if (props.match.params.categoria) {
      setCategoriaVinhetaSelecionada(props.match.params.categoria)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (categoriaVinhetaSelecionada) {
      setVinhetasLista(
        vinhetas.filter(
          (vinheta) =>
            vinheta.titulo.toUpperCase().includes(pesquisa.toUpperCase()) &&
            vinheta.categoria.id === categoriaVinhetaSelecionada
        )
      )
    } else {
      setVinhetasLista(
        vinhetas.filter((vinheta) =>
          vinheta.titulo.toUpperCase().includes(pesquisa.toUpperCase())
        )
      )
    }
    //eslint-disable-next-line
  }, [vinhetas])

  useEffect(() => {
    if (categoriaVinhetaSelecionada) {
      setVinhetasLista(
        vinhetas.filter(
          (vinheta) =>
            vinheta.titulo.toUpperCase().includes(pesquisa.toUpperCase()) &&
            vinheta.categoria.id === categoriaVinhetaSelecionada
        )
      )
    } else {
      setVinhetasLista(
        vinhetas.filter((vinheta) =>
          vinheta.titulo.toUpperCase().includes(pesquisa.toUpperCase())
        )
      )
    }
    //eslint-disable-next-line
  }, [pesquisa, categoriaVinhetaSelecionada])

  useEffect(() => {
    setResults(vinhetasLista.slice(0, 20 * page))
    setLoadingMore(false)
  }, [page, vinhetasLista])

  const [modalOpen, setModalOpen] = useState(false)
  const [vinhetaExcluir, setVinhetaExcluir] = useState({})

  const openModal = (id, titulo) => {
    setModalOpen(true)
    setVinhetaExcluir({ id, titulo })
  }

  const excluirVinheta = (id) => {
    Axios.get(
      process.env.REACT_APP_API +
        '/vinheta/delete.php?id=' +
        id +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    ).then((data) => {
      dispatch(fetchVinhetas(usuarioInfo.token))
      setModalOpen(false)
    })
  }

  const changeEstadoVinheta = (id, estado) => {
    setVinhetasLista(
      vinhetasLista.filter((vinheta) => {
        if (vinheta.id === id) {
          vinheta.ativo = estado
        }
        return vinheta
      })
    )
    Axios.get(
      process.env.REACT_APP_API +
        '/vinheta/change_status.php?id=' +
        id +
        '&status=' +
        estado +
        '&token=' +
        encodeURIComponent(usuarioInfo.token),
      {
        headers: {
          Authorization: 'bearer ' + usuarioInfo.token,
          withCredentials: true,
        },
      }
    )
      .then((data) => {
        setVinhetasLista(
          vinhetasLista.filter((vinheta) => {
            if (vinheta.id === id) {
              vinheta.ativo = estado
            }
            return vinheta
          })
        )

        refreshPlaylist()

        setModalOpen(false)
      })
      .catch(() => {
        setVinhetasLista(
          vinhetasLista.filter((vinheta) => {
            if (vinheta.id === id) {
              vinheta.ativo = estado === '1' ? '0' : '1'
            }
            return vinheta
          })
        )
      })
  }

  return (
    <PageWrapperPainel>
      <TopoSessao titulo='Vinhetas'>
        <Wrap marginTop='3'>
          {props.match.params.categoria && (
            <WrapItem>
              <Link
                to={`/vinheta/${props.match.params.categoria}/adicionar-vinheta/`}
              >
                <Button
                  leftIcon={<BiPlus />}
                  colorScheme='blueGray'
                  variant='outline'
                >
                  Nova Vinheta
                </Button>
              </Link>
            </WrapItem>
          )}
          <WrapItem>
            <Box>
              <PopupSpotInterval />
            </Box>
          </WrapItem>
          <WrapItem w={['100%', null, 'auto']}>
            <Select
              w={['100%', null, 'auto']}
              name='categoria'
              bg='white'
              value={categoriaVinhetaSelecionada || ''}
              onChange={(e) => {
                setCategoriaVinhetaSelecionada(e.target.value)
              }}
            >
              <option value=''>- Selecione uma categoria -</option>
              {categoriasVinhetas?.map((categoriaVinheta) => (
                <option key={categoriaVinheta.id} value={categoriaVinheta.id}>
                  {categoriaVinheta.titulo}
                </option>
              ))}
            </Select>
          </WrapItem>
          <WrapItem w={['100%', null, 'auto']}>
            <Input
              w={['100%', null, 'auto']}
              bg='white'
              placeholder='Digite para pesquisar...'
              onChange={(e) => {
                setPesquisa(e.target.value)
              }}
            />
          </WrapItem>
        </Wrap>
      </TopoSessao>
      <Box paddingX={['4', null, '6']} paddingTop='2' paddingBottom='48'>
        <Box bg='white'>
          {vinhetas?.length > 0 && !loading ? (
            <Table>
              <Thead>
                <Tr>
                  <Th>Título</Th>
                  <Th display={['none', null, null, 'table-cell']}>
                    Categoria
                  </Th>
                  <Th display={['none', null, 'table-cell']}>Estado</Th>
                  <Th display={['none', null, 'table-cell']}>Frequência</Th>
                  {+usuarioInfo?.nivel === 0 && (
                    <Th display={['none', null, 'table-cell']}>Ações</Th>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {results?.map((vinheta) => (
                  <VinhetaItem
                    onChangeEstado={() => {
                      changeEstadoVinheta(
                        vinheta.id,
                        vinheta.ativo === '1' ? '0' : '1'
                      )
                    }}
                    onExcluir={() => {
                      openModal(vinheta.id, vinheta.titulo)
                    }}
                    key={vinheta.id}
                    id={vinheta.id}
                    titulo={vinheta.titulo}
                    categoria={vinheta.categoria.titulo}
                    idCategoria={vinheta.categoria.id}
                    estado={vinheta.ativo}
                    frequencia={vinheta.frequencia}
                  />
                ))}
              </Tbody>
            </Table>
          ) : loading ? (
            <Center padding='8'>
              <CircularProgress
                isIndeterminate
                color='gray'
                trackColor='transparent'
              />
            </Center>
          ) : (
            <Center padding='8'>
              <Text>Parece que você ainda não possui vinhetas!</Text>
            </Center>
          )}
        </Box>
        {page < vinhetasLista.length / 20 && (
          <Center paddingY='6'>
            <Button
              size='lg'
              colorScheme='blueGray'
              variant='outline'
              isLoading={loadingMore}
              onClick={() => {
                if (page < vinhetasLista.length / 20)
                  setPage((prev) => prev + 1)
                setLoadingMore(true)
              }}
            >
              Carregar mais
            </Button>
          </Center>
        )}
      </Box>
      <ModalConfirmacaoForm
        open={modalOpen}
        success={modalOpen}
        onClick={() => {
          setModalOpen(false)
        }}
        onClose={() => {
          setModalOpen(false)
        }}
        message={`Tem certeza que deseja excluir a vinheta '${vinhetaExcluir.titulo}'?`}
      >
        <Button
          onClick={() => {
            setModalOpen(false)
          }}
          colorScheme='gray'
          autoFocus
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            excluirVinheta(vinhetaExcluir.id)
          }}
          colorScheme='accent'
        >
          Excluir
        </Button>
      </ModalConfirmacaoForm>
    </PageWrapperPainel>
  )
}

export default VinhetasScreen
